import styled from 'styled-components';

interface UploadingContainerProps {
  $size: number;
}

export const UploadingContainer = styled.div<UploadingContainerProps>`
  width: ${(props: UploadingContainerProps) => `${props.$size}px`};
  height: ${(props: UploadingContainerProps) => `${props.$size}px`};
`;
