import React from 'react';

const OverviewIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.63852 17.0849H3.70268C3.26768 17.0849 2.91602 16.7324 2.91602 16.2983V13.1499C2.91602 12.7149 3.26852 12.3633 3.70268 12.3633H7.63768"
      stroke="stroke"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3594 3.70464V17.0846H16.2944C16.7294 17.0846 17.081 16.7321 17.081 16.298V3.70464C17.081 3.26964 16.7285 2.91797 16.2944 2.91797H13.146C12.7119 2.91797 12.3594 3.27047 12.3594 3.70464H12.3594Z"
      stroke="stroke"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3592 17.0848H7.63672V8.42729C7.63672 7.99229 7.98922 7.64062 8.42339 7.64062H12.3584"
      stroke="stroke"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default OverviewIcon;
