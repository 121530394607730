import React from 'react';

const ContactIcon = (props: any) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2785 13.4811C18.0369 12.6328 19.2501 10.8331 19.2501 8.75C19.2501 5.85051 16.8996 3.5 14.0001 3.5C11.1006 3.5 8.75006 5.85051 8.75006 8.75C8.75006 10.8331 9.96325 12.6328 11.7216 13.4811C8.73576 14.4429 6.57544 17.244 6.57544 20.5496C6.57544 24.6501 21.4247 24.6501 21.4247 20.5496C21.4247 17.244 19.2644 14.4429 16.2785 13.4811Z"
    />
  </svg>
);

export default ContactIcon;
