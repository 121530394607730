import { Key, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { DropDownProps } from './Dropdown';

export const useDropdown = (props: DropDownProps) => {
  const selectedIndex = props.selectedIndex;
  const [selected, setSelected] = useState(new Set<Key>(selectedIndex !== undefined ? [selectedIndex.toString()] : []));

  const [selectedIndexInitial, setSelectedIndexInitial] = useState(selectedIndex);
  if (selectedIndex !== undefined && selectedIndex !== selectedIndexInitial) {
    setSelectedIndexInitial(selectedIndex);
    setSelected(new Set<Key>(selectedIndex !== undefined ? [selectedIndex.toString()] : undefined));
  }

  const buttonRef = useRef<HTMLElement>();
  const [buttonWidth, setButtonWidth] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setButtonWidth(buttonRef?.current?.offsetWidth);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const selectedValue = useMemo(
    () =>
      Array.from(selected)
        .map((item) => props.items[item]?.text)
        .join(', '),
    [selected],
  );

  const selectedIcon = useMemo(() => {
    if (props.multiselect) return undefined;

    return props.items[Array.from(selected)[0]]?.icon;
  }, [selected]);

  const disabledKeysLocal = useMemo(() => {
    return props.disabledKeys?.map((key) =>
      props.items.findIndex((item) => (item.key || item.text) === key).toString(),
    );
  }, [props.disabledKeys]);

  return { selected, setSelected, selectedValue, selectedIcon, buttonWidth, buttonRef, disabledKeysLocal };
};

export default useDropdown;
