import { Base64 } from 'js-base64';

import { User } from '~/constants/graphqlTypes';
import { OrganizationType, Scope } from '~/types/api.graphql';

import { AudiencePerson } from '../constants/graphqlTypes';

const firstLetterOfWord = (word: string) => word?.charAt(0)?.toUpperCase() || '';

export const getInitials = (firstName?: string, lastName?: string) => {
  return [firstLetterOfWord(firstName), firstLetterOfWord(lastName)].join('');
};

export const getFullName = (firstName?: string, lastName?: string) => {
  return [firstName, lastName].filter((a) => a).join(' ');
};

export const getAddress = (person?: AudiencePerson) => {
  return (
    [person?.address, person?.city, person?.state, person?.zip]
      .map((component) => component?.trim())
      .filter((component) => !!component)
      .join(', ') || '-'
  );
};

export const getInviteLink = (email: string, inviter: string, organization: string) => {
  const inviteObj = `{"email": "${email}", "inviter": "${inviter}", "org": "${organization}"}`;
  return `${process.env.REACT_APP_URL}/signup?invite=${Base64.encode(inviteObj)}`;
};

export const getUserOrganizationType = (user: User) => {
  const isOrgAdmin = user?.scopes?.includes(Scope.OrgsAdmin) || false;
  if (isOrgAdmin || user?.organizations?.some((org) => org.organizationType === OrganizationType.WhiteLabel))
    return OrganizationType.WhiteLabel;
  if (user?.organizations?.some((org) => org.organizationType === OrganizationType.Agency))
    return OrganizationType.Agency;
  return OrganizationType.Customer;
};
