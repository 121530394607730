import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export const useExtendedTranslation = () => {
  const { t: ti18n } = useTranslation(['common', 'specific']);

  const t = (key, options = {}) => ti18n(key, options);
  const tSpecific = (key: string, options = {}) => ti18n(`specific:${key}`, options);
  const tOptional = (key: string, options = {}) =>
    i18next.exists(`common:${key}`) ? t(key, options) : tSpecific(key, options);
  const tOverrides = (key: string, options = {}) =>
    i18next.exists(`specific:${key}`) ? tSpecific(key, options) : t(key, options);

  return { t, tSpecific, tOptional, tOverrides };
};
