import moment from 'moment';
import React, { ComponentPropsWithoutRef, forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledCalendarIcon, StyledInput } from './DateTimeInput.styles';

const DATE_FORMAT = /^en\b/.test(window.navigator.language) ? 'MM/DD/YY' : 'DD/MM/YY';

interface DateTimeInputProps extends Omit<ComponentPropsWithoutRef<typeof StyledInput>, 'value' | 'onChange'> {
  value: Date;
  dateFormat?: string;
  onChange?: (date: Date) => void;
  setIsWrongDate?: (disabled: boolean) => void;
}

const DateTimeInput = forwardRef(
  ({ value, dateFormat = DATE_FORMAT, onChange, setIsWrongDate, ...props }: DateTimeInputProps, ref) => {
    const { t } = useTranslation();
    const { error, ...rest } = props;
    const formatDate = (val: Date) => (val ? moment(val).format(DATE_FORMAT) : '');
    const [valueInitial, setValueInitial] = React.useState(value);
    const [inputValue, setInputValue] = useState(formatDate(value));
    const [isDateInvalid, setDateInvalid] = useState(false);

    if (value !== null && valueInitial !== value) {
      setInputValue(formatDate(value));
      setValueInitial(value);
      setDateInvalid(!moment(value).isValid());
    }

    useEffect(() => {
      setIsWrongDate?.(isDateInvalid);
    }, [isDateInvalid]);

    const onValueChange = (event: any) => {
      const value = event.target.value as string;
      const currentDate = moment(value, DATE_FORMAT, true);
      setInputValue(value);
      if (currentDate.isValid()) {
        onChange?.(currentDate.toDate());
        setDateInvalid(false);
      } else {
        setDateInvalid(true);
      }
    };

    return (
      <StyledInput
        ref={ref}
        iconLeft={<StyledCalendarIcon />}
        placeholder={dateFormat}
        value={inputValue}
        error={error || (isDateInvalid && t('components.input.invalidDate'))}
        onChange={onValueChange}
        {...rest}
      />
    );
  },
);

export default DateTimeInput;
