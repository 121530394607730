import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.span`
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xs};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
`;

interface CardProps {
  $isDragging?: boolean;
  $isFileSelected?: boolean;
}

export const Card = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props: CardProps & ThemeProp) => (props.$isFileSelected ? 'white' : props.theme.colors.primary.c050)};
  opacity: ${(props: CardProps) => (props.$isDragging ? '0.5' : '1')};
  border: ${(props: CardProps & ThemeProp) =>
    props.$isFileSelected
      ? `1px solid ${props.theme.colors.neutrals.c050}`
      : `1px dashed ${props.theme.colors.primary.c500}`};
  border-radius: 0.25rem;
  padding: ${(props: CardProps) => (props.$isFileSelected ? '0.75rem' : '2rem')};
`;

export const Subtitle = styled.span<CardProps>`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xs};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
  text-align: ${(props: CardProps) => (props.$isFileSelected ? 'left' : 'center')};
  flex-shrink: 1;
`;

export const ClickableLink = styled(Subtitle)`
  cursor: pointer;
  color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
  stroke: ${(props: ThemeProp) => props.theme.colors.primary.c500};
  flex-shrink: 0;
`;

export const ActionLink = styled.span`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xs};
  color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
`;

export const FilenameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & > svg {
    min-width: 1.25rem;
  }
`;

export const Filename = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

interface IconContainerProps {
  $failed?: boolean;
}

export const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background: ${(props: ThemeProp & IconContainerProps) =>
    props.$failed ? '#FFEBEF' : props.theme.colors.lightGreenBackground};
  border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
  gap: 1rem;
  flex-shrink: 0;
`;
