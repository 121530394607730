import React, { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import EmptyStateImage from '../../overwrites/assets/audience/EmptyState';
import { ClickableLink, Container, Subtitle, Title } from '../EmptyListSearch/EmptyListSearch.styles';

interface EmptyListProps {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  searchText?: string;
  onClick?: () => void;
  narrow?: boolean;
  emptyListIcon?: ReactNode;
  maxSubtitleWidth?: string;
}

const EmptyList = ({ title, subtitle, onClick, narrow, emptyListIcon, maxSubtitleWidth, ...rest }: EmptyListProps) => {
  const { t } = useTranslation();

  return (
    <Container $narrow={narrow} {...rest}>
      {emptyListIcon || <EmptyStateImage />}
      <Title>{title || t('organizations.emptyStateTitle')}</Title>
      <Subtitle $maxWidth={maxSubtitleWidth}>
        {subtitle || (
          <Trans
            i18nKey={'organizations.emptyStateSubtitle'}
            components={{ span: <ClickableLink onClick={onClick} /> }}
          />
        )}
      </Subtitle>
    </Container>
  );
};

export default EmptyList;
