import styled from 'styled-components';

import { CloseIcon } from '../../../assets';
import { ThemeProp } from '../../../theme/Themes';
import { IconButton } from '../../';

interface Center {
  $centerTitle?: boolean;
}

export const HeaderContainer = styled.div<Center>`
  display: flex;
  width: 100%;
  justify-content: ${(props) => (props.$centerTitle ? 'center' : 'space-between')};
  position: relative;
  padding: 0.5rem 1rem 0rem 1.5rem;
  text-align: ${(props) => (props.$centerTitle ? 'center' : 'left')};
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  z-index: 100;
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    position: sticky;
    top: 0;
    padding: 0 1.5rem 1rem 1.5rem;
  }
`;

interface TitleProps {
  $hasRightPadding?: boolean;
}

export const Title = styled.span<TitleProps>`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.l};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xs};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  align-self: flex-end;
  padding-top: 1rem;
  padding-right: ${(props: TitleProps) => (props.$hasRightPadding ? '1rem' : '0')};
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 1rem;
  top: 0.75rem;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    right: 1rem;
    top: -0.25rem;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
`;
