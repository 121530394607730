import React from 'react';

const ChartDonutIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5460_46892)">
      <path
        d="M16.9078 12.9176C16.4307 14.0459 15.6844 15.0402 14.7342 15.8135C13.784 16.5868 12.6589 17.1156 11.4571 17.3536C10.2554 17.5917 9.01362 17.5317 7.84041 17.1789C6.6672 16.8261 5.59827 16.1914 4.72707 15.33C3.85586 14.4687 3.20891 13.4071 2.84278 12.238C2.47664 11.0689 2.40247 9.82792 2.62675 8.62353C2.85103 7.41914 3.36692 6.28803 4.12934 5.32908C4.89175 4.37013 5.87746 3.61255 7.0003 3.12256"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5V10H17.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5460_46892">
        <rect width="18" height="18" fill="white" transform="translate(1 1)" />
      </clipPath>
    </defs>
  </svg>
);

export default ChartDonutIcon;
