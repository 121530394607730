import React from 'react';

const LinkInNewTabIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 10.75V15.25C14.5 15.6478 14.342 16.0294 14.0607 16.3107C13.7794 16.592 13.3978 16.75 13 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V7C3.25 6.60218 3.40804 6.22064 3.68934 5.93934C3.97064 5.65804 4.35218 5.5 4.75 5.5H9.25"
      stroke="#818181"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.25 3.25H16.75V7.75" stroke="#818181" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.5 11.5L16.75 3.25" stroke="#818181" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default LinkInNewTabIcon;
