import React from 'react';

const BlankScreenIcon = (props: any) => {
  return (
    <svg height="177" width="216" fill="none" viewBox="0 0 216 177" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="95" cy="70" fill="#ED0A34" opacity="0.04" r="70" />
      <g>
        <rect height="44" width="145" fill="white" rx="4.79339" x="22" y="14" />
        <rect height="44" width="145" fill="url(#paint0_linear_4461_203507)" rx="4.79339" x="22" y="14" />
        <rect
          height="43.2698"
          width="144.27"
          rx="4.42827"
          stroke="#F4F4F4"
          strokeWidth="0.730243"
          x="22.3651"
          y="14.3651"
        />
      </g>
      <g>
        <rect height="44" width="145" fill="white" rx="4.79339" x="22" y="66" />
        <rect height="44" width="145" fill="url(#paint3_linear_4461_203507)" rx="4.79339" x="22" y="66" />
        <rect
          height="43.2698"
          width="144.27"
          rx="4.42827"
          stroke="#F4F4F4"
          strokeWidth="0.730243"
          x="22.3651"
          y="66.3651"
        />
      </g>
      <g>
        <circle cx="158" cy="105" fill="url(#paint6_linear_4461_203507)" r="23" />
      </g>
      <rect height="7" width="84" fill="url(#paint1_linear_4461_203507)" opacity="0.7" rx="3.5" x="66" y="32" />
      <rect height="24" width="24" fill="#EB4747" opacity="0.1" rx="4" x="32" y="24" />
      <rect height="7" width="84" fill="url(#paint4_linear_4461_203507)" opacity="0.7" rx="3.5" x="66" y="84" />
      <rect height="24" width="24" fill="#EB4747" opacity="0.1" rx="4" x="32" y="76" />
      <path
        d="M43.0455 37.4835C42.8425 37.9583 42.741 38.5954 42.741 39.3947H44.8354C44.8477 38.5293 45.0568 37.8712 45.4628 37.4204L46.5976 36.3296C47.5325 35.38 48 34.4154 48 33.4358C48 32.336 47.654 31.4796 46.962 30.8665C46.2701 30.2535 45.3059 29.947 44.0696 29.947C42.8702 29.947 41.9061 30.2565 41.1772 30.8756C40.4483 31.4946 40.0777 32.336 40.0654 33.3997H42.3074C42.3074 32.9009 42.4689 32.5058 42.7918 32.2143C43.1147 31.9228 43.5406 31.7771 44.0696 31.7771C44.6232 31.7771 45.043 31.9258 45.329 32.2233C45.615 32.5208 45.758 32.952 45.758 33.5169C45.758 33.9437 45.635 34.3403 45.389 34.7069C45.2291 34.9473 44.8492 35.3515 44.2495 35.9194C43.6498 36.4874 43.2485 37.0087 43.0455 37.4835ZM42.9234 40.7525C42.6958 40.977 42.582 41.2707 42.582 41.6336C42.582 41.9719 42.6912 42.2548 42.9095 42.4824C43.1279 42.71 43.44 42.8238 43.846 42.8238C44.2519 42.8238 44.5656 42.71 44.7871 42.4824C45.0085 42.2548 45.1192 41.9719 45.1192 41.6336C45.1192 41.283 45.0054 40.9924 44.7778 40.7617C44.5503 40.5311 44.2396 40.4157 43.846 40.4157C43.4585 40.4157 43.151 40.528 42.9234 40.7525Z"
        fill="url(#paint2_linear_4461_203507)"
        fillRule="evenodd"
        opacity="0.7"
      />
      <path
        d="M43.0455 89.4835C42.8425 89.9583 42.741 90.5954 42.741 91.3947H44.8354C44.8477 90.5293 45.0568 89.8712 45.4628 89.4204L46.5976 88.3296C47.5325 87.38 48 86.4154 48 85.4358C48 84.336 47.654 83.4796 46.962 82.8665C46.2701 82.2535 45.3059 81.947 44.0696 81.947C42.8702 81.947 41.9061 82.2565 41.1772 82.8756C40.4483 83.4946 40.0777 84.336 40.0654 85.3997H42.3074C42.3074 84.9009 42.4689 84.5058 42.7918 84.2143C43.1147 83.9228 43.5406 83.7771 44.0696 83.7771C44.6232 83.7771 45.043 83.9258 45.329 84.2233C45.615 84.5208 45.758 84.952 45.758 85.5169C45.758 85.9437 45.635 86.3403 45.389 86.7069C45.2291 86.9473 44.8492 87.3515 44.2495 87.9194C43.6498 88.4874 43.2485 89.0087 43.0455 89.4835ZM42.9234 92.7525C42.6958 92.977 42.582 93.2707 42.582 93.6336C42.582 93.9719 42.6912 94.2548 42.9095 94.4824C43.1279 94.71 43.44 94.8238 43.846 94.8238C44.2519 94.8238 44.5656 94.71 44.7871 94.4824C45.0085 94.2548 45.1192 93.9719 45.1192 93.6336C45.1192 93.283 45.0054 92.9924 44.7778 92.7617C44.5503 92.5311 44.2396 92.4157 43.846 92.4157C43.4585 92.4157 43.151 92.528 42.9234 92.7525Z"
        fill="url(#paint5_linear_4461_203507)"
        fillRule="evenodd"
        opacity="0.7"
      />
      <path
        d="M169.667 105C169.667 111.443 164.443 116.667 158 116.667C151.557 116.667 146.333 111.443 146.333 105C146.333 98.5567 151.557 93.3334 158 93.3334C164.443 93.3334 169.667 98.5567 169.667 105ZM156.5 110C156.5 110.828 157.172 111.5 158 111.5H158.014C158.842 111.5 159.514 110.828 159.514 110C159.514 109.172 158.842 108.5 158.014 108.5H158C157.172 108.5 156.5 109.172 156.5 110ZM158 106.5C158.829 106.5 159.5 105.828 159.5 105V100C159.5 99.1716 158.829 98.5 158 98.5C157.172 98.5 156.5 99.1716 156.5 100V105C156.5 105.828 157.172 106.5 158 106.5Z"
        fill="url(#paint7_linear_4461_203507)"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4461_203507"
          gradientUnits="userSpaceOnUse"
          x1="61.7356"
          x2="66.2666"
          y1="51.4423"
          y2="11.335"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4461_203507"
          gradientUnits="userSpaceOnUse"
          x1="64.6667"
          x2="158.046"
          y1="38.4167"
          y2="22.5223"
        >
          <stop stopColor="#EB4747" />
          <stop offset="1" stopColor="#EB4747" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4461_203507"
          gradientUnits="userSpaceOnUse"
          x1="40.1793"
          x2="46.991"
          y1="35.8796"
          y2="38.6994"
        >
          <stop stopColor="#EB4747" />
          <stop offset="1" stopColor="#E03B6C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4461_203507"
          gradientUnits="userSpaceOnUse"
          x1="61.7356"
          x2="66.2666"
          y1="103.442"
          y2="63.335"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4461_203507"
          gradientUnits="userSpaceOnUse"
          x1="64.6667"
          x2="158.046"
          y1="90.4167"
          y2="74.5223"
        >
          <stop stopColor="#EB4747" />
          <stop offset="1" stopColor="#EB4747" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_4461_203507"
          gradientUnits="userSpaceOnUse"
          x1="40.1793"
          x2="46.991"
          y1="87.8796"
          y2="90.6994"
        >
          <stop stopColor="#EB4747" />
          <stop offset="1" stopColor="#E03B6C" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_4461_203507"
          gradientUnits="userSpaceOnUse"
          x1="135.66"
          x2="167.533"
          y1="103.193"
          y2="124.606"
        >
          <stop stopColor="#EB4747" />
          <stop offset="1" stopColor="#E03B6C" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_4461_203507"
          gradientUnits="userSpaceOnUse"
          x1="167.193"
          x2="142.092"
          y1="108.301"
          y2="100.667"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BlankScreenIcon;
