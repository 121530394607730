import React from 'react';

const ChartFunnelIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="path-1-inside-1_5460_46828" fill="white">
      <rect x="2" y="3" width="16" height="6" rx="1" />
    </mask>
    <rect x="2" y="3" width="16" height="6" rx="1" strokeWidth="3" mask="url(#path-1-inside-1_5460_46828)" />
    <path
      d="M4.75 8.5C4.75 8.36193 4.86193 8.25 5 8.25H15C15.1381 8.25 15.25 8.36193 15.25 8.5V12C15.25 12.1381 15.1381 12.25 15 12.25H5C4.86193 12.25 4.75 12.1381 4.75 12V8.5Z"
      strokeWidth="1.5"
    />
    <path
      d="M7.75 12.5C7.75 12.3619 7.86193 12.25 8 12.25H12C12.1381 12.25 12.25 12.3619 12.25 12.5V15.9994C12.25 16.1375 12.1381 16.2494 12 16.2494H8C7.86193 16.2494 7.75 16.1375 7.75 15.9994V12.5Z"
      strokeWidth="1.5"
    />
  </svg>
);

export default ChartFunnelIcon;
