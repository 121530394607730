import React from 'react';

const AudienceTypeIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7973 17.5013H4.20252C3.2813 17.5013 2.53516 16.7552 2.53516 15.8339V6.66345C2.53516 5.74224 3.2813 4.99609 4.20252 4.99609H15.7973C16.7186 4.99609 17.4647 5.74224 17.4647 6.66345V15.8339C17.4647 16.7552 16.7177 17.5013 15.7973 17.5013Z"
      stroke="#575757"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7.5 7.5L7.5 14" stroke="#575757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12.5 14.3333L12.5 7.74988"
      stroke="#575757"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6738 9.5L12.5867 7.5871L14.4996 9.5"
      stroke="#575757"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 12.4128L7.5871 14.4999L9.5 12.587"
      stroke="#575757"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.98242 4.99714V4.16345C3.98242 3.24224 4.72857 2.49609 5.64978 2.49609H14.3492C15.2705 2.49609 16.0166 3.24224 16.0166 4.16345V4.99714"
      stroke="#575757"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AudienceTypeIcon;
