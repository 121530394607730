import React from 'react';

const AttributionSourceIcon = (props: any) => {
  return (
    <svg height="20" width="20" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.1577 11.175L11.1827 17.15C11.0279 17.305 10.8441 17.4279 10.6417 17.5118C10.4394 17.5956 10.2225 17.6388 10.0035 17.6388C9.78449 17.6388 9.56761 17.5956 9.36528 17.5118C9.16295 17.4279 8.97914 17.305 8.82435 17.15L1.66602 9.99999V1.66666H9.99935L17.1577 8.82499C17.4681 9.13726 17.6423 9.55968 17.6423 9.99999C17.6423 10.4403 17.4681 10.8627 17.1577 11.175V11.175Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path d="M5.83203 5.83334H5.84036" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </svg>
  );
};

export default AttributionSourceIcon;
