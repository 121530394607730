import React, { ComponentProps } from 'react';

import { inMobile, useWindowDimensions } from '../../hooks/useDimensions';
import { PaginationContainer, StyledPagination } from './Pagination.styles';

interface PaginationProps {
  page: number;
  total: number;
  containerStyle?: ComponentProps<'div'>['style'];
  onChangePage?: (page: number) => void;
}

const Pagination = ({ page, total, containerStyle, onChangePage, ...rest }: PaginationProps) => {
  const { width } = useWindowDimensions();
  return (
    <PaginationContainer style={containerStyle}>
      <StyledPagination
        siblings={inMobile(width) ? 0 : 1}
        size={inMobile(width) ? 'md' : 'lg'}
        total={total}
        page={page}
        onChange={onChangePage}
        {...rest}
      />
    </PaginationContainer>
  );
};

export default Pagination;
