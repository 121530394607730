import React, { createContext, FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type Nullable<T> = T | null;

export interface TabsContextProps {
  scrollLeft: Nullable<number>;
  handleScrollLeft: (scroll: number) => void;
}

interface TabsProviderProps {
  children: ReactNode;
  id?: string;
}

/* eslint-disable */
const defaultProps: TabsContextProps = {
  scrollLeft: null,
  handleScrollLeft: undefined,
};
/* eslint-enable */

const TabsContext = createContext<TabsContextProps>(defaultProps);

export const TabsProvider: FC<TabsProviderProps> = ({ children }) => {
  const location = useLocation();
  const [scrollLeft, setScrollLeft] = React.useState<Nullable<number>>(null);

  const handleScrollLeft = (scroll: number) => {
    setScrollLeft(scroll);
  };

  // Math the id from path (after /app/entity/id). Works for audiences and organizations.
  const id = location.pathname.match(/\/([^/]+)\/([^/]+)\/([^/]+)/)?.[3];

  useEffect(() => {
    // reset scroll after changing entity
    setScrollLeft(0);
  }, [id]);

  return (
    <TabsContext.Provider
      value={{
        scrollLeft,
        handleScrollLeft,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};

export const useTabContext: () => TabsContextProps = () => {
  return React.useContext(TabsContext);
};
