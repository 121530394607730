import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

interface ListItemGradientContainerProps {
  $noExpand?: boolean;
  $gap?: boolean;
}

const ListItemGradientContainer = styled.div<ListItemGradientContainerProps>`
  width: ${(props: ListItemGradientContainerProps) => (props.$noExpand ? 'unset' : '100%')};
  display: flex;
  position: relative;
  overflow: hidden;
  ${(props: ListItemGradientContainerProps) =>
    props.$gap &&
    `
    gap: 0.5rem;
  `}

  :after {
    content: '';
    background: ${(props: ThemeProp) => props.theme.gradients.overflowGradient};
    z-index: 2;
    position: absolute;
    right: 0;
    height: 1.5rem;
    width: 2rem;
  }
`;

export default ListItemGradientContainer;
