import styled from 'styled-components';

import DateRangePicker from '../../components/DateRangePicker';
import { ThemeProp } from '../../theme/Themes';
import { Badge } from '../layout/ListHeader/components/FilterButton/FilterButton.styles';

export const FilterButton = styled.button`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.s};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
  border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  border: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c200};
  position: relative;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  gap: 0.5rem;
  display: flex;
  align-items: center;
`;

interface ButtonTextProps {
  breakpoint?: string;
}

export const ButtonText = styled.div<ButtonTextProps>`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: ${(props: ButtonTextProps & ThemeProp) => props.breakpoint || props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const StyledBadge = styled(Badge)<ButtonTextProps>`
  display: none;
  @media (max-width: ${(props: ButtonTextProps & ThemeProp) => props.breakpoint || props.theme.breakpoints.tablet}) {
    display: flex;
  }
`;

export const StyledDateRangePicker = styled(DateRangePicker)`
  padding-top: 1rem;
`;
