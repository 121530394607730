import React from 'react';

const StarsIcon = (props: any) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M18.3274 2.46094C18.5264 1.9232 19.287 1.9232 19.486 2.46094L20.949 6.41476C21.0116 6.58382 21.1449 6.71711 21.3139 6.77967L25.2677 8.24272C25.8055 8.4417 25.8055 9.20226 25.2677 9.40124L21.3139 10.8643C21.1449 10.9268 21.0116 11.0601 20.949 11.2292L19.486 15.183C19.287 15.7208 18.5264 15.7208 18.3274 15.183L16.8644 11.2292C16.8018 11.0601 16.6685 10.9268 16.4995 10.8643L12.5457 9.40124C12.0079 9.20226 12.0079 8.4417 12.5457 8.24272L16.4995 6.77967C16.6685 6.71711 16.8018 6.58382 16.8644 6.41476L18.3274 2.46094Z" />
    <path d="M9.68046 12.5439C9.87944 12.0062 10.64 12.0062 10.839 12.5439L12.302 16.4978C12.3646 16.6668 12.4979 16.8001 12.6669 16.8627L16.6208 18.3257C17.1585 18.5247 17.1585 19.2853 16.6208 19.4842L12.6669 20.9473C12.4979 21.0098 12.3646 21.1431 12.302 21.3122L10.839 25.266C10.64 25.8038 9.87944 25.8038 9.68046 25.266L8.21742 21.3122C8.15486 21.1431 8.02156 21.0098 7.8525 20.9473L3.89868 19.4842C3.36095 19.2853 3.36095 18.5247 3.89868 18.3257L7.8525 16.8627C8.02156 16.8001 8.15486 16.6668 8.21742 16.4978L9.68046 12.5439Z" />
  </svg>
);

export default StarsIcon;
