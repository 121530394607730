import { useEffect, useRef, useState } from 'react';

import { lightTheme } from '../overwrites/Theme/theme';

export function useWindowDimensions() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const lastWidth = useRef<number>();

  useEffect(() => {
    function handleResize() {
      if (window?.innerWidth !== lastWidth.current) {
        const width = window.innerWidth;
        lastWidth.current = width;
        setWindowWidth(width);
      }
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    width: windowWidth,
    isInMobile: inMobile(windowWidth),
    isInTablet: inTablet(windowWidth),
    isInDesktop: inDesktop(windowWidth),
    isInCustomBreakpoint: (customBreakpoint: string) => inCustomBreakpoint(customBreakpoint, windowWidth),
  };
}

export const parsePx = (px: string) => Number(px.replace('px', ''));

export const inSmallMobile = (width?: number) =>
  (width || window.innerWidth) <= Number(parsePx(lightTheme.breakpoints.smallMobile));

export const inMobile = (width?: number) =>
  (width || window.innerWidth) <= Number(parsePx(lightTheme.breakpoints.mobile));

export const inMobileXL = (width?: number) =>
  (width || window.innerWidth) <= Number(parsePx(lightTheme.breakpoints.xlMobile));

export const inTablet = (width?: number) =>
  (width || window.innerWidth) <= Number(parsePx(lightTheme.breakpoints.tablet));

export const inDesktop = (width?: number) =>
  (width || window.innerWidth) <= Number(parsePx(lightTheme.breakpoints.desktop));

export const inCustomBreakpoint = (customBreakpoint: string, width?: number) =>
  (width || window.innerWidth) > Number(parsePx(customBreakpoint));
