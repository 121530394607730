import { Button } from '@nextui-org/react';
import styled from 'styled-components';

interface IconButtonProps {
  width?: string;
  height?: string;
}

const IconButton = styled(Button)<IconButtonProps>`
  min-width: unset;
  width: ${(props: IconButtonProps) => props.width || '2rem'};
  height: ${(props: IconButtonProps) => props.height || '2rem'};
  background-color: transparent;
  box-shadow: none;
`;

export default IconButton;
