import React from 'react';

const InfoIcon = (props: any) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity="0.1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill={props.fill || '#2D2D2D'}
    />
    <g opacity="0.6">
      <path
        d="M8.02148 4.66545C7.90643 4.66545 7.81306 4.75882 7.81389 4.87387C7.81389 4.98892 7.90726 5.08229 8.02231 5.08229C8.13736 5.08229 8.23073 4.98892 8.23073 4.87387C8.23073 4.75882 8.13736 4.66545 8.02148 4.66545"
        stroke={props.stroke || '#2D2D2D'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.23212 11.4873V7.52734H7.39844"
        stroke={props.stroke || '#2D2D2D'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default InfoIcon;
