import { Dropdown as NextDropdown, Loading } from '@nextui-org/react';
import React, { Key, ReactNode, useMemo } from 'react';

import { ChevronIcon } from '../../assets';
import Badge from '../FilterBadge';
import HelpTooltip from '../HelpTooltip';
import {
  Container,
  DropDownButton,
  DropdownError,
  DropDownItemContainer,
  DropDownItemDescription,
  DropDownItemLeftContainer,
  DropDownItemText,
  DropDownMenu,
  DropDownValueText,
  Separator,
  StyledDropdown,
  Title,
} from './Dropdown.styles';
import useDropdown from './useDropdown';

export interface DropdownItemProps<T> {
  key?: string;
  text?: string;
  value?: T;
  description?: string;
  selected?: boolean;
  icon?: ReactNode;
  disabled?: boolean;
}

export type DropdownSelection = number | number[];

export interface DropDownProps<T = any> extends React.ComponentPropsWithoutRef<typeof Container> {
  title?: string;
  items?: DropdownItemProps<T>[];
  selectedIndex?: number;
  placeholder?: string;
  multiselect?: boolean;
  helpText?: string;
  disabled?: boolean;
  hideBorder?: boolean;
  disabledKeys?: Key[];
  customTrigger?: ReactNode;
  onSelectionChange?: (selection: DropdownSelection) => void;
  loading?: boolean;
  disallowEmptySelection?: boolean;
  error?: string;
  required?: boolean;
  showBadge?: boolean;
  placeholderIcon?: ReactNode;
  showSelectedValue?: boolean;
  autoMenuWidth?: boolean;
}

const Dropdown = (props: DropDownProps) => {
  const {
    items,
    title,
    selectedIndex,
    placeholder,
    multiselect,
    helpText,
    disabled,
    disabledKeys,
    customTrigger,
    hideBorder,
    disallowEmptySelection = !multiselect,
    onSelectionChange,
    loading = false,
    error = '',
    required = false,
    showBadge,
    placeholderIcon,
    showSelectedValue = true,
    ...rest
  } = props;
  const { selected, setSelected, selectedValue, selectedIcon, disabledKeysLocal, buttonRef, buttonWidth } =
    useDropdown(props);

  const onLocalSelectionChange = (keys: 'all' | Set<Key>) => {
    if (keys === 'all') return;

    setSelected(keys);
    const selectedIndexes = Array.from(keys || []).map((key) => Number(key));
    onSelectionChange?.(multiselect ? selectedIndexes : selectedIndexes[0]);
  };

  const dropDownComponent = useMemo(
    () => (
      <DropDownMenu
        width={!props.autoMenuWidth && buttonWidth}
        selectionMode={multiselect ? 'multiple' : 'single'}
        disallowEmptySelection={disallowEmptySelection}
        selectedKeys={selected}
        disabledKeys={disabledKeysLocal}
        onSelectionChange={onLocalSelectionChange}
      >
        {items.map((item, index) => (
          <NextDropdown.Item key={index.toString()} textValue={index.toString()}>
            <DropDownItemContainer>
              <DropDownItemLeftContainer>
                {item.icon}
                <DropDownItemText>{item.text}</DropDownItemText>
              </DropDownItemLeftContainer>
              {item.description && <DropDownItemDescription>{item.description}</DropDownItemDescription>}
            </DropDownItemContainer>
            {index !== items.length - 1 && <Separator />}
          </NextDropdown.Item>
        ))}
      </DropDownMenu>
    ),
    [
      buttonWidth,
      disallowEmptySelection,
      disabledKeysLocal,
      items,
      multiselect,
      onLocalSelectionChange,
      selected,
      selectedValue,
    ],
  );

  return (
    <Container {...rest}>
      {title && (
        <Title>
          {title}
          {required && ' *'}
          {helpText && <HelpTooltip $helpText={helpText} />}
        </Title>
      )}
      <StyledDropdown error={error} isDisabled={disabled} isBordered={!hideBorder}>
        {customTrigger ? (
          <StyledDropdown.Trigger>{customTrigger}</StyledDropdown.Trigger>
        ) : (
          <DropDownButton
            error={error}
            helpText={error}
            disabled={disabled || loading}
            ref={buttonRef}
            color={error ? 'error' : 'default'}
            isBordered={!hideBorder}
            iconRight={loading ? <Loading type="points-opacity" size="sm" /> : <ChevronIcon />}
          >
            <DropDownItemLeftContainer>
              {showSelectedValue ? selectedIcon : placeholderIcon}
              <DropDownValueText>
                {(showSelectedValue ? selectedValue || placeholder : placeholder) || ''}
              </DropDownValueText>
              {showBadge && <Badge>1</Badge>}
            </DropDownItemLeftContainer>
          </DropDownButton>
        )}
        {dropDownComponent}
      </StyledDropdown>
      {error && <DropdownError>{error}</DropdownError>}
    </Container>
  );
};

export default Dropdown;
