import React from 'react';

const LocationIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16 8.66675C16 13.3334 10 17.3334 10 17.3334C10 17.3334 4 13.3334 4 8.66675C4 7.07545 4.63214 5.54933 5.75736 4.42411C6.88258 3.29889 8.4087 2.66675 10 2.66675C11.5913 2.66675 13.1174 3.29889 14.2426 4.42411C15.3679 5.54933 16 7.07545 16 8.66675Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10.6667C11.1046 10.6667 12 9.77132 12 8.66675C12 7.56218 11.1046 6.66675 10 6.66675C8.89543 6.66675 8 7.56218 8 8.66675C8 9.77132 8.89543 10.6667 10 10.6667Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LocationIcon;
