import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const EmailVerifiedIcon = () => {
  const theme = useContext(ThemeContext);
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
        fill={theme.colors.primary.c500}
      />
      <path
        d="M10.3307 4.5L5.7474 9.08333L3.66406 7"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmailVerifiedIcon;
