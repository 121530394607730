import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import EmptyListFilter from '../../overwrites/assets/EmptyListFilter';
import EmptyList from '../EmptyList';
import EmptyListSearch from '../EmptyListSearch';
import { ClickableLink } from '../EmptyListSearch/EmptyListSearch.styles';

interface EmptyStateComponentProps {
  hasSearchFilter: boolean;
  hasDropdownFilter?: boolean;
  searchValue?: string;
  handleEmptyListSearch?: () => void;
  emptyListTitleKey: string;
  emptyListSubtitleKey: string;
  handleEmptyList?: () => void;
  narrow?: boolean;
  emptyListIcon?: React.ReactNode;
  dropdownFilterValue?: string;
  handleEmptyFilter?: () => void;
  maxSubtitleWidth?: string;
}
const EmptyStateComponent = ({
  hasSearchFilter,
  hasDropdownFilter = false,
  searchValue = '',
  handleEmptyListSearch = null,
  emptyListTitleKey,
  emptyListSubtitleKey,
  handleEmptyList = null,
  narrow = false,
  emptyListIcon = null,
  dropdownFilterValue = null,
  handleEmptyFilter = null,
  maxSubtitleWidth,
  ...rest
}: EmptyStateComponentProps) => {
  const { t } = useTranslation();

  return hasSearchFilter ? (
    <EmptyListSearch
      title={t('common.emptyStateSearchTitle', { search: searchValue, interpolation: { escapeValue: false } })}
      searchText={searchValue}
      narrow={narrow}
      subtitle={
        <Trans
          i18nKey={'common.emptyStateSearchSubtitle'}
          components={{ span: <ClickableLink onClick={handleEmptyListSearch} /> }}
        />
      }
      {...rest}
    />
  ) : hasDropdownFilter ? (
    <EmptyListSearch
      title={t('common.emptyStateDropdownTitle', { value: dropdownFilterValue, interpolation: { escapeValue: false } })}
      searchText={dropdownFilterValue}
      narrow={narrow}
      emptyListIcon={<EmptyListFilter />}
      subtitle={
        <Trans
          i18nKey={'common.emptyStateDropdownSubtitle'}
          components={{ span: <ClickableLink onClick={handleEmptyFilter} /> }}
        />
      }
      {...rest}
    />
  ) : (
    <EmptyList
      narrow={narrow}
      emptyListIcon={emptyListIcon}
      title={t(emptyListTitleKey)}
      subtitle={
        <Trans i18nKey={emptyListSubtitleKey} components={{ span: <ClickableLink onClick={handleEmptyList} /> }} />
      }
      maxSubtitleWidth={maxSubtitleWidth}
      {...rest}
    />
  );
};

export default EmptyStateComponent;
