import styled from 'styled-components';

import { ThemeProp } from '../../../theme/Themes';

export const StyledTableBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  border-radius: unset;
  box-shadow: none;
  text-align: left;
  position: relative;
  border-radius: none;
  border-radius: 0;
  max-height: 15rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props: ThemeProp) => props.theme.colors.neutrals.c100};
    max-height: 3rem;
    border-radius: 2.5rem;
  }
`;

interface StyledTableProps {
  $mobilePadding?: boolean;
}

export const StyledTable = styled.div<StyledTableProps>`
  display: flex;
  flex-direction: column;
  height: auto;
  min-width: 100%;
  border-radius: unset;
  box-shadow: none;
  margin-top: 0.25rem;
  padding: 0 0.75rem 0 0.75rem;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    padding: ${(props: StyledTableProps) => (props.$mobilePadding ? '0' : '0 0.75rem 0 0.75rem')};

    tbody {
      border-radius: 0;
      max-height: 20rem;
    }
  }
`;

interface SelectedProps {
  $isSelected?: boolean;
  $top?: number;
  $hide?: boolean;
}

export const StyledTableRow = styled.div.attrs((props: SelectedProps) => ({
  style: {
    top: props.$top ? `${props.$top}px` : 'auto',
  },
}))<SelectedProps & DraggingProps>`
  display: ${(props) => (props.$hide ? 'none' : 'flex')};
  margin: 0.125rem 0;
  padding: 0.5rem;
  justify-content: space-between;
  border-radius: 0.25rem;
  background-color: ${(props: ThemeProp & SelectedProps) =>
    props?.$isSelected ? props.theme.colors.primaryLight : props.theme.colors.neutrals.white};
  justify-content: space-between;
  cursor: pointer;
  left: 0.75rem !important;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    left: 1.25rem !important;
    flex-direction: row-reverse;
    max-width: 15rem;
  }

  ${(props: ThemeProp & SelectedProps & DraggingProps) =>
    props?.$isDragging &&
    `
    background-color: ${props.theme.colors.neutrals.white};
    border: 1px solid ${props.theme.colors.neutrals.c050};
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.2);
    `}
`;

export const StyledTableCell = styled.div<SelectedProps>`
  text-align: left;
  border-radius: 0;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};

  color: ${(props: ThemeProp & SelectedProps) =>
    props?.$isSelected ? props.theme.colors.primary.c500 : props.theme.colors.neutrals.black};
`;

interface LeftComponentProps {
  $disabledDrag?: boolean;
}
export const LeftComponent = styled.div<LeftComponentProps>`
  display: flex;
  width: ${(props: LeftComponentProps) => (props.$disabledDrag ? '100%' : 'calc(100% - 1.5rem)')};
  align-items: center;
  margin-right: 0.2rem;
  gap: 0.5rem;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

interface DraggingProps {
  $isDragging?: boolean;
}

export const RightComponent = styled.div<DraggingProps>`
  display: flex;
  width: 1.5rem;
  justify-content: flex-end;
  stroke: ${(props: ThemeProp & DraggingProps) =>
    props?.$isDragging ? props.theme.colors.neutrals.c400 : props.theme.colors.neutrals.c200};

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    flex-direction: row-reverse;
  }
`;

export const SelectAllContainer = styled.div`
  display: flex;
  padding: 0.75rem 1.25rem;
`;
