import i18next from 'i18next';
import { DateRange } from 'moment-range';
import React from 'react';

import { GlobalSearchIcon, PixelIcon } from '~/assets';
import { CustomerListIcon, SegmentsIcon } from '~/assets/audience';
import { AudienceNode } from '~/constants/graphqlTypes';
import {
  AudienceCondition,
  AudienceFilterDateField,
  AudienceFilterNumberField,
  AudiencePixelStatus,
  FilterExtendedStringField,
  PersonFilterInput,
  UtmGroup,
} from '~/types/api.graphql';

import { MatchTypeValue } from '../components/MatchTypeDropdown/MatchTypeDropdown';

export const allConditionsArray = [
  AudienceCondition.Pixel,
  AudienceCondition.CustomerList,
  AudienceCondition.Global,
  AudienceCondition.Segment,
];

export const allPixelStatuses = [
  AudiencePixelStatus.Enabled,
  AudiencePixelStatus.Disabled,
  AudiencePixelStatus.QuotaReached,
  AudienceFilterNumberField.QuotaUsagePercentage,
  AudienceFilterDateField.LastNewMatchDate,
];

const conditionIconsMap = {
  [AudienceCondition.Pixel]: <PixelIcon />,
  [AudienceCondition.Global]: <GlobalSearchIcon />,
  [AudienceCondition.Segment]: <SegmentsIcon />,
  [AudienceCondition.CustomerList]: <CustomerListIcon />,
};

export const conditionIcon = (condition: AudienceCondition) => conditionIconsMap[condition] || <GlobalSearchIcon />;

export const conditionTitle = (condition: AudienceCondition) =>
  i18next.exists(`specific:audience.overview.${condition}`)
    ? i18next.t(`specific:audience.overview.${condition}`)
    : condition;

type AudienceWithCondition = Pick<AudienceNode, 'condition' | 'parentAudience'>;

export const shouldShowPixelComponents = (audience?: AudienceWithCondition) =>
  audience?.condition === AudienceCondition.Pixel || audience?.parentAudience?.condition === AudienceCondition.Pixel;

export const shouldShowGlobalComponents = (audience?: AudienceWithCondition) =>
  audience?.condition === AudienceCondition.Global || audience?.parentAudience?.condition === AudienceCondition.Global;

export const dateFilterFromDateRange = (dateRange: DateRange, matchType: MatchTypeValue) => {
  const startDateString = dateRange.start.toISOString(true).split('T')[0];
  const endDateString = dateRange.end.toISOString(true).split('T')[0];
  return {
    dateFilter: {
      field: matchType,
      operator: { between: { from: startDateString, to: endDateString } },
    },
  };
};

export interface AttributionSource {
  group: UtmGroup;
  name: string;
}

export const attributionSourceFilters = (attributionSource: AttributionSource): PersonFilterInput[] => {
  if (!attributionSource) return [];

  const params: string[] = [];

  const addParam = (group: UtmGroup, name: string) => {
    params.push(`${mapAttributions[group]}=${encodeURIComponent(name.trim())}`);
  };

  const mapAttributions: Record<string, string> = {
    [UtmGroup.Source]: 'utm_source',
    [UtmGroup.Campaign]: 'utm_campaign',
    [UtmGroup.Medium]: 'utm_medium',
  };
  if (attributionSource.group !== UtmGroup.SourceAndMedium) {
    addParam(attributionSource.group, attributionSource.name);
  } else {
    const names = attributionSource.name.split('/');
    if (names.length !== 2) return [];
    addParam(UtmGroup.Source, names[0]);
    addParam(UtmGroup.Medium, names[1]);
  }

  return params.map((param) => ({
    extendedStringFilter: {
      field: FilterExtendedStringField.PixelHitUrl,
      operator: { contains: param },
    },
  }));
};
