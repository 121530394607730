import React from 'react';

const QuotaIcon = (props: any) => {
  return (
    <svg height="28" width="28" fill="none" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.1331 15.3984H21.9971L24.7318 18.1331V21.9971L21.9971 24.7318H18.1331L15.3984 21.9971V18.1331L18.1331 15.3984Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M11.6667 17.5H8.16667C5.58934 17.5 3.5 19.5893 3.5 22.1667V23.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <ellipse
        cx="12.8346"
        cy="8.16667"
        rx="4.66667"
        ry="4.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default QuotaIcon;
