import styled from 'styled-components';

import Input from '~/components/Input';

import { SearchIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';

export const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: unset;
  box-shadow: none;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    padding: 0;

    tbody {
      border-radius: 0;
      max-height: 20rem;
    }
  }
`;

interface SelectedProps {
  $isSelected?: boolean;
  $disabled?: boolean;
}

export const StyledTableBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  border-radius: unset;
  box-shadow: none;
  text-align: left;
  position: relative;
  border-radius: none;
  border-radius: 0;
  max-height: 15rem;
  height: 15rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props: ThemeProp) => props.theme.colors.neutrals.c100};
    max-height: 3rem;
    border-radius: 2.5rem;
  }
`;

const getBackgroundColor = (props: ThemeProp & SelectedProps) => {
  if (props?.$disabled) {
    return '#F7F7F7';
  }
  if (props?.$isSelected) {
    return props.theme.colors.primaryLight;
  }
  return props.theme.colors.neutrals.white;
};

export const StyledTableRow = styled.div<SelectedProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.125rem 0;
  padding: 0.5rem;
  gap: 0.5rem;
  border-radius: 0.25rem;
  background-color: ${(props: ThemeProp & SelectedProps) => getBackgroundColor(props)};

  cursor: ${(props: SelectedProps) => (props.$disabled ? 'not-allowed' : 'pointer')};
`;

const getColor = (props: ThemeProp & SelectedProps) => {
  if (props?.$disabled) {
    return props.theme.colors.neutrals.c400;
  }
  if (props?.$isSelected) {
    return props.theme.colors.primary.c500;
  }
  return props.theme.colors.neutrals.black;
};

export const StyledTableCell = styled.div<SelectedProps>`
  text-align: left;
  border-radius: 0;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  cursor: ${(props: SelectedProps) => (props.$disabled ? 'not-allowed' : 'pointer')};

  color: ${(props: ThemeProp & SelectedProps) => getColor(props)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TableLeftContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;
`;

export const TableCellDescription = styled.span`
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
  font-style: italic;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  flex-shrink: 0;
`;

export const StyledInput = styled(Input)`
  height: 2.75rem !important;
  width: calc(100% - 0.75rem);
  margin: 0 auto;
  margin-bottom: 0 !important;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
`;

export const NoResults = styled.span`
  margin: 0.125rem 1rem;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  display: flex;
  align-items: center;
  height: 2.5rem;
`;
