import React from 'react';

import HelpTooltip from '../HelpTooltip';
import { Container, StyledSwitch, Title } from './Switch.styles';

export interface SwitchProps {
  checked: boolean;
  onChange: () => void;
  title?: string;
  disabled?: boolean;
  helpText?: string;
  size?: any;
  alignTitle?: 'left' | 'right';
}

const Switch = ({ title, helpText, size = 'sm', alignTitle = 'right', ...props }: SwitchProps) => {
  return (
    <Container>
      <StyledSwitch initialChecked={props.checked} size={size} $alignTitle={alignTitle} {...props} />
      {title && <Title $alignTitle={alignTitle}>{title}</Title>}
      {helpText && <HelpTooltip $helpText={helpText} />}
    </Container>
  );
};

export default Switch;
