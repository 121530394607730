import { Amplify, Auth, Storage } from 'aws-amplify';
import { MutableRefObject } from 'react';

export function configureAmplify() {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
      ...(process.env.REACT_APP_COGNITO_ENDPOINT && {
        endpoint: process.env.REACT_APP_COGNITO_ENDPOINT,
      }),
      authenticationFlowType: 'CUSTOM_AUTH',
      // Set a default metadata for when it's not present in the Auth calls.
      // If other metadata is sent in the call, the requiredClientMetadata should be manually included.
      clientMetadata: requiredClientMetadata,
    },
    Storage: {
      bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      region: process.env.REACT_APP_COGNITO_REGION,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    },
  });
}

export const requiredClientMetadata = { domain: window.location.hostname };

function generateName(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export interface UploadFileConfig {
  bucket?: string;
  url?: string;
  path?: string;
}

export interface UploadProgress {
  loaded: number;
  total: number;
}

export async function uploadFile(
  file: File,
  progressCallback?: (progress: UploadProgress) => void,
  uploadOperationRef?: MutableRefObject<any>,
  config?: UploadFileConfig,
  isPrivate?: boolean,
): Promise<string> {
  const fileName = `${config?.path || ''}${generateName()}`;
  const bucket = config?.bucket || process.env.REACT_APP_S3_BUCKET_NAME;
  try {
    const existingFile = await Storage.get(fileName, {
      download: true,
      bucket,
      level: isPrivate ? 'private' : 'public',
    });
    if (existingFile) {
      // File already exist so trigger a new name
      return uploadFile(file, progressCallback, uploadOperationRef, config, isPrivate);
    }
  } catch {
    // File doesn't exist
    const uploadOperation = Storage.put(fileName, file, {
      progressCallback,
      contentType: file.type,
      bucket,
      level: isPrivate ? 'private' : 'public',
    });

    if (uploadOperationRef) {
      uploadOperationRef.current = uploadOperation;
    }
    const result = await uploadOperation;
    const middlePrefix = await getMiddlePrefix(isPrivate);
    return `${config?.url || process.env.REACT_APP_S3_URI}${middlePrefix}${result.key}`;
  }
  return '';
}

async function getMiddlePrefix(isPrivate: boolean): Promise<string> {
  if (!isPrivate) return '';
  const cognitoIdentityId = (await Auth.currentUserCredentials()).identityId;
  return cognitoIdentityId + '/';
}
