import React from 'react';

const IntegrationsIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.91962 8.96094H12.088C12.5485 8.96094 12.9217 9.33419 12.9217 9.79462V10.8367C12.9217 11.8727 12.0819 12.7125 11.0459 12.7125H8.96172C7.92575 12.7125 7.08594 11.8727 7.08594 10.8367V9.79462C7.08594 9.33419 7.45919 8.96094 7.91962 8.96094Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8.36997 8.95391V7.07812" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.6434 8.95391V7.07812" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M13.3378 16.7121C16.6484 15.0651 18.2641 11.2508 17.1438 7.72705C16.0236 4.2033 12.5018 2.02224 8.84802 2.58939C5.19424 3.15654 2.49936 6.30256 2.5 10.0001C2.49825 12.0718 3.32141 14.0589 4.78762 15.5224V15.5224C5.87866 16.6093 7.35594 17.2198 8.896 17.2198V17.2198C9.50726 17.2193 10.0027 16.7239 10.0031 16.1126V12.7096"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IntegrationsIcon;
