import React, { useEffect, useState } from 'react';

import { AvatarProps, StyledAvatar } from './Avatar.styles';

const Avatar = (props: AvatarProps) => {
  const [complete, setComplete] = useState(true);

  useEffect(() => {
    if (!props.src) return;
    // check if the image is cached and disable opacity animation if needed
    const imgTemp = document.createElement('img');
    imgTemp.src = props.src;
    setComplete(imgTemp.complete);
  }, []);

  return <StyledAvatar complete={complete} {...props} />;
};

export default Avatar;
