import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyListIcon from '../../overwrites/assets/EmptyListIcon';
import { Container, Subtitle, SubtitleContainer, Title } from './EmptyListSearch.styles';

interface EmptyListSearchProps {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  searchText?: string;
  onClearSearch?: () => void;
  narrow?: boolean;
  emptyListIcon?: ReactNode;
}

const EmptyListSearch = ({
  title,
  subtitle,
  searchText,
  onClearSearch,
  narrow = false,
  emptyListIcon,
  ...rest
}: EmptyListSearchProps) => {
  const { t } = useTranslation();
  return (
    <Container $narrow={narrow} {...rest}>
      {emptyListIcon || <EmptyListIcon />}
      <Title>{title || t('organization.members.emptyListTitleShort')}</Title>
      <SubtitleContainer>
        <Subtitle>{subtitle || t('organization.members.emptyListSubtitleShort')}</Subtitle>
      </SubtitleContainer>
    </Container>
  );
};

export default EmptyListSearch;
