import i18next from 'i18next';
import React, { useMemo } from 'react';

import { AgencyOrgIcon, CustomerOrgIcon, WhiteLabelOrgIcon } from '~/assets/organization';
import {
  Organization,
  OrganizationPageQueryOrganization,
  OrganizationQueryOrganization,
  User,
} from '~/constants/graphqlTypes';
import { useSessionContext } from '~/contexts/SessionContext';
import { OrganizationType, UserOrganizationRole } from '~/types/api.graphql';

import { lowercaseFirstLetter } from './helpers';

export const allRoles = [
  UserOrganizationRole.SuperOwner,
  UserOrganizationRole.Owner,
  UserOrganizationRole.Member,
  UserOrganizationRole.ViewOnly,
];

export const mapOrgTypeIcons = {
  [OrganizationType.WhiteLabel]: <WhiteLabelOrgIcon />,
  [OrganizationType.Agency]: <AgencyOrgIcon />,
  [OrganizationType.Customer]: <CustomerOrgIcon />,
};

export const getOrganizationInitials = (organizationName: string) => {
  const words = organizationName.split(' ');
  const firstWord = words[0];
  const secondWord = words[1];
  const firstLetter = firstWord[0] || '';
  const secondLetter = secondWord ? secondWord[0] : firstWord[1] || '';
  return `${firstLetter}${secondLetter}`.toUpperCase();
};

export interface OrganizationAddress {
  addressLine1?: string;
  address?: string;
  city?: string;
  state?: string;
}

export const getOrganizationAddress = (organization: OrganizationAddress) => {
  return [organization?.addressLine1 || organization?.address, organization?.city, organization?.state]
    .map((component) => component?.trim())
    .filter((component) => !!component)
    .join(', ');
};

export const validateOrgPlan = async (organization: Organization, getSubscriptionInfo) => {
  if (organization?.subscriptionOverride) return true;
  if (!organization?.paidSubscription) return false;
  try {
    const result = await getSubscriptionInfo();
    return !!result?.data?.subscriptionInfo;
  } catch (error) {
    return false;
  }
};

export const isB2BOrg = (organization: Organization) => organization?.businessDataEnabled;
export const isFreeOrg = (organization: Organization) =>
  !(organization.paidSubscription || organization.subscriptionOverride);

export const getOrganizationRole = (organization: OrganizationPageQueryOrganization, me: User) => {
  const userRole = organization?.userRoles?.find((user) => user?.user?.id === me?.id);
  if (userRole) {
    return i18next.t(`organization.members.roles.${lowercaseFirstLetter(userRole?.role)}`);
  }

  return i18next.t('organizations.notMember');
};

interface OrganizationUserRole {
  role: UserOrganizationRole;
  user?: {
    id: string;
  };
}

export interface OrganizationWithRoles {
  userRoles?: OrganizationUserRole[];
}

export const doesUserHaveRoleInOrg = <OrgType extends OrganizationWithRoles>(
  org: OrgType,
  userId: string,
  roles: UserOrganizationRole[],
) => {
  return org?.userRoles?.some((userRole) => userRole?.user?.id === userId && roles.includes(userRole?.role));
};

export const isParentOrgMember = (org: OrganizationQueryOrganization, userId: string) => {
  if (!org?.parentOrganization || !userId) return false;

  const isOrgMember = (org: Partial<OrganizationQueryOrganization>) =>
    doesUserHaveRoleInOrg(org, userId, [
      UserOrganizationRole.SuperOwner,
      UserOrganizationRole.Owner,
      UserOrganizationRole.Member,
    ]);
  return (
    isOrgMember(org?.parentOrganization) ||
    isOrgMember(org?.parentOrganization?.['parentOrganization'] as OrganizationQueryOrganization)
  );
};

export const isOrganizationOwned = (org: OrganizationQueryOrganization, userId: string) => {
  return (
    doesUserHaveRoleInOrg(org, userId, [UserOrganizationRole.SuperOwner, UserOrganizationRole.Owner]) ||
    isParentOrgMember(org, userId)
  );
};

export const isOrganizationSuperOwned = (org: OrganizationQueryOrganization, userId: string) => {
  const whitelabelOrganization = [
    org,
    org?.parentOrganization as OrganizationQueryOrganization,
    org?.parentOrganization?.['parentOrganization'] as OrganizationQueryOrganization,
  ].find((organization) => organization?.organizationType === OrganizationType.WhiteLabel);
  return doesUserHaveRoleInOrg(whitelabelOrganization, userId, [UserOrganizationRole.SuperOwner]);
};

export const useRoles = (org: OrganizationQueryOrganization) => {
  const { me, isOrgAdmin } = useSessionContext();

  const rolesInvite = useMemo(() => {
    const shouldShowSuperOwner =
      org?.organizationType === OrganizationType.WhiteLabel && (isOrgAdmin || isOrganizationSuperOwned(org, me?.id));
    return shouldShowSuperOwner ? allRoles : allRoles.filter((role) => role !== UserOrganizationRole.SuperOwner);
  }, [allRoles, me, isOrgAdmin, org]);

  const rolesFilter = useMemo(() => {
    return org?.organizationType === OrganizationType.WhiteLabel
      ? allRoles
      : allRoles.filter((role) => role !== UserOrganizationRole.SuperOwner);
  }, [allRoles, org]);

  return { rolesInvite, rolesFilter };
};
