import React from 'react';

const ChartLineIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 14L5.08044 14.7701C5.59607 14.899 6.12245 14.602 6.27875 14.0941L7.54252 9.9868C7.7566 9.29105 8.61859 9.04986 9.16266 9.53348L11.4925 11.6045C11.9941 12.0503 12.7837 11.8854 13.0649 11.2761L15.5 6"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M2 3.5V17.5H15.5" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default ChartLineIcon;
