import React from 'react';

const PlusIcon = (props: any) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1 5.99984H11" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.00002 11V1" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default PlusIcon;
