import React from 'react';

const PeopleIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.01699 15.8333C1.01699 16.1923 1.30801 16.4833 1.66699 16.4833C2.02598 16.4833 2.31699 16.1923 2.31699 15.8333H1.01699ZM11.017 15.8333C11.017 16.1923 11.308 16.4833 11.667 16.4833C12.026 16.4833 12.317 16.1923 12.317 15.8333H11.017ZM2.31699 15.8333C2.31699 14.359 3.52598 13.15 5.00033 13.15V11.85C2.80801 11.85 1.01699 13.641 1.01699 15.8333H2.31699ZM5.00033 13.15H8.33366V11.85H5.00033V13.15ZM8.33366 13.15C9.80801 13.15 11.017 14.359 11.017 15.8333H12.317C12.317 13.641 10.526 11.85 8.33366 11.85V13.15Z"
      fill={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="transparent"
    />
    <path
      d="M8.75022 5.00029C9.91689 6.16696 9.91689 8.00029 8.75022 9.08362C7.58356 10.167 5.75022 10.2503 4.66689 9.08362C3.58356 7.91696 3.50022 6.16696 4.58356 5.00029C5.66689 3.83362 7.58356 3.91696 8.75022 5.00029"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
    <path
      d="M13.334 11.018C12.975 11.018 12.684 11.309 12.684 11.668C12.684 12.027 12.975 12.318 13.334 12.318V11.018ZM17.684 14.168C17.684 14.527 17.975 14.818 18.334 14.818C18.693 14.818 18.984 14.527 18.984 14.168H17.684ZM13.334 12.318H15.834V11.018H13.334V12.318ZM15.834 12.318C16.8917 12.318 17.684 13.1103 17.684 14.168H18.984C18.984 12.3923 17.6096 11.018 15.834 11.018V12.318Z"
      fill={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="transparent"
    />
    <path
      d="M16.0841 5.58594C16.9174 6.41927 16.9174 7.7526 16.0841 8.5026C15.2507 9.25261 13.9174 9.33594 13.1674 8.5026C12.4174 7.66927 12.3341 6.33594 13.1674 5.58594C13.9174 4.83594 15.2507 4.83594 16.0841 5.58594"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
  </svg>
);

export default PeopleIcon;
