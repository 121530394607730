import { Modal } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';
import { Button, SecondaryButton } from '../';
import BottomSheet from '../BottomSheet';
import { ButtonRole } from '../Button';

export const StyledModal = styled(Modal)`
  display: flex;
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    display: none;
  }
  &.nextui-modal {
    border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
  }
  .nextui-modal-body {
    padding: 0;
  }
`;

export const StyledBottomSheet = styled(BottomSheet)`
  display: none;
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    display: flex;
  }
  [data-rsbs-content] {
    padding: 0;
  }
`;

interface ButtonProps {
  $buttonRole: ButtonRole;
}

export const StyledButton = styled(Button)<ButtonProps>`
  background-color: ${(props) => props.$buttonRole.backgroundColor(props.theme)};
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    flex: 1;
  }
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    flex: 1;
  }
`;

interface FooterProps {
  $reverse?: boolean;
  $shadow?: boolean;
}

export const StyledFooter = styled.div<FooterProps>`
  position: relative;
  display: flex;
  flex-direction: ${(props: FooterProps) => (props.$reverse ? 'row-reverse' : 'row')};
  width: 100%;
  gap: 1rem;
  justify-content: ${(props: FooterProps) => (props.$reverse ? 'flex-start' : 'flex-end')};
  padding: ${(props: FooterProps) => (props.$shadow ? '1.5rem' : '0')} 1.5rem 1.5rem 1.5rem;
  z-index: 1;
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    padding: 2rem 1.5rem 2rem 1.5rem;
  }
  box-shadow: ${(props: FooterProps) =>
    props.$shadow ? '0px -2px 5px 0px rgba(0, 0, 0, 0.03), 0px -2px 16px 0px rgba(0, 0, 0, 0.04)' : 'none'};
`;

interface BodyContainerProps {
  $padding?: string;
  $disableBottomPadding?: boolean;
}

export const BodyContainer = styled.div<BodyContainerProps>`
  padding: ${(props: BodyContainerProps) =>
    props.$padding || `1rem 1.5rem ${props.$disableBottomPadding ? '0' : '1rem'} 1.5rem`};
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    padding: ${(props: BodyContainerProps) => props.$padding || '0 1.5rem'};
  }
`;
