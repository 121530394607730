import { Button as NextButton, ButtonProps as NextButtonProps, Loading } from '@nextui-org/react';
import React from 'react';
import styled from 'styled-components';

import { ThemeProp, ThemeType } from '~/theme/Themes';
import { isTouchDevice } from '~/utils/helpers';

enum ButtonRoleEnum {
  Cancel,
  Destructive,
  Primary,
}

export class ButtonRole {
  static Cancel: ButtonRole = new ButtonRole(ButtonRoleEnum.Cancel);
  static Destructive: ButtonRole = new ButtonRole(ButtonRoleEnum.Destructive);
  static Primary: ButtonRole = new ButtonRole(ButtonRoleEnum.Primary);

  private buttonRole: ButtonRoleEnum;

  constructor(buttonRole: ButtonRoleEnum) {
    this.buttonRole = buttonRole;
  }

  backgroundColor(theme: ThemeType): string {
    switch (this.buttonRole) {
      case ButtonRoleEnum.Destructive:
        return theme.colors.darkRed;
      default:
        return theme.colors.primary.c500;
    }
  }
}

interface ButtonProps extends NextButtonProps {
  loading?: boolean;
  loadingSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  disableMinWidth?: boolean;
}

const StyledLoading = styled(Loading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const Button = styled(({ children, loading, loadingSize = 'md', onPress, ...props }: ButtonProps) => (
  <NextButton
    {...props}
    // FIX: safari does not fire onPress event with opened soft keyboard
    onPressEnd={(e) => isTouchDevice() && onPress?.(e)}
    onPress={(e) => !isTouchDevice() && onPress?.(e)}
  >
    {loading && <StyledLoading size={loadingSize} />}
    {children}
  </NextButton>
)).attrs((props: ButtonProps & ThemeProp) => ({
  ...props,
  css: {
    position: 'relative',
    'pointer-events': props.loading ? 'none' : 'auto',
    'border-radius': '$xxs',
    'font-size': '$base',
    'font-weight': '$normal',
    height: '$14',
    'min-width': props.disableMinWidth ? undefined : '$25',
    padding: '$6a $8',
    color: props.loading ? 'transparent' : props.color,
    gap: '$5',
    '&:disabled': {
      background: props.theme.colors.primary.c500,
      opacity: 0.2,
      color: props.theme.colors.neutrals.white,
    },
    ...props.css,
  },
}))`
  .nextui-loading {
    --nextui--loadingColor: ${(props: ThemeProp) => props.theme.colors.neutrals.c100};
  }
  .nextui-button-icon {
    margin-right: 0;
  }
  .nextui-button-text {
    z-index: 1;
  }
`;

export default Button;
