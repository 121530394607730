import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const DisclaimerIcon = (props: any) => {
  const theme = useContext(ThemeContext);
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_201_2)">
        <path
          opacity="0.08"
          d="M26 0H6C2.68629 0 0 2.68629 0 6V26C0 29.3137 2.68629 32 6 32H26C29.3137 32 32 29.3137 32 26V6C32 2.68629 29.3137 0 26 0Z"
          fill={theme.colors.primary.c500}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7973 23.5013H10.2025C9.2813 23.5013 8.53516 22.7552 8.53516 21.8339V12.6635C8.53516 11.7422 9.2813 10.9961 10.2025 10.9961H21.7973C22.7186 10.9961 23.4647 11.7422 23.4647 12.6635V21.8339C23.4647 22.7552 22.7177 23.5013 21.7973 23.5013Z"
          stroke={theme.colors.primary.c500}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9983 13.5C17.1496 13.5 18.0825 14.4329 18.0825 15.5842C18.0825 16.7355 17.1496 17.6684 15.9983 17.6684C14.847 17.6684 13.9141 16.7355 13.9141 15.5842C13.9141 14.4329 14.847 13.5 15.9983 13.5Z"
          stroke={theme.colors.primary.c500}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.7531 21.0005C19.6439 20.7262 19.4747 20.4795 19.2579 20.2786C18.8919 19.9384 18.4126 19.75 17.9124 19.75C17.0787 19.75 14.9244 19.75 14.0908 19.75C13.5906 19.75 13.112 19.9392 12.7452 20.2786C12.5284 20.4795 12.3592 20.7262 12.25 21.0005"
          stroke={theme.colors.primary.c500}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.98438 10.9971V10.1635C9.98438 9.24224 10.7305 8.49609 11.6517 8.49609H20.3512C21.2724 8.49609 22.0186 9.24224 22.0186 10.1635V10.9971"
          stroke={theme.colors.primary.c500}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_201_2">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DisclaimerIcon;
