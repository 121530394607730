import React, { SVGProps } from 'react';

const HelpIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg height="20" width="20" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.8327 14.1663H14.9993C14.5393 14.1663 14.166 13.793 14.166 13.333V9.16634C14.166 8.70634 14.5393 8.33301 14.9993 8.33301H15.8327C16.7535 8.33301 17.4993 9.07884 17.4993 9.99967V12.4997C17.4993 13.4205 16.7535 14.1663 15.8327 14.1663Z"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M5 14.1663H4.16667C3.24583 14.1663 2.5 13.4205 2.5 12.4997V9.99967C2.5 9.07884 3.24583 8.33301 4.16667 8.33301H5C5.46 8.33301 5.83333 8.70634 5.83333 9.16634V13.333C5.83333 13.793 5.46 14.1663 5 14.1663Z"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M15.4173 8.33333V7.91667C15.4173 4.925 12.9923 2.5 10.0007 2.5V2.5C7.00898 2.5 4.58398 4.925 4.58398 7.91667V8.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M10.5208 17.7083H9.47917C8.90417 17.7083 8.4375 17.2417 8.4375 16.6667V16.6667C8.4375 16.0917 8.90417 15.625 9.47917 15.625H10.5208C11.0958 15.625 11.5625 16.0917 11.5625 16.6667V16.6667C11.5625 17.2417 11.0958 17.7083 10.5208 17.7083Z"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M11.5625 16.667H13.3333C14.2542 16.667 15 15.9212 15 15.0003V14.167"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default HelpIcon;
