import React from 'react';

const GoogleTagManagerIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.4384 26.8515L11.5781 22.1122L22.0161 11.4531L27.0481 16.3849L16.4384 26.8515Z" fill="#8AB4F8" />
    <path
      d="M16.4556 5.89767L11.6128 0.953125L1.02375 11.442C-0.34125 12.807 -0.34125 15.0199 1.02375 16.3865L11.4538 26.8849L16.3856 22.2713L8.43898 13.9143L16.4556 5.89767Z"
      fill="#4285F4"
    />
    <path
      d="M26.9743 11.5249L16.4743 1.02494C15.1077 -0.341648 12.8915 -0.341648 11.5249 1.02494C10.1584 2.39153 10.1584 4.60767 11.5249 5.97426L22.0249 16.4743C23.3915 17.8409 25.6077 17.8409 26.9743 16.4743C28.3409 15.1077 28.3409 12.8915 26.9743 11.5249Z"
      fill="#8AB4F8"
    />
    <path
      d="M13.9205 28.0011C15.8095 28.0011 17.3409 26.4697 17.3409 24.5806C17.3409 22.6915 15.8095 21.1602 13.9205 21.1602C12.0314 21.1602 10.5 22.6915 10.5 24.5806C10.5 26.4697 12.0314 28.0011 13.9205 28.0011Z"
      fill="#246FDB"
    />
  </svg>
);

export default GoogleTagManagerIcon;
