import styled from 'styled-components';

import { UserProfileIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';

const StyledUserProfileIcon = styled(UserProfileIcon)`
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
`;

export default StyledUserProfileIcon;
