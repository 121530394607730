import React from 'react';

interface Props {
  className?: string;
}

const EditProfileIcon = (props: Props) => {
  return (
    <svg height="20" width="20" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.3033 4.6967C18.2322 7.62563 18.2322 12.3744 15.3033 15.3033C12.3743 18.2322 7.62562 18.2322 4.6967 15.3033C1.76777 12.3743 1.76777 7.62562 4.6967 4.6967C7.62563 1.76777 12.3744 1.76777 15.3033 4.6967"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M11.658 6.93659C12.5734 7.85204 12.5734 9.33629 11.658 10.2517C10.7425 11.1672 9.25829 11.1672 8.34284 10.2517C7.42739 9.33629 7.42739 7.85204 8.34284 6.93659C9.25829 6.02114 10.7425 6.02114 11.658 6.93659"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M14.7539 15.7983C13.558 14.5392 11.8714 13.75 9.99802 13.75C8.12469 13.75 6.43802 14.5392 5.24219 15.7992"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default EditProfileIcon;
