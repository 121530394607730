import styled, { css } from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const TourTooltipContainer = styled.div`
  background-color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  max-width: 19.5rem;
`;

export const TourStepTitle = styled.span`
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.semibold};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.mlg};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.mlg};
`;

export const TourStepDescription = styled.span`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.m};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.m};
  margin-top: 0.75rem;
`;

const buttonCss = css`
  border-radius: 0.5rem;
  padding: 0.625rem 1.5rem;
  border: none;
  cursor: pointer;
`;

export const NextButton = styled.button`
  ${buttonCss}
  color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    flex: 1;
  }
`;

export const SkipButton = styled.button`
  ${buttonCss}
  background: transparent;
  margin-right: 1rem;
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LastRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
`;

export const NumberCount = styled.span`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.m};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.m};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  opacity: 0.6;
`;
