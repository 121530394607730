import React from 'react';
import { useTranslation } from 'react-i18next';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { useAppContext } from '../../contexts/AppContext';
import useCopy from '../../hooks/useCopy';
import { CopyButton, CopyButtonContainer, CopyIconStyled, StyledCodeBlock, StyledDiv } from './CodeBlock.styles';

interface CodeBlockProps {
  textToCopy: string;
  language: string;
  isEscapedString?: boolean;
}

const CodeBlock = ({ textToCopy, language, isEscapedString = false }: CodeBlockProps): any => {
  const [copied, copy, setCopied] = useCopy(isEscapedString ? decodeHtml(textToCopy) : textToCopy);
  const { t } = useTranslation();
  const { showSuccess } = useAppContext();

  const copyText = () => {
    copy();
    showSuccess(t('common.copiedToClipboard'));

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  function decodeHtml(html: string) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.documentElement.textContent;
  }

  return (
    <StyledDiv>
      <StyledCodeBlock
        useInlineStyles={false}
        showLineNumbers
        showInlineLineNumbers
        language={language}
        wrapLongLines
        style={darcula}
      >
        {isEscapedString ? decodeHtml(textToCopy) : textToCopy}
      </StyledCodeBlock>
      <CopyButtonContainer onClick={copyText}>
        <CopyButton disabled={copied}>
          <CopyIconStyled />
        </CopyButton>
      </CopyButtonContainer>
    </StyledDiv>
  );
};

export default CodeBlock;
