import { Table } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../../theme/Themes';

export const StyledTable = styled(Table)`
  height: auto;
  min-width: 100%;
  border-radius: unset;
  box-shadow: none;
  margin-top: 0.25rem;
  padding: 0 0.75rem 0 0.75rem;

  thead {
    tr {
      display: none;
    }
  }

  tbody {
    border-radius: 0;
    max-height: 15rem;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.25rem;
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props: ThemeProp) => props.theme.colors.neutrals.c100};
      max-height: 3rem;
      border-radius: 2.5rem;
    }

    display: flex;
    flex-direction: column;
    padding-bottom: 0.5rem;
  }

  td {
    text-align: left;

    ::before {
      background: none !important;
    }

    label {
      transform: scale(0.8);
    }

    label:hover {
      div {
        background: none !important;
      }
    }
    .nextui-checkbox-mask {
      ::before {
        background: transparent !important;
        border: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c200} !important;
      }
    }
  }

  td:first-child {
    padding: 0.485rem 0.5rem;
  }

  td:last-child {
    font-size: ${(props: ThemeProp) => props.theme.fontSize.m};
    padding: 0.485rem 0 0.485rem 0.5rem;
  }

  tr {
    --nextui--tableRowColor: transparent;
    --nextui--tableRowTextColor: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
    padding: 0 0.5rem;
    padding-left: 0;
    margin: 0.125rem 0;

    border-radius: ${(props: ThemeProp) => props.theme.borderRadius} !important;

    &:hover {
      --nextui--tableRowColor: ${(props: ThemeProp) => props.theme.colors.primaryLight};
      --nextui--tableRowTextColor: ${(props: ThemeProp) => props.theme.colors.primary.c500};
      background: ${(props: ThemeProp) => props.theme.colors.primaryLight};
      color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
      opacity: 1;

      @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
        --nextui--tableRowColor: unset;
        --nextui--tableRowTextColor: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
        color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
        background: unset;
      }
    }
  }

  tr[aria-selected='true'] {
    background: ${(props: ThemeProp) => props.theme.colors.primary.c500}12;
    color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
  }

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    padding: 0;

    tbody {
      border-radius: 0;
      max-height: 20rem;
    }

    tr {
      display: flex;
      flex-direction: row-reverse;
      margin: 0.25rem 0;
      justify-content: space-between;
    }

    tr[aria-selected='true'] {
      background: ${(props: ThemeProp) => props.theme.colors.primary.c500}12;
      color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
    }

    td:last-child {
      padding-left: 0.5rem;
    }
  }
`;

export const StyledTableHeader = styled(Table.Header)`
  display: none;
`;

export const StyledTableBody = styled(Table.Body)`
  border-radius: unset;
  box-shadow: none;
  text-align: left;
  border-radius: none;

  .nextui-table-row[aria-selected='true'] {
    background-color: ${(props: ThemeProp) => props.theme.colors.primaryLight};
  }
`;

export const StyledTableRow = styled(Table.Row)`
  background-color: ${(props: ThemeProp) => props.theme.colors.primaryLight};
  .nextui-table-row--selected {
    background-color: ${(props: ThemeProp) => props.theme.colors.primaryLight};
  }
  .nextui-table-row[aria-selected='true'] {
    background-color: ${(props: ThemeProp) => props.theme.colors.primaryLight};
  }
`;

export const StyledTableCell = styled(Table.Cell)`
  text-align: left;
  border-radius: 0;

  .nextui-table-row--selected {
    background-color: ${(props: ThemeProp) => props.theme.colors.primaryLight};
  }
`;
