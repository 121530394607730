import React from 'react';

const AgencyOrgIcon = (props: any) => {
  return (
    <svg height="20" width="20" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4 17V3H16V17H5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M8 17V12H12V17" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M7.5 6.5H12M7.5 9H12" strokeLinecap="round" strokeWidth="1.5" />
    </svg>
  );
};

export default AgencyOrgIcon;
