import React from 'react';

const ChartMapIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12.5 3.25781V14.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.5 4.73438V16.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M17.4993 5.4272V15.0991C17.4993 15.8504 16.7015 16.3343 16.0332 15.9914C15.0041 15.4633 13.7657 14.8384 13.499 14.75C13.1073 14.6202 12.8339 14.6196 12.4424 14.75C11.9719 14.9067 8.48979 16.25 8.48979 16.25C8.02064 16.4842 7.46817 16.485 6.99902 16.25L2.96081 14.5102C2.67832 14.3693 2.5 14.0802 2.5 13.7644V4.31558C2.5 3.74644 3.05747 3.34479 3.59661 3.52478L6.97229 4.64973C7.31477 4.7639 7.68392 4.7639 8.02641 4.64973L11.9696 3.33562C12.3129 3.22146 12.6829 3.22146 13.0262 3.33562L16.9293 4.6364C17.2693 4.74973 17.4993 5.06805 17.4993 5.4272Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChartMapIcon;
