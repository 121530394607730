import React from 'react';

const ErrorIcon = (props: any) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
      fill="#ED0A34"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 11C6.44772 11 6 10.5523 6 10C6 9.44772 6.44772 9 7 9H7.00833C7.56062 9 8.00833 9.44772 8.00833 10C8.00833 10.5523 7.56062 11 7.00833 11H7ZM8 7C8 7.55228 7.55228 8 7 8C6.44772 8 6 7.55228 6 7V4C6 3.44771 6.44772 3 7 3C7.55228 3 8 3.44771 8 4V7Z"
      fill="white"
    />
  </svg>
);

export default ErrorIcon;
