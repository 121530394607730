import React from 'react';

const TaxonomyIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.11792 4.97266C3.23292 5.88099 2.59042 7.02266 2.28125 8.29599"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.74219 17.0332C8.23469 17.1398 8.74552 17.199 9.27052 17.199C10.0139 17.199 10.7314 17.0857 11.4064 16.8765"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.28125 11.6992C2.59042 12.9726 3.23292 14.1142 4.11792 15.0226"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.1715 12.0508C15.8223 13.2274 15.1773 14.2749 14.3281 15.1158"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3281 4.87891C15.1773 5.71974 15.8215 6.76724 16.1715 7.94391"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.74219 2.96422C8.23469 2.85755 8.74552 2.79839 9.27052 2.79839C10.0139 2.79839 10.7314 2.91172 11.4064 3.12089"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9471 8.52426C18.7607 9.33785 18.7607 10.6569 17.9471 11.4705C17.1335 12.2841 15.8144 12.2841 15.0008 11.4705C14.1872 10.6569 14.1872 9.33785 15.0008 8.52426C15.8144 7.71066 17.1335 7.71066 17.9471 8.52426"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.36116 14.6375C8.17475 15.4511 8.17475 16.7702 7.36116 17.5838C6.54756 18.3974 5.22847 18.3974 4.41488 17.5838C3.60129 16.7702 3.60129 15.4511 4.41488 14.6375C5.22847 13.8239 6.54757 13.8239 7.36116 14.6375"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.36116 2.41098C8.17475 3.22457 8.17475 4.54366 7.36116 5.35725C6.54756 6.17084 5.22847 6.17084 4.41488 5.35725C3.60129 4.54366 3.60129 3.22457 4.41488 2.41098C5.22847 1.59738 6.54757 1.59738 7.36116 2.41098"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TaxonomyIcon;
