import React from 'react';

const BusinessInsightsIcon = (props: any) => {
  return (
    <svg height="28" width="28" fill="none" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22.4 8.75H5.6C4.4402 8.75 3.5 9.6902 3.5 10.85V21.35C3.5 22.5098 4.4402 23.45 5.6 23.45H22.4C23.5598 23.45 24.5 22.5098 24.5 21.35V10.85C24.5 9.6902 23.5598 8.75 22.4 8.75Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M18.1998 23.4501V6.65005C18.1998 6.09309 17.9786 5.55895 17.5847 5.16512C17.1909 4.7713 16.6568 4.55005 16.0998 4.55005H11.8998C11.3429 4.55005 10.8087 4.7713 10.4149 5.16512C10.0211 5.55895 9.7998 6.09309 9.7998 6.65005V23.4501"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default BusinessInsightsIcon;
