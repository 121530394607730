import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import styled from 'styled-components';

import { CopyIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';

export const StyledDiv = styled.div`
  display: flex;
  background: ${(props: ThemeProp) => props.theme.colors.lightBlackBackground} !important;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  border-radius: ${(props: ThemeProp) => props.theme.borderRadius} !important;
  padding: 0.5rem !important;
`;

export const StyledCodeBlock = styled(SyntaxHighlighter)`
  overflow: unset !important;
  margin: 0;
  padding: 0;
  width: 100%;
  border-radius: ${(props: ThemeProp) => props.theme.borderRadius};

  .language-javascript {
    display: inline-block !important;
    position: relative;
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.c300} !important;
    background: ${(props: ThemeProp) => props.theme.colors.lightBlackBackground} !important;
    padding: 0.6rem;
    word-break: break-all !important;
    white-space: break-spaces !important;
    font-size: ${(props: ThemeProp) => props.theme.fontSize.s} !important;

    & > span {
      display: unset !important;
    }
  }
  .string {
    color: ${(props: ThemeProp) => props.theme.colors.neon} !important;
    font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
  }
`;

export const CopyButton = styled.button`
  border: none;
  background: none;
  align-items: flex-start;
  display: flex;
  cursor: pointer;
`;

export const CopyButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CopyIconStyled = styled(CopyIcon)`
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
`;
