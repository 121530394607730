import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { DragIcon } from '../../../assets';
import { useDebounce } from '../../../hooks/useDebounce';
import { inMobile, useWindowDimensions } from '../../../hooks/useDimensions';
import { ColumnExtendedType } from '../../../views/audiences/PeopleList/PeopleList';
import Checkbox from '../../Checkbox';
import { TableItemType } from '../ColumnsTable';
import {
  LeftComponent,
  RightComponent,
  SelectAllContainer,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
} from './TableComponent.styles';

const TableComponent = ({
  onItemSelected,
  handleSelectAll,
  itemsToShow,
  onOrderChange,
  isItemSelected,
  isDragging,
  popoverContentRef,
  setIsDragging,
  isSelectAllIndeterminate,
  isAllSelected,
  disabledDrag = false,
  mobilePadding = false,
}: {
  itemsToShow: TableItemType[];
  handleSelectAll: (selected: boolean) => void;
  onItemSelected: (id: string, selected: boolean) => void;
  disallowEmptySelection?: boolean;
  isItemSelected: (id: string) => boolean;
  onOrderChange?: (columnsOrdered: ColumnExtendedType[]) => void;
  isDragging: boolean;
  setIsDragging: (isDragging: boolean) => void;
  popoverContentRef: React.RefObject<HTMLDivElement>;
  isSelectAllIndeterminate?: boolean;
  isAllSelected?: boolean;
  disabledDrag?: boolean;
  mobilePadding?: boolean;
}) => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  const HEIGHT_OF_ROW = 41;
  const HEIGHT_OF_HEADER = 92;
  const HEIGHT_OF_MODAL = inMobile(width) ? 20 : 0;

  const reorder = (list: TableItemType[], startIndex: number, endIndex: number) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);

    return list;
  };

  const [items, setItems] = useState<TableItemType[]>(itemsToShow);
  const [popoverPositionWhenOpened, setPopoverPositionWhenOpened] = React.useState(0);

  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollDebounced = useDebounce(scrollPosition, 100) as number;

  const minItemIndex = useMemo(
    () =>
      itemsToShow.reduce(
        (minIndex, item, index) => (item.show ? Math.min(minIndex, index) : minIndex),
        Number.MAX_VALUE,
      ),
    [itemsToShow],
  );

  const onDragEnd = (result) => {
    setIsDragging(false);
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newItems = reorder(
      itemsToShow,
      result.source.index as number,
      Math.max(result.destination.index as number, minItemIndex),
    );
    setItems(newItems);
    onOrderChange?.(
      newItems.map((item, index) => ({
        column: item.column.column,
        order: index,
        selected: isItemSelected(item.key),
      })),
    );

    handleScroll(document.getElementById('tableBody').scrollTop, false);
  };

  useEffect(() => {
    setItems(itemsToShow);
  }, [itemsToShow]);

  useEffect(() => {
    if (isDragging && !inMobile(width)) {
      setPopoverPositionWhenOpened(popoverContentRef?.current?.getBoundingClientRect().top);
    }
  }, [isDragging]);

  const handleScroll = (scrollTop: number, isDragging: boolean) => {
    if (!isDragging) {
      setScrollPosition(scrollTop);
      if (!inMobile(width)) {
        setPopoverPositionWhenOpened(popoverContentRef?.current?.getBoundingClientRect().top);
      }
    }
  };

  // This is needed to set the top position of the item being scrolled
  // Because needs to be relative to the position of the popover / bottom sheet
  const getTopPosition = useCallback(
    (key: string, index: number) => {
      const itemsBeingShown = items.filter((item) => item.show);
      const hasSearch = itemsBeingShown?.length !== items?.length;

      // If there is a search, the maths needs to take this into account
      const realIndex = hasSearch ? itemsBeingShown.findIndex((item) => item.key === key) : index;
      // Get the new popover top position (if the user triggers scroll while dragging)
      let realDiff = 0;
      if (!inMobile(width)) {
        const popoverTopPosition = popoverContentRef?.current?.getBoundingClientRect().top;
        const diff = Math.abs(popoverPositionWhenOpened - popoverTopPosition);
        // Check if sum or subtraction is needed based on initial and current position
        realDiff = popoverPositionWhenOpened > popoverTopPosition ? diff : -diff;
      }

      const topPosition = realIndex * HEIGHT_OF_ROW + HEIGHT_OF_HEADER + HEIGHT_OF_MODAL - scrollDebounced + realDiff;
      return topPosition;
    },
    [scrollDebounced, items, popoverPositionWhenOpened, popoverContentRef],
  );

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={() => setIsDragging(true)}>
        <StyledTable $mobilePadding={mobilePadding}>
          <Droppable droppableId="list">
            {(provided) => (
              <StyledTableBody
                id="tableBody"
                onScroll={(e) => handleScroll(e.currentTarget.scrollTop, isDragging)}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {items.map((item, index) => (
                  <>
                    <Draggable isDragDisabled={disabledDrag} key={item.key} draggableId={item.key} index={index}>
                      {(provided, snapshot) => (
                        <StyledTableRow
                          $isSelected={isItemSelected(item.key) && !snapshot.isDragging}
                          $isDragging={snapshot.isDragging}
                          $top={getTopPosition(item.key, index)}
                          onClick={() => onItemSelected(item.key, !isItemSelected(item.key))}
                          $hide={!item.show}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <LeftComponent $disabledDrag={disabledDrag}>
                            <Checkbox
                              aria-label="checkbox"
                              isSelected={isItemSelected(item.key)}
                              size="md"
                              onChange={(selected: boolean) => {
                                onItemSelected(item.key, selected);
                              }}
                            />
                            <StyledTableCell $isSelected={isItemSelected(item.key)}>{item.label}</StyledTableCell>
                          </LeftComponent>
                          {!disabledDrag && (
                            <RightComponent $isDragging={snapshot.isDragging}>
                              <DragIcon />
                            </RightComponent>
                          )}
                        </StyledTableRow>
                      )}
                    </Draggable>
                  </>
                ))}
                {provided.placeholder}
              </StyledTableBody>
            )}
          </Droppable>
        </StyledTable>
      </DragDropContext>
      <SelectAllContainer>
        <Checkbox isSelected={isAllSelected} isIndeterminate={isSelectAllIndeterminate} onChange={handleSelectAll}>
          {t('audience.people.selectAll')}
        </Checkbox>
      </SelectAllContainer>
    </>
  );
};

export default TableComponent;
