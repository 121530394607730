import { Textarea } from '@nextui-org/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSessionContext } from '~/contexts/SessionContext';

import {
  Container,
  StyledTickIcon,
  Subtitle,
  SuccessCircle,
  SuccessSubtitle,
  SuccessTitle,
} from './SupportModalContent.styles';

const SupportModalContent = ({ isSubmitted, message, setMessage }) => {
  const { t } = useTranslation();
  const { me } = useSessionContext();

  return (
    <Container>
      {isSubmitted ? (
        <>
          <SuccessCircle>
            <StyledTickIcon />
          </SuccessCircle>
          <SuccessTitle>{t('support.successTitle')}</SuccessTitle>
          <SuccessSubtitle>{t('support.successSubtitle', { email: me?.email })}</SuccessSubtitle>
        </>
      ) : (
        <>
          <Subtitle>{t('support.subtitle', { email: me?.email })}</Subtitle>
          <Textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
            animated={false}
            shadow={false}
            minRows={6}
            label={t('support.label')}
            placeholder={t('support.placeholder')}
          />
        </>
      )}
    </Container>
  );
};

export default SupportModalContent;
