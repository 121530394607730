import React from 'react';

const AutoCategoryIcon = (props: any) => {
  return (
    <svg height="11" width="12" fill="none" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.9751 2.55029L5.16834 4.73205L7.3501 5.92529L5.16834 7.11854L3.9751 9.30029L2.78186 7.11854L0.600098 5.92529L2.78186 4.73205L3.9751 2.55029Z"
        strokeLinejoin="round"
      />
      <path
        d="M9.71265 1.2002L10.3093 2.29107L11.4001 2.8877L10.3093 3.48432L9.71265 4.5752L9.11603 3.48432L8.02515 2.8877L9.11603 2.29107L9.71265 1.2002Z"
        strokeLinejoin="round"
      />
      <path
        d="M9.71265 6.9375L10.3093 8.02838L11.4001 8.625L10.3093 9.22162L9.71265 10.3125L9.11603 9.22162L8.02515 8.625L9.11603 8.02838L9.71265 6.9375Z"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AutoCategoryIcon;
