import { Loading } from '@nextui-org/react';
import React from 'react';

import FileUploader from '../FileUploader';
import { AvatarContainer, EditAvatarButton, EditIcon, LoadingWrapper, StyledAvatar } from './AvatarUploader.styles';

export interface AvatarUploaderProps {
  avatarSize?: string;
  imageUrl?: string;
  onUploadFinish: (fileUrl: string) => void;
  avatarInitials: string;
}

const AvatarUploader = ({ imageUrl, onUploadFinish, avatarSize = '10rem', avatarInitials }: AvatarUploaderProps) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [uploadingAvatar, setUploadingAvatar] = React.useState(false);

  const onAvatarClick = () => {
    fileInputRef?.current.click();
  };

  return (
    <>
      <FileUploader
        inputRef={fileInputRef}
        setLoading={setUploadingAvatar}
        onUploadFinish={onUploadFinish}
        accept="image/*"
      />
      <AvatarContainer avatarSize={avatarSize} onClick={onAvatarClick}>
        <StyledAvatar squared text={!uploadingAvatar && avatarInitials} src={imageUrl} />
        {uploadingAvatar && (
          <LoadingWrapper>
            <Loading size="lg" />
          </LoadingWrapper>
        )}
        <EditAvatarButton icon={<EditIcon />} onClick={onAvatarClick} />
      </AvatarContainer>
    </>
  );
};

export default AvatarUploader;
