import './index.css';
import './locale/index'; // Import i18n to be bundled

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { BrandProvider } from './brands/BrandContext';
import { AuthProvider } from './contexts/AuthContext';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // Removing SctrictMode because BottomSheet does not work with it: https://github.com/stipsan/react-spring-bottom-sheet/issues/210#issuecomment-1099602649
  // <React.StrictMode>
  <AuthProvider>
    <BrandProvider>
      <App />
    </BrandProvider>
  </AuthProvider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
