import styled from 'styled-components';

import { ThemeProp } from '../../../theme/Themes';
import { ShimmerProps, ShimmerText } from '../Shimmer.styles';

export const Container = styled.div<ShimmerProps>`
  width: ${(props: ShimmerProps) => props.width};
  height: ${(props: ShimmerProps) => props.height};
  display: flex;
  align-items: center;
  background: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  box-shadow: 0px 0.5rem 1.5rem rgba(0, 0, 0, 0.06);
  border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
`;

interface ShimmerCaptionProps {
  padding?: string;
}

export const ShimmerCaption = styled(ShimmerText).attrs(() => {
  return { height: '0.625rem' };
})<ShimmerCaptionProps>`
  padding: 0 ${(props: ShimmerCaptionProps) => props.padding || '1.5rem'};
`;
