import { Table } from '@nextui-org/react';
import { MultipleSelection, Selection } from '@react-types/shared';
import React, { ReactNode, useState } from 'react';

import {
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableHeader,
  StyledTableRow,
} from './TableComponent.styles';

export interface TableItem {
  key: string;
  label: string;
  selected?: boolean;
  icon?: ReactNode;
}

interface TableComponentProps extends MultipleSelection {
  items: TableItem[];
  ariaLabel?: string;
  multiselect?: boolean;
}

const TableComponent = ({
  items,
  ariaLabel,
  multiselect = true,
  defaultSelectedKeys,
  onSelectionChange,
  ...props
}: TableComponentProps) => {
  const [selectionLocal, setSelectionLocal] = useState(defaultSelectedKeys as Set<string>);
  const onLocalSelectionChange = (selection: Selection) => {
    if (!multiselect) {
      const selectionSet = selection as Set<string>;
      const singleSelection = new Set([...selectionSet].filter((key) => !selectionLocal?.has(key)));
      setSelectionLocal(singleSelection);
      onSelectionChange?.(singleSelection);
    } else {
      onSelectionChange?.(selection);
    }
  };

  return (
    <StyledTable
      aria-label={ariaLabel}
      selectionMode="multiple"
      defaultSelectedKeys={defaultSelectedKeys}
      selectedKeys={!multiselect ? selectionLocal : undefined}
      onSelectionChange={onLocalSelectionChange}
      {...props}
    >
      <StyledTableHeader>
        {/* This header is hidden but a child is still required */}
        <Table.Column hideHeader>{null}</Table.Column>
      </StyledTableHeader>
      <StyledTableBody>
        {items.map((item) => (
          <StyledTableRow key={item.key}>
            <StyledTableCell>{item.label}</StyledTableCell>
          </StyledTableRow>
        ))}
      </StyledTableBody>
    </StyledTable>
  );
};

export default React.memo(TableComponent);
