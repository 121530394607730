import React, { ChangeEvent, useRef, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import usePlacesAutocomplete, { getDetails, getGeocode, getLatLng } from 'use-places-autocomplete';

import Input from '~/components/Input';
import { getScrollParent } from '~/utils/helpers';

import { inMobile, useWindowDimensions } from '../../hooks/useDimensions';
import { BottomSheet } from '..';
import ModalHeader from '../Modal/ModalHeader';
import { Item, ItemContainer, ItemDescription, Items, ItemText, Separator } from './PlacesAutocomplete.styles';

export interface AutocompletePlace {
  address: string;
  city: string;
  country: string;
  coordinates?: {
    latitude: number;
    longitude: number;
  } | null;
  state: string;
  zip: string;
}

export interface PlacesAutocompleteProps {
  label: string;
  placeholder?: string;
  helpText?: string;
  onSelect?: (place: AutocompletePlace) => void;
  defaultValue?: string;
  error?: string;
  onChange?: (value: string) => void;
}

export enum PlaceAddressComponent {
  ZIP_CODE = 'postal_code',
  COUNTRY = 'country',
  STATE = 'administrative_area_level_1',
  CITY = 'locality',
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export function getAddressComponent(
  address_components: AddressComponent[],
  key: string,
  attribute: 'long_name' | 'short_name' = 'long_name',
) {
  let value = '';
  const addressComponents = address_components.filter((addressComponent) =>
    addressComponent.types.some((typesItem) => typesItem === key),
  );
  if (addressComponents !== null && addressComponents.length > 0) value = addressComponents[0][attribute];
  return `${value}`;
}

const DROPDOWN_OFFSET_TOP = 10;

const PlacesAutocomplete = (props: PlacesAutocompleteProps) => {
  const { label, onSelect, placeholder, helpText, defaultValue, onChange, error } = props;
  const { width } = useWindowDimensions();
  const searchInputRef = useRef<HTMLDivElement>(null);
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['geocode'],
      componentRestrictions: { country: 'USA' },
    },
    defaultValue,
    debounce: 300,
  });

  const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);
  const [inputValueMobile, setInputValueMobile] = useState(defaultValue);
  const isMobile = inMobile(width);
  const shouldShowSuggestionsList = status === 'OK';

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange?.(e.target.value);
  };

  const handleSelect = async (place: any) => {
    const {
      place_id,
      description,
      structured_formatting: { main_text },
    } = place;

    if (typeof description === 'string') {
      setValue(description, false);
      setInputValueMobile(description);
    }
    clearSuggestions();

    // Get latitude and longitude via utility functions
    const results = await getGeocode({ address: description });
    const { lat, lng } = getLatLng(results?.[0]);

    const detailsResult = await getDetails({ placeId: place_id });
    const addressComponents = detailsResult.address_components as AddressComponent[];
    const country = getAddressComponent(addressComponents, PlaceAddressComponent.COUNTRY, 'short_name');
    const city = getAddressComponent(addressComponents, PlaceAddressComponent.CITY);
    const zip = getAddressComponent(addressComponents, PlaceAddressComponent.ZIP_CODE);
    const state = getAddressComponent(addressComponents, PlaceAddressComponent.STATE, 'short_name');

    /* eslint-disable prettier/prettier */
    onSelect({
      address: main_text,
      city,
      country,
      coordinates: [lat, lng].every((coordinate) => typeof coordinate === 'number')
        ? {
          latitude: lat,
          longitude: lng,
        }
        : null,
      state,
      zip,
    });
    /* eslint-enable prettier/prettier */

    setBottomSheetOpen(false);
  };

  const onInputClick = () => {
    if (!isMobile) return;

    setBottomSheetOpen(true);
  };

  const SuggestionsList = () => {
    const getTopPosition = () => {
      const inputRect = searchInputRef.current.getBoundingClientRect();
      return inputRect.top + inputRect.height + DROPDOWN_OFFSET_TOP;
    };

    const [dropdownTop, setDropdownTop] = useState(getTopPosition());

    getScrollParent(searchInputRef.current)?.addEventListener('scroll', () => {
      if (!shouldShowSuggestionsList) return;
      setDropdownTop(getTopPosition());
    });

    window.addEventListener('scroll', () => {
      if (!shouldShowSuggestionsList) return;
      setDropdownTop(getTopPosition());
    });

    return (
      <Items $top={dropdownTop}>
        {data.map((suggestion, index) => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
          } = suggestion;

          return (
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            <Item key={place_id} onClick={() => handleSelect(suggestion)}>
              <ItemContainer>
                <ItemText>{main_text}</ItemText>
                {secondary_text && <ItemDescription>{secondary_text}</ItemDescription>}
              </ItemContainer>
              {index !== data.length - 1 && <Separator />}
            </Item>
          );
        })}
      </Items>
    );
  };

  return (
    <>
      <div ref={!isMobile ? ref : undefined} onClick={onInputClick}>
        <Input
          clearable
          error={error}
          helpText={helpText}
          label={label}
          placeholder={placeholder}
          value={isMobile ? inputValueMobile : value}
          readOnly={isMobile}
          onChange={handleInput}
          ref={searchInputRef}
        />
        {!isMobile && shouldShowSuggestionsList && <SuggestionsList />}
      </div>
      <BottomSheet
        open={isBottomSheetOpen}
        onDismiss={() => setBottomSheetOpen(false)}
        snapPoints={({ maxHeight }) => [maxHeight / 0.6, maxHeight / 0.6]}
      >
        <ModalHeader title={label} onClose={() => setBottomSheetOpen(false)} />
        <Input autoFocus clearable error={error} placeholder={placeholder} value={value} onChange={handleInput} />
        {shouldShowSuggestionsList && <SuggestionsList />}
      </BottomSheet>
    </>
  );
};

export default PlacesAutocomplete;
