import React from 'react';

const CopyIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.3996 7.60001H9.19961C8.31595 7.60001 7.59961 8.31635 7.59961 9.20001V16.4C7.59961 17.2837 8.31595 18 9.19961 18H16.3996C17.2833 18 17.9996 17.2837 17.9996 16.4V9.20001C17.9996 8.31635 17.2833 7.60001 16.3996 7.60001Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.4 12.3999H3.6C3.17565 12.3999 2.76869 12.2313 2.46863 11.9313C2.16857 11.6312 2 11.2243 2 10.7999V3.59991C2 3.17556 2.16857 2.7686 2.46863 2.46854C2.76869 2.16848 3.17565 1.99991 3.6 1.99991H10.8C11.2243 1.99991 11.6313 2.16848 11.9314 2.46854C12.2314 2.7686 12.4 3.17556 12.4 3.59991V4.39991"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CopyIcon;
