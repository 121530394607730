import 'react-circular-progressbar/dist/styles.css';

import React, { forwardRef, ReactNode, useImperativeHandle, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ThrashIcon } from '../../assets';
import { FailedIcon, FileCheckIcon, FileChooseIcon, FileTypeFailedIcon } from '../../assets/FileChooser';
import { ResendIcon } from '../../assets/members';
import { CircularProgress } from '../../components';
import { uploadFile, UploadFileConfig, UploadProgress } from '../../utils/Amplify';
import { isTouchDevice } from '../../utils/helpers';
import {
  ActionContainer,
  ActionLink,
  Card,
  ClickableLink,
  Container,
  Filename,
  FilenameContainer,
  IconContainer,
  Subtitle,
  Title,
  TitleContainer,
} from './FileChooser.styles';

interface FileChooserProps {
  accept?: string;
  iconFile?: ReactNode;
  file?: File;
  onFileSelect?: (file: File) => void;
  fileUrl: string | undefined;
  disableUpload?: boolean;
  propsUpload?: {
    config?: UploadFileConfig;
    onUploadStart?: () => void;
    onUploadFinish?: (url: string) => void;
    onUploadProgress?: (percentage: number) => void;
  };
}

const FileChooser = forwardRef(
  ({ accept, iconFile, file, onFileSelect, disableUpload, propsUpload }: FileChooserProps, ref) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>();
    const [isDragActive, setDragActive] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File>(file);
    const uploadOperationRef = useRef(undefined);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [isUploadFailed, setUploadFailed] = useState(false);
    const [completedPercentage, setCompletedPercentage] = useState(0);

    const progressCallback = (progress: UploadProgress) => {
      const percentage = Math.trunc((progress.loaded * 100) / progress.total);
      setCompletedPercentage(percentage);
      propsUpload?.onUploadProgress?.(percentage);
    };

    const handleDrag = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      if (selectedFile) return;

      if (accept) {
        const exts = accept.split(',');
        const extension: string = event.dataTransfer.items[0]?.type?.split('/').pop();
        if (extension && !exts.includes('.' + extension)) return;
      }

      if (event.type === 'dragenter' || event.type === 'dragover') {
        setDragActive(true);
      } else if (event.type === 'dragleave') {
        setDragActive(false);
      }
    };

    const onUploadFinish = (url: string) => {
      propsUpload?.onUploadFinish(url);
    };

    const selectFile = (file?: File) => {
      setSelectedFile(file);
      onFileSelect?.(file);

      if (!disableUpload && file) {
        void uploadSelectedFile(file);
      }
    };

    const uploadSelectedFile = async (file: File) => {
      setUploadingFile?.(true);
      setUploadFailed(false);
      propsUpload?.onUploadStart?.();

      try {
        const url = await uploadFile(file, progressCallback, uploadOperationRef, propsUpload?.config, true);
        if (url) {
          onUploadFinish(url);
        }
      } catch (error: any) {
        onUploadFinish(undefined);
        setUploadFailed(true);
      } finally {
        setUploadingFile?.(false);
        setCompletedPercentage(0);
      }
    };

    const handleDrop = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      if (!isDragActive) return;
      setDragActive(false);
      selectFile(event.dataTransfer.files[0] as File);
    };

    const onBrowse = () => {
      inputRef.current.click();
    };

    const onFileChange = (event: any) => {
      event.preventDefault();
      selectFile(event.target.files[0] as File);
    };

    const onFileDelete = () => {
      selectFile(undefined);
      inputRef.current.value = '';
    };

    useImperativeHandle(ref, () => ({
      retryUpload: onRetryUpload,
    }));

    const onRetryUpload = () => {
      void uploadSelectedFile(selectedFile);
    };

    return (
      <Container>
        {selectedFile && (
          <TitleContainer>
            <Title>{t('components.fileChooser.uploadedTitle')}</Title>
            {!uploadingFile && (
              <ActionContainer>
                {isUploadFailed ? <ResendIcon /> : <ThrashIcon />}
                <ActionLink onClick={isUploadFailed ? onRetryUpload : onFileDelete}>
                  {t(`components.fileChooser.${isUploadFailed ? 'retry' : 'deleteFile'}`)}
                </ActionLink>
              </ActionContainer>
            )}
          </TitleContainer>
        )}
        <Card
          $isDragging={isDragActive}
          $isFileSelected={!!selectedFile}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          {selectedFile ? (
            <FilenameContainer>
              <Filename>
                {iconFile && (
                  <IconContainer $failed={isUploadFailed}>
                    {isUploadFailed ? <FileTypeFailedIcon /> : iconFile}
                  </IconContainer>
                )}
                <Subtitle $isFileSelected>{selectedFile?.name}</Subtitle>
              </Filename>
              {uploadingFile ? (
                <CircularProgress size={28} value={completedPercentage} />
              ) : isUploadFailed ? (
                <FailedIcon />
              ) : (
                <FileCheckIcon />
              )}
            </FilenameContainer>
          ) : (
            <>
              <FileChooseIcon />
              <Subtitle>
                <Trans
                  i18nKey={`components.fileChooser.${isTouchDevice() ? 'infoTextMobile' : 'infoText'}`}
                  components={{ span: <ClickableLink onClick={onBrowse} /> }}
                />
              </Subtitle>
            </>
          )}
          <input type="file" onChange={onFileChange} hidden ref={inputRef} accept={accept} />
        </Card>
      </Container>
    );
  },
);

export default FileChooser;
