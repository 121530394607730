import { Avatar, AvatarProps as NextAvatarProps } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export interface AvatarProps extends NextAvatarProps {
  circular?: boolean;
  borderRadius?: string;
  border?: string;
  fontSize?: string;
  fontColor?: string;
  backgroundColor?: string;
  complete?: boolean;
}

const defaultBorderRadius = '0.375rem';

const borderRadiusProps = (props: AvatarProps) => {
  return !props.circular && `border-radius: ${props.borderRadius || defaultBorderRadius};`;
};

export const StyledAvatar = styled(Avatar)<AvatarProps>`
  ${(props: AvatarProps) => borderRadiusProps(props)}
  .nextui-avatar-bg,
  .nextui-avatar-img {
    opacity: ${(props: AvatarProps) => (props.complete ? 1 : 0)};
    background-color: ${(props: ThemeProp & AvatarProps) => props.backgroundColor || props.theme.colors.primaryLight};
    ${(props: AvatarProps) => borderRadiusProps(props)}
    border: ${(props: AvatarProps) => props.border || 'none'};
  }
  .nextui-avatar-text {
    color: ${(props: ThemeProp & AvatarProps) => props.fontColor || props.theme.colors.primary.c500};
    font-size: ${(props: AvatarProps & ThemeProp) => props.fontSize || props.theme.fontSize.ml};
  }
`;
