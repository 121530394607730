import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const ToastContainer = styled.div`
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.m};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.m};
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  padding: 0.625rem 1rem;
`;

export const HideButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
