import React from 'react';

const GeographicalIcon = (props: any) => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M11.5008 1.50781V6.49927" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.49887 2.98438V14.8339" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M16.4993 7.33288V3.6772C16.4993 3.31805 16.2693 2.99973 15.9293 2.8864L12.0262 1.58562C11.6829 1.47146 11.3129 1.47146 10.9696 1.58562L7.02641 2.89973C6.68392 3.0139 6.31477 3.0139 5.97229 2.89973L2.59661 1.77478C2.05747 1.59479 1.5 1.99644 1.5 2.56558V12.0144C1.5 12.3302 1.67832 12.6193 1.96081 12.7602L5.7548 14.6567C6.22394 14.8917 6.77642 14.8909 7.24556 14.6567L8.99965 13.7793"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9932 16.2646C14.6715 16.5771 14.1624 16.5771 13.8407 16.2646C12.9899 15.4388 11.5 13.7689 11.5 12.2056C11.5 10.5948 12.8058 9.28906 14.4165 9.28906C16.0273 9.28906 17.3331 10.5948 17.3331 12.2056C17.3331 13.7689 15.8431 15.4388 14.9932 16.2646Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.416 11.9032C14.296 11.9032 14.1993 12.0007 14.2001 12.12C14.2001 12.24 14.2976 12.3367 14.4168 12.3367C14.5361 12.3367 14.6336 12.2392 14.6336 12.12C14.6336 11.9999 14.5369 11.9032 14.416 11.9032"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GeographicalIcon;
