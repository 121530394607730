import React from 'react';

import { ColorVariants } from '~/theme/Themes';

interface MagnifierIconProps {
  colors?: ColorVariants;
}

const MagnifierIcon = (props: MagnifierIconProps) => {
  return (
    <svg height="94" width="97" fill="none" viewBox="0 0 97 94" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        height="49"
        id="mask0_4248_1369"
        style={{ maskType: 'alpha' }}
        width="49"
        x="8"
        y="7"
        maskUnits="userSpaceOnUse"
      >
        <path
          d="M49.1226 48.6058C39.8132 57.9154 24.7264 57.9137 15.4152 48.6058C6.1161 39.3083 6.1161 24.2224 15.4273 14.9145C24.7264 5.61703 39.8132 5.61703 49.1123 14.9145C58.4217 24.2224 58.4217 39.3083 49.1226 48.6058Z"
          fill="white"
          fillOpacity="0.1"
          fillRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_4248_1369)">
        <rect
          height="48.6352"
          width="6.0991"
          fill="url(#paint0_linear_4248_1369)"
          transform="matrix(0.707161 0.707053 -0.707161 0.707053 37.1807 2.17871)"
        />
        <rect
          height="55.6185"
          width="6.0991"
          fill="url(#paint1_linear_4248_1369)"
          transform="rotate(45 47.4561 7.15137)"
          x="47.4561"
          y="7.15137"
        />
      </g>
      <path
        d="M49.1226 48.6061C39.8132 57.9159 24.7264 57.9142 15.4152 48.6061C6.1161 39.3084 6.1161 24.2222 15.4273 14.9142C24.7264 5.6165 39.8132 5.6165 49.1123 14.9142C58.4217 24.2222 58.4217 39.3084 49.1226 48.6061ZM54.7282 9.30379C42.3213 -3.10126 22.2162 -3.10126 9.80926 9.30379C-2.59589 21.7071 -2.60668 41.8217 9.79847 54.2267C21.0903 65.5132 38.7888 66.5311 51.2371 57.2641C52.453 56.3578 53.6239 55.3417 54.7408 54.2267C55.856 53.1099 56.8722 51.9392 57.7769 50.7235C67.0453 38.2753 66.0182 20.5921 54.7282 9.30379Z"
        fill="url(#paint2_linear_4248_1369)"
        fillRule="evenodd"
      />
      <path
        d="M52.8516 55.9741C53.4966 55.4228 54.1268 54.8399 54.7406 54.2271C55.8558 53.1103 56.872 51.9396 57.7767 50.7239C57.9275 50.5214 58.0755 50.3175 58.2209 50.1123L67.173 59.0631L61.557 64.6782L52.8516 55.9741Z"
        fill={props.colors?.c200}
      />
      <path
        d="M59.8965 71.44C58.3457 69.8894 58.3457 67.3755 59.8965 65.8249L68.3205 57.4022C69.8713 55.8516 72.3857 55.8516 73.9365 57.4022L93.5925 77.0552C97.4695 80.9316 97.4695 87.2165 93.5925 91.093C89.7154 94.9694 83.4295 94.9694 79.5525 91.093L59.8965 71.44Z"
        fill="url(#paint3_radial_4248_1369)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4248_1369"
          gradientUnits="userSpaceOnUse"
          x1="3.04955"
          x2="3.04955"
          y1="0"
          y2="48.6352"
        >
          <stop stopColor={props.colors?.c100} />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4248_1369"
          gradientUnits="userSpaceOnUse"
          x1="50.5056"
          x2="50.5056"
          y1="7.15137"
          y2="62.7699"
        >
          <stop stopColor={props.colors?.c100} />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4248_1369"
          gradientUnits="userSpaceOnUse"
          x1="77.4402"
          x2="-2.47141"
          y1="63.637"
          y2="13.4985"
        >
          <stop stopColor={props.colors?.c100} />
          <stop offset="1" stopColor={props.colors?.c200} />
        </linearGradient>
        <radialGradient
          id="paint3_radial_4248_1369"
          cx="0"
          cy="0"
          gradientTransform="translate(120.04 104.805) rotate(-146.936) scale(71.5991 39.0618)"
          gradientUnits="userSpaceOnUse"
          r="1"
        >
          <stop stopColor={props.colors?.c500} />
          <stop offset="1" stopColor={props.colors?.c400} />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default MagnifierIcon;
