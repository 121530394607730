import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.semibold};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xxs};
`;

export default Badge;
