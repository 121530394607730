import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

interface ContainerProps {
  $narrow?: boolean;
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props: ContainerProps) => (props.$narrow ? '1rem' : '4rem')};
`;

export const Title = styled.span`
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.semibold};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.ml};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xl};
  text-align: center;
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
`;

interface SubtitleProps {
  $maxWidth?: string;
}

const defaultSubtitleMaxWidth = '16.25rem';

export const SubtitleContainer = styled.div<SubtitleProps>`
  max-width: ${(props: SubtitleProps) => props.$maxWidth || defaultSubtitleMaxWidth};
  text-align: center;
`;

export const Subtitle = styled.span<SubtitleProps>`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.s};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
  text-align: center;
  max-width: ${(props: SubtitleProps) => props.$maxWidth || defaultSubtitleMaxWidth};
`;

export const ClickableLink = styled(Subtitle)`
  cursor: pointer;
  color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
`;
