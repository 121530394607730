import styled from 'styled-components';

import Input from '~/components/Input';

import { CloseIcon, SearchIcon } from '../../../assets';
import { ThemeProp } from '../../../theme/Themes';
import { IconButton } from '../../';

export const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    margin-bottom: 1.5rem;
  }
`;
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0.75rem 0rem 0.75rem;

  .nextui-input-main-container {
    margin-bottom: 0;
  }
`;

export const Title = styled.span`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.m};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.ml};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.semibold};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  align-self: flex-end;
  text-align: left;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    font-size: ${(props: ThemeProp) => props.theme.fontSize.l};
    line-height: ${(props: ThemeProp) => props.theme.lineHeight.l};
  }
`;

export const CloseButton = styled(IconButton)`
  width: 1rem;
  height: 1rem;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
`;

export const StyledInput = styled(Input)`
  margin-bottom: 0;
  height: 2.25rem !important;
  input {
    margin-left: 0 !important;
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
`;
