import 'react-multi-email/dist/style.css';

import React, { useContext } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import { ThemeContext } from 'styled-components';

import { Container, ErrorText, Label } from './MultiEmailInput.styles';

const MultiEmailInput = ({ emails, onChange, onRemove, error, label, ...rest }) => {
  const theme = useContext(ThemeContext);
  return (
    <Container>
      <Label>{rest.required ? `${label as string} *` : label}</Label>
      <ReactMultiEmail
        emails={emails}
        onChange={onChange}
        className="react-multi-email"
        style={{
          border: `1px solid ${error ? (theme.colors.errorRed as string) : (theme.colors.c200 as string)}`,
          width: '100%',
          borderRadius: '6px',
          fontSize: theme.fontSize.s,
        }}
        getLabel={(email, index) => {
          return (
            <div data-tag key={index}>
              <div data-tag-item>{email}</div>
              <span data-tag-handle onClick={() => onRemove(emails.filter((em) => em !== email))}>
                ×
              </span>
            </div>
          );
        }}
        {...rest}
      />
      <ErrorText>{error}</ErrorText>
    </Container>
  );
};

export default MultiEmailInput;
