export const commonTheme = {
  fontSize: {
    xxs: '0.625rem',
    xs: '0.75rem',
    s: '0.875rem',
    m: '0.9375rem',
    ml: '1rem',
    mlg: '1.125rem',
    l: '1.25rem',
    lxl: '1.375rem',
    xl: '1.5rem',
    '2xl': '1.875rem',
    '3xl': '2.5rem',
  },
  lineHeight: {
    xxs: '0.875rem',
    xs: '1.25rem',
    s: '1.375rem',
    m: '1.5rem',
    ml: '1.25rem',
    mlg: '1.5rem',
    l: '1.75rem',
    lxl: '2rem',
    xl: '2rem',
    '2xl': '2.5rem',
    '3xl': '3.75rem',
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  breakpoints: {
    smallMobile: '375px',
    mobile: '480px',
    xlMobile: '598px',
    tablet: '768px',
    desktop: '1054px',
    large: '1200px',
    'extra-large': '1400px',
  },
};
