import styled from 'styled-components';

import { TickIcon } from '~/assets';
import { ThemeProp } from '~/theme/Themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .nextui-input-container--textarea {
    border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
    border: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c200};
  }

  .nextui-input-wrapper {
    background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  }

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    margin-bottom: 2rem;
  }
`;

export const Subtitle = styled.span`
  margin-bottom: 2rem;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
`;

export const SuccessTitle = styled.span`
  margin-bottom: 0.5rem;
  text-align: center;
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xl};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
`;

export const SuccessSubtitle = styled.span`
  margin-bottom: 1rem;
  text-align: center;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
`;

export const StyledTickIcon = styled(TickIcon)`
  stroke: ${(props: ThemeProp) => props.theme.colors.primary.c500};
`;

export const SuccessCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  margin: 0 auto;
  border-radius: 50%;
  background-color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
  margin-bottom: 1.5rem;

  svg {
    flex-shrink: 0;
    stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
    width: 1.75rem;
    height: 1.75rem;
  }
`;
