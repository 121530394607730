import { Popover, PopoverPlacement } from '@nextui-org/react';
import React, { Key, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '../../hooks/useDebounce';
import { inMobile, useWindowDimensions } from '../../hooks/useDimensions';
import { ColumnExtendedType } from '../../views/audiences/PeopleList/PeopleList';
import {
  StyledBottomSheet,
  StyledButton,
  StyledFooter,
  StyledPopoverContent,
  StyledSecondaryButton,
} from './ColumnsTable.styles';
import Header from './components/Header';
import TableComponent from './components/TableComponent';

export interface TableItemType {
  column?: ColumnExtendedType;
  key: string;
  label: string;
  selected?: boolean;
  show?: boolean;
  hidden?: boolean;
}

export interface ColumnsTableProps {
  open?: boolean;
  title?: string;
  cancelButtonTitle?: string;
  submitButtonTitle?: string;
  inputPlaceholder?: string;
  width?: string;
  loading?: boolean;
  items: TableItemType[];
  ariaLabel?: string;
  popoverTriggerComponent?: React.ReactNode;
  showTableOnly?: boolean;
  disallowEmptySelection?: boolean;
  onClose?: () => void;
  onSelectionChange?: (selection: ColumnExtendedType[]) => void;
  onOrderChange?: (columnsOrdered: ColumnExtendedType[]) => void;
  disabledDrag?: boolean;
  showPopoverEvenMobile?: boolean;
  hideFooter?: boolean;
  placement?: PopoverPlacement;
}

const ColumnsTable = ({
  open,
  title,
  cancelButtonTitle,
  submitButtonTitle,
  inputPlaceholder,
  loading,
  items,
  popoverTriggerComponent,
  onClose,
  onSelectionChange,
  onOrderChange,
  disabledDrag = false,
  showPopoverEvenMobile = false,
  hideFooter = false,
  width,
  placement,
}: ColumnsTableProps) => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowDimensions();

  const getDefaultSelectedIndexes = (items: TableItemType[]) => {
    return items.filter((item) => item.column.selected).map((item) => item.key);
  };

  const getDefaultUnselectedIndexes = (items: TableItemType[]) => {
    return items.filter((item) => !item.column.selected).map((item) => item.key);
  };

  const allItemsSelected = getDefaultSelectedIndexes(items)?.length === items.length;

  const [isAllSelected, setAllSelected] = React.useState(allItemsSelected);
  const popoverContentRef = React.useRef<HTMLDivElement>(null);

  // selected ids if "Select all" is unchecked and unselected ids if "Select all" is checked
  const [reversedIds, setReversedIds] = React.useState<Set<string>>(
    allItemsSelected ? new Set(getDefaultUnselectedIndexes(items)) : new Set(getDefaultSelectedIndexes(items)),
  );
  const [search, setSearch] = React.useState('');
  const [isDragging, setIsDragging] = React.useState<boolean>(false);

  // Fire the update after 0.5s after the user finished typing
  const searchDebounced = useDebounce(search, 500) as string;

  const columnsFixed = useMemo(() => items.filter((item) => item.hidden).map((item) => item.key), [items]);

  const isItemSelected = useCallback(
    (id: string) => {
      return isAllSelected ? !reversedIds.has(id) : reversedIds.has(id);
    },
    [isAllSelected, reversedIds],
  );

  const onItemSelected = (id: string, selected: boolean) => {
    const reversedIdsTemp = new Set(reversedIds);
    if (isAllSelected) {
      if (selected) {
        reversedIdsTemp.delete(id);
      } else {
        reversedIdsTemp.add(id);
      }
      setReversedIds(reversedIdsTemp);
    } else {
      if (selected) {
        // If now all items will be selected
        if (reversedIdsTemp.size + 1 === items.length) {
          setAllSelected(true);
          setReversedIds(new Set());
        } else {
          reversedIdsTemp.add(id);
          setReversedIds(reversedIdsTemp);
        }
      } else {
        reversedIdsTemp.delete(id);
        setReversedIds(reversedIdsTemp);
      }
    }
  };

  const resetSelection = () => {
    setReversedIds(
      allItemsSelected ? new Set(getDefaultUnselectedIndexes(items)) : new Set(getDefaultSelectedIndexes(items)),
    );
    setAllSelected(allItemsSelected);
  };

  const handleSelectAll = (selected: boolean) => {
    setAllSelected(selected);
    setReversedIds(!selected ? new Set(columnsFixed) : new Set());
  };

  const onSubmit = () => {
    const selectedItems = Array.from((reversedIds as 'all' | Set<Key>) || []);

    const selectedColumns = items.map((item) => {
      return {
        ...item.column,
        selected: isAllSelected ? !selectedItems.includes(item.key) : selectedItems.includes(item.key),
      };
    });

    onSelectionChange?.(selectedColumns);
    onClose();
  };

  const onCancel = () => {
    onClose();

    // add a delay to avoid selection changes after closing the popover
    setTimeout(() => {
      resetSelection();
    }, 300);
  };

  const CancelButton = () => (
    <StyledSecondaryButton onClick={onCancel}>{cancelButtonTitle || t('common.cancel')}</StyledSecondaryButton>
  );

  const SubmitButton = ({ disabled }) => (
    <StyledButton disabled={disabled} loading={loading} onClick={onSubmit}>
      {submitButtonTitle || t('common.ok')}
    </StyledButton>
  );

  // don't allow to leave the selection empty taking into account fixed columns
  const isDisabledSubmitButton = useMemo(() => {
    return (
      (reversedIds.size === columnsFixed.length && !isAllSelected) ||
      (reversedIds.size + columnsFixed.length === items.length && isAllSelected)
    );
  }, [reversedIds, isAllSelected, items, columnsFixed]);

  const Footer = () => (
    <StyledFooter>
      <CancelButton />
      <SubmitButton disabled={isDisabledSubmitButton} />
    </StyledFooter>
  );

  useEffect(() => {
    if (items) {
      const shouldSelectAll = getDefaultSelectedIndexes(items)?.length === items.length;
      setAllSelected(shouldSelectAll);
      setReversedIds(
        shouldSelectAll ? new Set(getDefaultUnselectedIndexes(items)) : new Set(getDefaultSelectedIndexes(items)),
      );
    }
  }, [items]);

  const itemsToShow = useMemo(() => {
    const shouldShowItem = (item: TableItemType) => {
      if (item.hidden) return false;
      return searchDebounced ? item.label.toLowerCase().includes(searchDebounced.toLowerCase()) : true;
    };

    return items.map((item) => {
      return {
        ...item,
        show: shouldShowItem(item),
      };
    });
  }, [items, searchDebounced]);

  const table = (
    <>
      <Header
        onClose={onCancel}
        inputPlaceholder={inputPlaceholder}
        setSearch={setSearch}
        search={search}
        title={title}
        hideHeaderTitle={showPopoverEvenMobile}
      />
      <TableComponent
        mobilePadding={!showPopoverEvenMobile}
        itemsToShow={itemsToShow}
        onOrderChange={onOrderChange}
        onItemSelected={onItemSelected}
        handleSelectAll={handleSelectAll}
        isItemSelected={isItemSelected}
        isDragging={isDragging}
        popoverContentRef={popoverContentRef}
        setIsDragging={setIsDragging}
        isAllSelected={isAllSelected}
        disabledDrag={disabledDrag || !!searchDebounced}
        isSelectAllIndeterminate={isAllSelected && reversedIds.size > 0}
      />
      {!hideFooter && <Footer />}
    </>
  );

  return (
    <>
      {!inMobile(windowWidth) || showPopoverEvenMobile ? (
        <Popover placement={placement} isOpen={open} onClose={onCancel}>
          <Popover.Trigger>{popoverTriggerComponent}</Popover.Trigger>
          <StyledPopoverContent $width={width} ref={popoverContentRef}>
            {table}
          </StyledPopoverContent>
        </Popover>
      ) : (
        <>
          {popoverTriggerComponent}
          <StyledBottomSheet scrollLocking={false} open={open} onDismiss={onClose}>
            {table}
          </StyledBottomSheet>
        </>
      )}
    </>
  );
};

export default ColumnsTable;
