import React, { ReactNode, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useTabContext } from '../../contexts/TabsContext';
import { ScrollHint, StyledDivider, StyledShimmer, Tab, TabsContainer, TabsInnerContainer, Title } from './Tabs.styles';

export interface TabType {
  title: string;
  icon?: ReactNode;
  disabled?: boolean;
  path?: string;
  value?: any;
  onClick: () => void;
}

interface TabsProps {
  tabs: TabType[];
  loading?: boolean;
  shimmersCount?: number;
  hideBottomLine?: boolean;
  hideScrollHint?: boolean;
  tabPaddingBottom?: string;
  isActiveTab?: (tab: TabType) => boolean;
}

const Tabs = ({
  tabs,
  loading,
  shimmersCount = tabs?.length || 5,
  hideBottomLine,
  hideScrollHint,
  tabPaddingBottom,
  isActiveTab,
  ...rest
}: TabsProps) => {
  const { scrollLeft, handleScrollLeft } = useTabContext();
  const location = useLocation();

  const tabsContainerRef = React.useRef<HTMLDivElement>(null);

  const isCurrentTab = (tab: TabType) => (tab.path ? location.pathname === tab.path : isActiveTab?.(tab));

  const getStatus = (tab: TabType) => {
    return isCurrentTab(tab) ? 'active' : 'inactive';
  };

  useLayoutEffect(() => {
    if (tabsContainerRef.current) {
      tabsContainerRef.current.scrollLeft = scrollLeft || 0;
    }
  }, [scrollLeft]);

  const handleTabClick = (tab: TabType) => {
    if (!isCurrentTab(tab)) {
      handleScrollLeft(tabsContainerRef.current?.scrollLeft || 0);
      tab.onClick();
    }
  };

  return (
    <TabsContainer {...rest}>
      <TabsInnerContainer ref={tabsContainerRef}>
        {loading ? (
          <>
            {[...Array(shimmersCount)].map((_, index) => (
              <StyledShimmer
                key={index}
                $paddingBottom={tabPaddingBottom}
                $marginRight={index === shimmersCount - 1 ? '0' : undefined}
              />
            ))}
          </>
        ) : (
          tabs?.map((tab: TabType, index) => (
            <Tab
              key={tab.title}
              status={getStatus(tab)}
              onClick={() => !tab.disabled && handleTabClick(tab)}
              $disabled={tab.disabled}
              $paddingBottom={tabPaddingBottom}
              $marginRight={index === tabs.length - 1 ? '0' : undefined}
            >
              {tab.icon && tab.icon} <Title>{tab.title}</Title>
            </Tab>
          ))
        )}
      </TabsInnerContainer>
      {!hideBottomLine && <StyledDivider />}
      {!hideScrollHint && <ScrollHint />}
    </TabsContainer>
  );
};

export default Tabs;
