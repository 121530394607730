import styled from 'styled-components';

import { ItemContainer } from '~/overwrites/views/layouts/SideBar/SideBar.styles';

export const StyledItemContainer = styled(ItemContainer)`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fontSize.xs};
    stroke: ${(props) => props.theme.colors.primary.c500};
    color: ${(props) => props.theme.colors.primary.c500};
    gap: 0.5rem;
  }
`;
