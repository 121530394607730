import React from 'react';

const ChartBarIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.91115 7.64242L2.91115 3.70659C2.91115 3.27159 3.26365 2.91992 3.69781 2.91992L6.84615 2.91992C7.28115 2.91992 7.63281 3.27242 7.63281 3.70659L7.63281 7.64159"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2915 12.3633L2.91146 12.3633L2.91146 16.2983C2.91146 16.7333 3.26396 17.0849 3.69812 17.0849L16.2915 17.0849C16.7265 17.0849 17.0781 16.7324 17.0781 16.2983L17.0781 13.1499C17.0781 12.7158 16.7256 12.3633 16.2915 12.3633V12.3633Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.90739 12.3631L2.90739 7.64062L11.5649 7.64062C11.9999 7.64062 12.3516 7.99312 12.3516 8.42729L12.3516 12.3623"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChartBarIcon;
