import React from 'react';

const CustomerOrgIcon = (props: any) => {
  return (
    <svg height="20" width="20" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8 17H3V3H15V7.5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M7 17V12H9.5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M6.5 6.5H11M6.5 9H10" strokeLinecap="round" strokeWidth="1.5" />
      <path
        d="M10.5801 17.107V16.7868C10.5824 15.8743 11.3213 15.1351 12.2334 15.1328H14.8476C15.7597 15.1351 16.4986 15.8743 16.5009 16.7868V17.107V17.107C16.5009 17.3252 16.3241 17.5021 16.1059 17.5021H10.9742C10.7564 17.5017 10.5801 17.3249 10.5801 17.107V17.107Z"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M13.5343 13.1583C12.6627 13.1567 11.9572 12.4493 11.958 11.5777C11.9588 10.7061 12.6656 10 13.5372 10C14.4088 10 15.1155 10.7061 15.1163 11.5777C15.1171 12.4493 14.4117 13.1567 13.5401 13.1583H13.5343Z"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default CustomerOrgIcon;
