import React, { useContext } from 'react';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { ThemeContext } from 'styled-components';

import { inTablet, useWindowDimensions } from '../../hooks/useDimensions';
import TourTooltip from '../TourTooltip';

interface JoyRideTourProps {
  steps: Step[];
  handleJoyrideCallback: (data: CallBackProps) => void;
  showJourney: boolean;
  stepIndex?: number;
  disableScrollParentFix?: boolean;
}

const JoyRideTour = ({
  steps,
  handleJoyrideCallback,
  showJourney,
  stepIndex,
  disableScrollParentFix = false,
}: JoyRideTourProps) => {
  const { width } = useWindowDimensions();
  const theme = useContext(ThemeContext);

  return (
    <Joyride
      steps={steps}
      disableScrollParentFix={disableScrollParentFix}
      stepIndex={stepIndex}
      callback={handleJoyrideCallback}
      continuous
      run={showJourney}
      tooltipComponent={(props) => <TourTooltip stepsAmount={steps.length} {...props} />}
      showProgress
      disableScrolling={inTablet(width)}
      showSkipButton
      styles={{
        options: {
          arrowColor: theme.colors.primary.c500,
          zIndex: 99999,
        },
      }}
    />
  );
};

export default JoyRideTour;
