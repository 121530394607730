import React from 'react';

const ErrorCustomerList = (props: any) => {
  return (
    <svg width="191" height="177" viewBox="0 0 191 177" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle opacity="0.04" cx="70" cy="70" r="70" fill="#ED0A34" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 28C48 26.8954 48.8954 26 50 26H87.5569C88.0638 26 88.5518 26.1925 88.9222 26.5385L103.365 40.0315C103.77 40.4097 104 40.9389 104 41.4929V98C104 99.1046 103.105 100 102 100H50C48.8954 100 48 99.1046 48 98V28Z"
        fill="url(#paint0_linear_8038_211570)"
      />
      <g filter="url(#filter0_d_8038_211570)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40 34C40 32.8954 40.8954 32 42 32H81.0027C81.5067 32 81.9921 32.1903 82.3618 32.5328L97.3592 46.426C97.7677 46.8045 98 47.3362 98 47.8932V106C98 107.105 97.1046 108 96 108H42C40.8954 108 40 107.105 40 106V34Z"
          fill="url(#paint1_linear_8038_211570)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53 51C51.3431 51 50 52.3431 50 54C50 55.6569 51.3431 57 53 57H85C86.6569 57 88 55.6569 88 54C88 52.3431 86.6569 51 85 51H53ZM53 62C51.3431 62 50 63.3431 50 65C50 66.6569 51.3431 68 53 68H85C86.6569 68 88 66.6569 88 65C88 63.3431 86.6569 62 85 62H53ZM50 76C50 74.3431 51.3431 73 53 73H85C86.6569 73 88 74.3431 88 76C88 77.6569 86.6569 79 85 79H53C51.3431 79 50 77.6569 50 76ZM53 84C51.3431 84 50 85.3431 50 87C50 88.6569 51.3431 90 53 90H75.5C77.1569 90 78.5 88.6569 78.5 87C78.5 85.3431 77.1569 84 75.5 84H53Z"
        fill="url(#paint2_linear_8038_211570)"
      />
      <g filter="url(#filter1_d_8038_211570)">
        <circle cx="133" cy="105" r="23" fill="url(#paint3_linear_8038_211570)" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.666 105C144.666 111.443 139.443 116.667 133 116.667C126.556 116.667 121.333 111.443 121.333 105C121.333 98.5567 126.556 93.3333 133 93.3333C139.443 93.3333 144.666 98.5567 144.666 105ZM131.5 110C131.5 110.828 132.171 111.5 133 111.5H133.013C133.842 111.5 134.513 110.828 134.513 110C134.513 109.172 133.842 108.5 133.013 108.5H133C132.171 108.5 131.5 109.172 131.5 110ZM133 106.5C133.828 106.5 134.5 105.828 134.5 105V100C134.5 99.1716 133.828 98.5 133 98.5C132.171 98.5 131.5 99.1716 131.5 100V105C131.5 105.828 132.171 106.5 133 106.5Z"
        fill="url(#paint4_linear_8038_211570)"
      />
      <defs>
        <filter
          id="filter0_d_8038_211570"
          x="22"
          y="23"
          width="94"
          height="112"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="9" />
          <feGaussianBlur stdDeviation="9" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.964706 0 0 0 0 0.945098 0 0 0 0 0.980392 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8038_211570" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8038_211570" result="shape" />
        </filter>
        <filter
          id="filter1_d_8038_211570"
          x="75.6521"
          y="61.3913"
          width="114.696"
          height="114.696"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="13.7392" />
          <feGaussianBlur stdDeviation="17.1739" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.114792 0 0 0 0 0.258462 0 0 0 0 0.3625 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8038_211570" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8038_211570" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_8038_211570"
          x1="69.7241"
          y1="100"
          x2="92.837"
          y2="40.7257"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD8D8" />
          <stop offset="1" stopColor="#FFFEFE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8038_211570"
          x1="55.8942"
          y1="96.6731"
          x2="83.5416"
          y2="40.0002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8038_211570"
          x1="69"
          y1="100"
          x2="102.003"
          y2="72.3741"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB4747" stopOpacity="0.44" />
          <stop offset="1" stopColor="#EB4747" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8038_211570"
          x1="110.66"
          y1="103.193"
          x2="142.533"
          y2="124.606"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB4747" />
          <stop offset="1" stopColor="#E03B6C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8038_211570"
          x1="142.193"
          y1="108.301"
          x2="117.092"
          y2="100.667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ErrorCustomerList;
