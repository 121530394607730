import React from 'react';

const DragIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.25 10.75C7.66421 10.75 8 10.4142 8 10C8 9.58579 7.66421 9.25 7.25 9.25C6.83579 9.25 6.5 9.58579 6.5 10C6.5 10.4142 6.83579 10.75 7.25 10.75Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.75 10.75C13.1642 10.75 13.5 10.4142 13.5 10C13.5 9.58579 13.1642 9.25 12.75 9.25C12.3358 9.25 12 9.58579 12 10C12 10.4142 12.3358 10.75 12.75 10.75Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.25 15.75C7.66421 15.75 8 15.4142 8 15C8 14.5858 7.66421 14.25 7.25 14.25C6.83579 14.25 6.5 14.5858 6.5 15C6.5 15.4142 6.83579 15.75 7.25 15.75Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.75 15.75C13.1642 15.75 13.5 15.4142 13.5 15C13.5 14.5858 13.1642 14.25 12.75 14.25C12.3358 14.25 12 14.5858 12 15C12 15.4142 12.3358 15.75 12.75 15.75Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.25 5.75C7.66421 5.75 8 5.41421 8 5C8 4.58579 7.66421 4.25 7.25 4.25C6.83579 4.25 6.5 4.58579 6.5 5C6.5 5.41421 6.83579 5.75 7.25 5.75Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.75 5.75C13.1642 5.75 13.5 5.41421 13.5 5C13.5 4.58579 13.1642 4.25 12.75 4.25C12.3358 4.25 12 4.58579 12 5C12 5.41421 12.3358 5.75 12.75 5.75Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DragIcon;
