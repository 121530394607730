import { ApolloQueryResult } from '@apollo/client';
import { TokenResponse } from '@react-oauth/google';
import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';

import { visitIQSettings } from '~/constants';
import { User } from '~/constants/graphqlTypes';
import { FilterDateField, MeQuery, OrganizationType, Scope, useMeQuery } from '~/types/api.graphql';
import { getUserOrganizationType } from '~/utils/User';

type Nullable<T> = T | null;

export interface SessionSettings {
  isOnboadingShown?: boolean;
  journeyGlobalAudienceShown?: boolean;
  journeyPolygonsShown?: boolean;
  isSideBarCollapsed?: boolean;
  isBusinessSubscriptionShown?: boolean;
  isBusinessExportWarningDontShowAgain?: boolean;
  uniquesPixelHitFilter?: FilterDateField;
}

interface SessionContextProps {
  me: Nullable<User>;
  refetchMeQuery: () => Promise<ApolloQueryResult<MeQuery>>;
  settings: Nullable<SessionSettings>;
  updateSettingsProperty: (property: keyof SessionSettings, value: any) => void;
  hasOrgWithPaidSubscription: boolean;
  hasOrgWithSubscriptionOverride: boolean;
  hasOrgWithBusinessDataEnabled: boolean;
  googleAuthToken?: TokenResponse;
  setGoogleAuthToken: (token: TokenResponse) => void;
  isOrgAdmin: boolean;
  organizationType: OrganizationType;
  orgMenuItemTypes: OrganizationType[];
}

interface SessionProviderProps {
  children: ReactNode;
}

const defaultValues: SessionContextProps = {
  me: null,
  refetchMeQuery: null,
  settings: null,
  updateSettingsProperty: null,
  hasOrgWithPaidSubscription: false,
  hasOrgWithSubscriptionOverride: false,
  hasOrgWithBusinessDataEnabled: false,
  googleAuthToken: null,
  setGoogleAuthToken: null,
  isOrgAdmin: false,
  organizationType: OrganizationType.Customer,
  orgMenuItemTypes: [],
};

const SessionContext = createContext<SessionContextProps>(defaultValues);

export const SessionProvider: FC<SessionProviderProps> = ({ children }) => {
  const { data, refetch: refetchMeQuery } = useMeQuery();
  const [settings, updateSettings] = useState<SessionSettings>(undefined);

  const [googleAuthToken, setGoogleAuthToken] = useState<TokenResponse>(undefined);

  const hasOrgWithPaidSubscription = useMemo(
    () => data?.me?.organizations?.some((org) => org?.paidSubscription),
    [data],
  );
  const hasOrgWithSubscriptionOverride = useMemo(
    () => data?.me?.organizations?.some((org) => org?.subscriptionOverride),
    [data],
  );
  const hasOrgWithBusinessDataEnabled = useMemo(
    () => data?.me?.organizations?.some((org) => org?.businessDataEnabled),
    [data],
  );
  const isOrgAdmin = useMemo(() => data?.me?.scopes?.includes(Scope.OrgsAdmin) || false, [data]);
  const organizationType: OrganizationType = useMemo(() => getUserOrganizationType(data?.me), [data]);

  const orgMenuItemTypes = useMemo(() => {
    if (organizationType === OrganizationType.Customer) return [];

    let types = [OrganizationType.WhiteLabel, OrganizationType.Agency, OrganizationType.Customer];
    if (organizationType !== OrganizationType.WhiteLabel) {
      types = types.filter((type) => type !== OrganizationType.WhiteLabel);
    }
    return types;
  }, [organizationType]);

  useEffect(() => {
    if (data?.me) {
      const settingsItem = localStorage.getItem(`${visitIQSettings}-${data.me.id}`);
      updateSettings(settingsItem ? (JSON.parse(settingsItem) as SessionSettings) : {});
    }
  }, [data?.me]);

  useEffect(() => {
    if (settings && data?.me) {
      if (!settings.uniquesPixelHitFilter) {
        settings.uniquesPixelHitFilter = FilterDateField.PixelFirstHitDate;
      }
      localStorage.setItem(`${visitIQSettings}-${data.me.id}`, JSON.stringify(settings));
    }
  }, [settings, data?.me]);

  const updateSettingsProperty = <Key extends keyof SessionSettings>(property: Key, value: SessionSettings[Key]) => {
    settings[property] = value;
    updateSettings(settings);
    localStorage.setItem(`${visitIQSettings}-${data.me.id}`, JSON.stringify(settings));
  };

  return (
    <SessionContext.Provider
      value={{
        me: data?.me || null,
        refetchMeQuery,
        settings,
        isOrgAdmin,
        organizationType,
        orgMenuItemTypes,
        updateSettingsProperty,
        hasOrgWithPaidSubscription,
        hasOrgWithSubscriptionOverride,
        hasOrgWithBusinessDataEnabled,
        googleAuthToken,
        setGoogleAuthToken,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionContext: () => SessionContextProps = () => {
  return React.useContext(SessionContext);
};
