import { Image } from '@nextui-org/react';
import styled from 'styled-components';

export const LogoContainer = styled.div`
  display: flex;
`;

export const AppImage = styled(Image)`
  margin: 0;
`;
