import React from 'react';

const AttributionGACountIcon = (props: any) => {
  return (
    <svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" fill="white" r="11.5" stroke="#F4F4F4" />
      <path
        d="M14.125 7.05113V16.8011C14.125 17.8929 14.8775 18.5 15.6761 18.5C16.4148 18.5 17.2273 17.983 17.2273 16.8011V7.125C17.2273 6.12553 16.4886 5.5 15.6761 5.5C14.8636 5.5 14.125 6.19016 14.125 7.05113Z"
        fill="#F8AB00"
      />
      <path
        d="M10.0625 11.9999V16.801C10.0625 17.8928 10.815 18.4999 11.6136 18.4999C12.3523 18.4999 13.1648 17.9828 13.1648 16.801V12.0737C13.1648 11.0743 12.4261 10.4487 11.6136 10.4487C10.8011 10.4487 10.0625 11.1389 10.0625 11.9999Z"
        fill="#E37300"
      />
      <path
        d="M9.10227 16.9491C9.10227 17.8054 8.40749 18.5002 7.55114 18.5002C6.69478 18.5002 6 17.8054 6 16.9491C6 16.0927 6.69478 15.3979 7.55114 15.3979C8.40749 15.3979 9.10227 16.0927 9.10227 16.9491Z"
        fill="#E37300"
      />
    </svg>
  );
};

export default AttributionGACountIcon;
