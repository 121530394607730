import React from 'react';

const ResendIcon = (props: any) => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14.334 1.66675V5.66675H10.334" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12.6602 9.00005C12.2269 10.2267 11.4066 11.2792 10.323 11.9991C9.23942 12.7189 7.95122 13.0671 6.65253 12.9911C5.35383 12.9151 4.11501 12.4191 3.12275 11.5778C2.13048 10.7365 1.43853 9.59553 1.15116 8.32675C0.863784 7.05798 0.996564 5.73017 1.52949 4.54343C2.06241 3.35668 2.9666 2.37529 4.10581 1.74714C5.24502 1.11899 6.55753 0.878117 7.84555 1.06081C9.13357 1.24351 10.3273 1.83988 11.2469 2.76005L14.3336 5.66672"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ResendIcon;
