import React from 'react';

const AudienceQuotaIcon = (props: any) => {
  return (
    <svg height="20" width="20" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.9533 11H15.7133L17.6667 12.9533V15.7133L15.7133 17.6667H12.9533L11 15.7133V12.9533L12.9533 11Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M8.33333 12.5H5.83333C3.99238 12.5 2.5 13.9924 2.5 15.8333V16.6667"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <circle cx="9.16536" cy="5.83333" r="3.33333" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </svg>
  );
};

export default AudienceQuotaIcon;
