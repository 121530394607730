import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';

import { visitIQAudience } from '../constants';
import { useSessionContext } from '../contexts/SessionContext';
import {
  AudienceOverviewQuery,
  FieldCategory,
  FieldType,
  FilterNumberField,
  FilterStringField,
  useAudienceOverviewQuery,
} from '../types/api.graphql';
import { shouldShowPixelComponents } from '../utils/audience';

type Nullable<T> = T | null;

const propertiesBlackList = [
  FilterStringField.Email,
  FilterStringField.FirstName,
  FilterStringField.LastName,
  FilterStringField.Phone,
  FilterStringField.PathPrefix,
  FilterStringField.UtmCampaign,
  FilterStringField.UtmMedium,
  FilterStringField.UtmSource,
  FilterStringField.UtmSourceAndMedium,
];

export const propertiesB2B = [
  FilterStringField.CompanyName,
  FilterStringField.CompanyRevenue,
  FilterStringField.Department,
  FilterStringField.JobTitle,
  FilterStringField.PrimaryIndustry,
  FilterStringField.SeniorityLevel,
  FilterStringField.CompanyCity,
  FilterStringField.CompanyDomain,
  FilterStringField.CompanySic,
  FilterStringField.CompanyState,
  FilterStringField.CompanyEmployeeCount,
];

export const onlyPixelProperties = [FilterNumberField.Bebacks];

export const propertiesMapAvailable = [FilterStringField.PostalCode, FilterStringField.State];

export enum TaxonomyChartType {
  column = 'column',
  bar = 'bar',
  donut = 'donut',
  line = 'line',
  funnel = 'funnel',
  map = 'map',
}

export interface TaxonomyProperty {
  key: string;
  type: FieldType;
}

enum SettingsGroup {
  Taxonomy = 'taxonomy',
  Journey = 'journey',
  Attributions = 'attributions',
  BusinessInsights = 'businessInsights',
}

const settingsChartGroups = [SettingsGroup.Taxonomy, SettingsGroup.Journey, SettingsGroup.BusinessInsights];

type SettingsType = 'audience' | 'context';

type PickSettings<AudienceType, ContextType, TypeKey extends SettingsType> = TypeKey extends 'audience'
  ? AudienceType
  : ContextType;

type SettingsFactory<TypeKey extends SettingsType> = {
  [SettingsGroup.Taxonomy]?: PickSettings<TaxomonySettings, TaxonomyContextProps, TypeKey>;
  [SettingsGroup.Journey]?: PickSettings<ChartsSettings, ChartContextProps, TypeKey>;
  [SettingsGroup.Attributions]?: PickSettings<AttributionSourcesSettings, AttributionSourcesContextProps, TypeKey>;
  [SettingsGroup.BusinessInsights]?: PickSettings<BusinessInsightsSettings, BusinessInsightsContextProps, TypeKey>;
};

enum ChartSettingsProperty {
  Properties = 'properties',
  Charts = 'charts',
}

interface ChartsSettings {
  [ChartSettingsProperty.Properties]: TaxonomyProperty[];
  [ChartSettingsProperty.Charts]: TaxonomyChartType[];
}

interface TaxomonySettings extends ChartsSettings {
  selectedLayout?: number;
}

interface AttributionSourcesSettings {
  selectedGroup: string;
}

interface BusinessInsightsSettings extends ChartsSettings {
  selectedCategory: string;
}

type AudienceSettings = SettingsFactory<'audience'>;

interface ChartContextProps {
  selectedChartProperty?: (index: number) => Nullable<TaxonomyProperty>;
  setSelectedChartProperty?: (index: number, property: TaxonomyProperty) => void;
  selectedChartType?: (index: number) => Nullable<TaxonomyChartType>;
  setSelectedChartType?: (index: number, property: TaxonomyChartType) => void;
}

interface TaxonomyContextProps extends ChartContextProps {
  selectedLayout?: () => Nullable<number>;
  setSelectedLayout?: (selectedLayout: number) => void;
}

interface AttributionSourcesContextProps {
  selectedGroup?: () => Nullable<string>;
  setSelectedGroup?: (group: string) => void;
}

interface BusinessInsightsContextProps extends ChartContextProps {
  selectedCategory?: () => Nullable<string>;
  setSelectedCategory?: (category: string) => void;
}

type AudienceContextSettingsProps = SettingsFactory<'context'>;

interface AudienceContextBaseProps {
  audienceId: Nullable<string>;
  setAudienceId: (audienceId: string) => void;
  isLoading: boolean;
  audienceData: Nullable<AudienceOverviewQuery>;
  taxonomyPropertiesByCategory: (category: FieldCategory) => TaxonomyProperty[];
}

type AudienceContextProps = AudienceContextBaseProps & AudienceContextSettingsProps;

interface AudienceProviderProps {
  children: ReactNode;
}

const AudienceContext = createContext<AudienceContextProps>({
  audienceId: null,
  setAudienceId: undefined,
  isLoading: false,
  audienceData: undefined,
  taxonomyPropertiesByCategory: undefined,
});

export const AudienceProvider: FC<AudienceProviderProps> = ({ children }) => {
  const { me } = useSessionContext();
  const [audienceId, setAudienceId] = useState<Nullable<string>>(null);
  const [settings, setSettings] = useState<AudienceSettings>({});

  const { data: audienceData, loading } = useAudienceOverviewQuery({
    skip: !audienceId,
    variables: {
      audienceId,
    },
  });

  const personProperties: TaxonomyProperty[] = useMemo(() => {
    const numbersPropertiesEnabled = process.env.REACT_APP_TAXONOMY_NUMBER_ENABLED === 'true';
    const isB2BFiltersEnabled = process.env.REACT_APP_B2B_FILTERS_ENABLED === 'true';

    const shouldIncludeNumberField = (field: FilterNumberField) => {
      if (onlyPixelProperties.includes(field)) {
        return shouldShowPixelComponents(audienceData?.audience);
      }

      return true;
    };

    const numbersProperties = Object.keys(FilterNumberField)
      .filter((field) => shouldIncludeNumberField(FilterNumberField[field] as FilterNumberField))
      .map((key) => {
        return { key, type: FieldType.Number };
      });

    const shouldIncludeField = (field: FilterStringField) => {
      if (propertiesBlackList.includes(field)) return false;

      if (propertiesB2B.includes(field)) {
        return isB2BFiltersEnabled && audienceData?.audience?.organization?.businessDataEnabled;
      }

      return true;
    };

    const stringProperties = Object.keys(FilterStringField)
      .filter((field) => shouldIncludeField(FilterStringField[field] as FilterStringField))
      .map((key) => {
        return { key, type: FieldType.String };
      });

    return [...(numbersPropertiesEnabled ? numbersProperties : []), ...stringProperties].sort((a, b) =>
      a.key.localeCompare(b.key),
    );
  }, [audienceData]);

  const taxonomyPropertiesByCategory = (category: FieldCategory) =>
    category === FieldCategory.Person
      ? personProperties
      : propertiesB2B.map((key) => ({ key, type: FieldType.String }));

  const updateSettings = (settings: AudienceSettings) => {
    setSettings(settings);
    localStorage.setItem(`${visitIQAudience}-${me.id}-${audienceId}`, JSON.stringify(settings));
  };

  const createEmptyGroupSettingsIfNeeded = (group: SettingsGroup) => {
    if (!settings[group]) {
      settings[group.toString()] = {};
    }
  };

  const setSettingsValue = (group: SettingsGroup, property: string, value: any) => {
    createEmptyGroupSettingsIfNeeded(group);
    settings[group][property] = value;
    updateSettings(settings);
  };

  const chartsSettingsContextValues: AudienceContextSettingsProps = useMemo(() => {
    const createEmptyArrayFieldIfNeeded = (group: SettingsGroup, arrayKey: ChartSettingsProperty) => {
      createEmptyGroupSettingsIfNeeded(group);
      if (!settings[group][arrayKey]) {
        settings[group][arrayKey] = [];
      }
    };

    const chartsSettings: AudienceContextSettingsProps = {};
    settingsChartGroups.forEach((group) => {
      chartsSettings[group] = {
        selectedChartProperty: (index: number) => {
          return settings?.[group]?.[ChartSettingsProperty.Properties]?.[index];
        },
        setSelectedChartProperty: (index: number, property: TaxonomyProperty) => {
          createEmptyArrayFieldIfNeeded(group, ChartSettingsProperty.Properties);
          settings[group][ChartSettingsProperty.Properties][index] = property;
          updateSettings(settings);
        },
        selectedChartType: (index: number) => {
          return settings?.[group]?.[ChartSettingsProperty.Charts]?.[index];
        },
        setSelectedChartType: (index: number, type: TaxonomyChartType) => {
          createEmptyArrayFieldIfNeeded(group, ChartSettingsProperty.Charts);
          settings[group][ChartSettingsProperty.Charts][index] = type;
          updateSettings(settings);
        },
      };
    });
    return chartsSettings;
  }, [settings, updateSettings]);

  useEffect(() => {
    if (!me || !audienceId) return;

    let settings = {};
    const storageItem = localStorage.getItem(`${visitIQAudience}-${me.id}-${audienceId}`);
    if (storageItem) {
      settings = JSON.parse(storageItem) as AudienceSettings;
    }

    setSettings(settings);
  }, [me, audienceId]);

  return (
    <AudienceContext.Provider
      value={{
        audienceId,
        setAudienceId,
        isLoading: loading || !audienceId,
        audienceData,
        taxonomyPropertiesByCategory,
        ...chartsSettingsContextValues,
        taxonomy: {
          ...chartsSettingsContextValues.taxonomy,
          selectedLayout: () => settings.taxonomy?.selectedLayout || 0,
          setSelectedLayout: (selectedLayout: number) =>
            setSettingsValue(SettingsGroup.Taxonomy, 'selectedLayout', selectedLayout),
        },
        attributions: {
          selectedGroup: () => settings.attributions?.selectedGroup,
          setSelectedGroup: (selectedGroup: string) =>
            setSettingsValue(SettingsGroup.Attributions, 'selectedGroup', selectedGroup),
        },
        businessInsights: {
          ...chartsSettingsContextValues.businessInsights,
          selectedCategory: () => settings.businessInsights?.selectedCategory,
          setSelectedCategory: (selectedCategory: string) =>
            setSettingsValue(SettingsGroup.BusinessInsights, 'selectedCategory', selectedCategory),
        },
      }}
    >
      {children}
    </AudienceContext.Provider>
  );
};

export const useAudienceContext: () => AudienceContextProps = () => {
  return React.useContext(AudienceContext);
};
