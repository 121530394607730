import 'react-daterange-picker/dist/css/react-calendar.css';

import DateRangePicker from 'react-daterange-picker';
import styled from 'styled-components';

import ChevronUrl from '../../assets/chevron.svg';
import { ThemeProp } from '../../theme/Themes';
import DateTimeInput from '../DateTimeInput';

const breakpoints = {
  oneColumn: '38.375rem',
  hiddenRightPanel: '53.375rem',
};

export const Container = styled.div`
  display: flex;
`;

export const RangeSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PredefinedSelectionsColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 12.5rem;
  margin: 1.4rem 0 0 2.5rem;
  @media (max-width: ${breakpoints.hiddenRightPanel}) {
    display: none;
  }
`;

export const PredefinedSelectionsRowContainer = styled.div`
  display: none;
  margin: 0 0.5rem;
  gap: 0.5rem;
  padding-bottom: 1rem;
  @media (max-width: ${breakpoints.hiddenRightPanel}) {
    display: flex;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin: 0 0.5rem;
  @media (max-width: ${breakpoints.oneColumn}) {
    flex-direction: column;
    gap: 0;
  }
`;

interface DateRangePickerProps {
  skipPendingState?: boolean;
}

export const StyledDateRangePicker = styled(DateRangePicker)<DateRangePickerProps>`
  .DateRangePicker__Month {
    width: calc(50% - 1rem);
    margin: 0 0.5rem;
    @media (max-width: ${breakpoints.oneColumn}) {
      width: calc(100% - 1rem);
      &:nth-of-type(3) {
        margin-top: 1rem;
      }
    }
  }
  .DateRangePicker__MonthHeader {
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.c500};
    font-size: ${(props: ThemeProp) => props.theme.fontSize.xs};
    text-transform: uppercase;
  }
  .DateRangePicker__PaginationArrow:hover {
    background-color: transparent;
  }
  .DateRangePicker__PaginationArrow--previous {
    left: 0.5rem;
  }
  .DateRangePicker__PaginationArrow--next {
    right: 0.5rem;
  }
  .DateRangePicker__PaginationArrowIcon {
    width: 1.5rem;
    background-image: url(${ChevronUrl});
    background-repeat: no-repeat;
  }
  .DateRangePicker__PaginationArrowIcon--previous {
    border-right: unset;
    transform: rotate(90deg);
  }
  .DateRangePicker__PaginationArrowIcon--next {
    border-left: unset;
    transform: rotate(270deg);
    top: 4px;
  }
  .DateRangePicker__Date--otherMonth {
    opacity: 1;
    .DateRangePicker__DateLabel {
      color: ${(props: ThemeProp) => props.theme.colors.neutrals.c200};
    }
    &.DateRangePicker__Date--is-selected {
      .DateRangePicker__DateLabel {
        color: ${(props: ThemeProp) => props.theme.colors.neutrals.c200};
      }
    }
  }
  .DateRangePicker__Week {
    :first-child {
      .DateRangePicker__Date {
        border-top: 2px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c100};
      }
    }
  }
  .DateRangePicker__Date {
    :first-child {
      border-left: 2px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c100};
    }
    border-right: 2px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c100};
    border-bottom: 2px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c100};
    &.DateRangePicker__Date--today {
      .DateRangePicker__FullDateStates {
        background-color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
      }
      .DateRangePicker__DateLabel {
        color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
      }
      &.DateRangePicker__Date--is-selected {
        .DateRangePicker__DateLabel {
          background-color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
          color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
        }
      }
      .DateRangePicker__CalendarSelection {
        background-color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
      }
    }
  }
  .DateRangePicker__WeekdayHeading {
    border-bottom-width: 0;
    & > abbr[title] {
      font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
      font-size: ${(props: ThemeProp) => props.theme.fontSize.xs};
      color: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
    }
  }
  .DateRangePicker__Date--weekend {
    background-color: transparent;
  }
  .DateRangePicker__MonthDates {
    border-bottom: unset;
    border-spacing: 0;
  }
  .DateRangePicker__CalendarSelection {
    background-color: ${(props: ThemeProp) => props.theme.colors.selection};
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    border: unset;
    .DateRangePicker__Date--is-selected {
      color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
    }
  }
  .DateRangePicker__Date--is-selected {
    .DateRangePicker__DateLabel {
      color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
    }
  }
  .DateRangePicker__CalendarHighlight--single {
    background-color: transparent;
    border: 1px solid ${(props: ThemeProp) => props.theme.colors.primary.c500};
    border-radius: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .DateRangePicker__CalendarSelection--single--is-pending {
    ${(props: DateRangePickerProps) => props.skipPendingState && 'background-color: transparent;'}
  }
`;

export const StyledDateTimeInput = styled(DateTimeInput)`
  & .nextui-input {
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.c500};
  }
`;

interface PredefinedSelectionButtonProps {
  isFirst?: boolean;
  isSelected?: boolean;
}

type ButtonProps = PredefinedSelectionButtonProps & ThemeProp;

export const PredefinedSelectionButton = styled.div<PredefinedSelectionButtonProps>`
  display: flex;
  align-items: center;
  height: 2.875rem;
  padding: 0 1rem;
  cursor: pointer;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.m};
  background-color: ${(props: ButtonProps) => (props.isSelected ? props.theme.colors.selection : 'transparent')};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c500};
  @media (min-width: ${breakpoints.hiddenRightPanel}) {
    border-bottom: 2px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c100};
    border-top: ${(props: ButtonProps) => (props.isFirst ? `2px solid ${props.theme.colors.neutrals.c100}` : 'none')};
  }
  @media (max-width: ${breakpoints.hiddenRightPanel}) {
    height: 2.375rem;
    background-color: ${(props: ButtonProps) => props.theme.colors[props.isSelected ? 'selection' : 'c050']};
    border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
    text-align: center;
    justify-content: center;
  }
  @media (max-width: ${breakpoints.oneColumn}) {
    padding: 0 0.5rem;
    font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
    flex-grow: 1;
  }
`;

export const CustomPredefinedRangeButton = styled(PredefinedSelectionButton)`
  @media (max-width: ${breakpoints.oneColumn}) {
    display: none;
  }
`;
