import React from 'react';

const CollapseChevron = (props: any) => {
  return (
    <svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.2002 9.59998L12.0029 14.4L16.8002 9.59997"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default CollapseChevron;
