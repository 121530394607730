import styled from 'styled-components';

import { ThemeProp } from '../../../theme/Themes';
import Button from '..';

const SecondaryButton = styled(Button).attrs((props) => ({
  ...props,
  bordered: true,
}))`
  &:disabled {
    background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
    color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
    border-color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
  }
  .nextui-loading {
    --nextui--loadingColor: ${(props: ThemeProp) => props.theme.colors.primary.c500};
  }
`;

export default SecondaryButton;
