import React from 'react';

const RoleIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.5455 3H3.45455C2.65122 3 2 3.7835 2 4.75V15.25C2 16.2165 2.65122 17 3.45455 17H16.5455C17.3488 17 18 16.2165 18 15.25V4.75C18 3.7835 17.3488 3 16.5455 3Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clip-path="url(#clip0_1532_5860)">
      <path
        d="M13.75 5.83331L12.9167 6.66665M12.9167 6.66665L14.1667 7.91665L12.7083 9.37498L11.4583 8.12498M12.9167 6.66665L11.4583 8.12498M9.74583 9.83748C9.96098 10.0498 10.132 10.3025 10.2491 10.5811C10.3661 10.8598 10.4269 11.1588 10.4279 11.4611C10.4289 11.7633 10.3702 12.0628 10.255 12.3422C10.1398 12.6216 9.97044 12.8755 9.75673 13.0892C9.54301 13.3029 9.28913 13.4722 9.00971 13.5874C8.73028 13.7026 8.43083 13.7614 8.1286 13.7604C7.82636 13.7594 7.52731 13.6986 7.24866 13.5815C6.97002 13.4645 6.71728 13.2935 6.505 13.0783C6.08755 12.6461 5.85657 12.0672 5.86179 11.4664C5.86701 10.8655 6.10802 10.2907 6.53292 9.86581C6.95781 9.44092 7.53259 9.19991 8.13346 9.19469C8.73432 9.18946 9.3132 9.42045 9.74542 9.8379L9.74583 9.83748ZM9.74583 9.83748L11.4583 8.12498"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1532_5860">
        <rect width="10" height="10" fill="white" transform="translate(5 5)" />
      </clipPath>
    </defs>
  </svg>
);

export default RoleIcon;
