import React from 'react';

const OrganizationIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.2274 15.3701C11.9053 16.048 11.9053 17.1471 11.2274 17.8249C10.5495 18.5028 9.45047 18.5028 8.77257 17.8249C8.09469 17.1471 8.09469 16.048 8.77257 15.3701C9.45046 14.6922 10.5495 14.6922 11.2274 15.3701"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M11.2274 15.3701C11.9053 16.048 11.9053 17.1471 11.2274 17.8249C10.5495 18.5028 9.45047 18.5028 8.77257 17.8249C8.09469 17.1471 8.09469 16.048 8.77257 15.3701C9.45046 14.6922 10.5495 14.6922 11.2274 15.3701"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M11.2274 2.1751C11.9053 2.85299 11.9053 3.95205 11.2274 4.62994C10.5495 5.30783 9.45047 5.30783 8.77257 4.62994C8.09469 3.95206 8.09469 2.85299 8.77257 2.1751C9.45046 1.49722 10.5495 1.49722 11.2274 2.1751"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M4.62991 8.77257C5.3078 9.45046 5.3078 10.5495 4.62991 11.2274C3.95203 11.9053 2.85296 11.9053 2.17507 11.2274C1.49719 10.5495 1.49719 9.45047 2.17507 8.77257C2.85296 8.09469 3.95202 8.09469 4.62991 8.77257"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M17.8249 8.77257C18.5028 9.45046 18.5028 10.5495 17.8249 11.2274C17.147 11.9053 16.048 11.9053 15.3701 11.2274C14.6922 10.5495 14.6922 9.45047 15.3701 8.77257C16.048 8.09469 17.147 8.09469 17.8249 8.77257"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M15.3342 6.3325C14.885 5.68 14.32 5.115 13.6675 4.66583"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66583 13.6675C5.115 14.32 5.68 14.885 6.3325 15.3342"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.3325 4.66583C5.68 5.115 5.115 5.68 4.66583 6.3325"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6675 15.3342C14.32 14.885 14.885 14.32 15.3342 13.6675"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default OrganizationIcon;
