import React from 'react';

const CopyInviteLinkIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.66699 10.6667C8.95329 11.0494 9.31856 11.3661 9.73803 11.5953C10.1575 11.8245 10.6213 11.9607 11.0981 11.9949C11.5749 12.029 12.0534 11.9602 12.5012 11.7932C12.9491 11.6261 13.3557 11.3647 13.6937 11.0267L15.6937 9.02667C16.3009 8.39799 16.6368 7.55599 16.6292 6.682C16.6216 5.80801 16.2711 4.97197 15.6531 4.35394C15.035 3.73591 14.199 3.38535 13.325 3.37775C12.451 3.37016 11.609 3.70614 10.9803 4.31333L9.83366 5.45333"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3335 9.33334C11.0472 8.95058 10.6819 8.63388 10.2624 8.40471C9.84297 8.17554 9.37913 8.03926 8.90237 8.00511C8.4256 7.97097 7.94707 8.03976 7.49924 8.20681C7.0514 8.37387 6.64472 8.63529 6.3068 8.97334L4.3068 10.9733C3.69961 11.602 3.36363 12.444 3.37122 13.318C3.37881 14.192 3.72938 15.028 4.3474 15.6461C4.96543 16.2641 5.80147 16.6147 6.67546 16.6222C7.54945 16.6298 8.39146 16.2939 9.02013 15.6867L10.1601 14.5467"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CopyInviteLinkIcon;
