import React, { createContext, FC, ReactNode, useEffect, useRef } from 'react';

import { OrganizationQuery, useOrganizationQuery } from '../types/api.graphql';

type Nullable<T> = T | null;

interface OrganizationContextProps {
  organizationData: Nullable<OrganizationQuery>;
  organizationId: Nullable<string>;
  setOrganizationId: (organizationId: string) => void;
  isOrganizationInfoLoading: boolean;
  setHeaderVisible: (visible: boolean) => void;
  isHeaderVisible: boolean;
  setCreatingSubscription: (test: boolean) => void;
  isCreatingSubscription: () => boolean;
}

interface OrganizationProviderProps {
  children: ReactNode;
}

const OrganizationContext = createContext<OrganizationContextProps>({
  organizationData: null,
  organizationId: null,
  setOrganizationId: undefined,
  isOrganizationInfoLoading: false,
  setHeaderVisible: undefined,
  isHeaderVisible: true,
  setCreatingSubscription: undefined,
  isCreatingSubscription: undefined,
});

export const OrganizationProvider: FC<OrganizationProviderProps> = ({ children }) => {
  const [organizationId, setOrganizationId] = React.useState<Nullable<string>>(null);
  const [isHeaderVisible, setHeaderVisible] = React.useState(false);
  const creatingSubscriptionRef = useRef<boolean>(false);

  const { data: organizationData, loading: isOrganizationInfoLoading } = useOrganizationQuery({
    skip: !organizationId,
    variables: {
      organizationId,
    },
  });

  const setCreatingSubscription = (isCreating: boolean) => {
    creatingSubscriptionRef.current = isCreating;
  };

  const isCreatingSubscription = () => {
    return creatingSubscriptionRef.current;
  };

  useEffect(() => {
    setCreatingSubscription(false);
  }, [organizationId]);

  return (
    <OrganizationContext.Provider
      value={{
        organizationData,
        organizationId,
        setOrganizationId,
        isOrganizationInfoLoading,
        setHeaderVisible,
        isHeaderVisible,
        setCreatingSubscription,
        isCreatingSubscription,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganizationContext: () => OrganizationContextProps = () => {
  return React.useContext(OrganizationContext);
};
