import React from 'react';

const DashboardIcon = (props: any) => {
  return (
    <svg height="20" width="20" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.25 3.25H4.75C3.92157 3.25 3.25 3.92157 3.25 4.75V15.25C3.25 16.0784 3.92157 16.75 4.75 16.75H15.25C16.0784 16.75 16.75 16.0784 16.75 15.25V4.75C16.75 3.92157 16.0784 3.25 15.25 3.25Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path d="M3.25 7.75H16.75" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M7.75 16.75V7.75" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </svg>
  );
};

export default DashboardIcon;
