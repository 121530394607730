import React from 'react';

const DollarSignIcon = (props: any) => {
  return (
    <svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_7710_881)">
        <path d="M12 1.73376V22.2671" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <path
          d="M16.6666 5.46619H9.66657C8.8002 5.46619 7.96931 5.81035 7.35669 6.42297C6.74407 7.03559 6.3999 7.86648 6.3999 8.73285C6.3999 9.59923 6.74407 10.4301 7.35669 11.0427C7.96931 11.6554 8.8002 11.9995 9.66657 11.9995H14.3332C15.1996 11.9995 16.0305 12.3437 16.6431 12.9563C17.2557 13.5689 17.5999 14.3998 17.5999 15.2662C17.5999 16.1326 17.2557 16.9635 16.6431 17.5761C16.0305 18.1887 15.1996 18.5329 14.3332 18.5329H6.3999"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_7710_881">
          <rect height="22.4" width="22.4" fill="white" transform="translate(0.799805 0.799988)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DollarSignIcon;
