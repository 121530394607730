import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OrganizationIcon } from '../../assets';
import { Input } from '../../components';
import ActionModal, { ActionModalComponentProps } from '../../components/Modal/ActionModal/ActionModal';
import { useDebounce } from '../../hooks/useDebounce';
import TableComponent, { TableItem } from './components/TableComponent';
import {
  FiltersContainer,
  InputContainer,
  MobileFilterButton,
  ModalInputContainer,
  StyledDropdown,
  StyledInput,
  StyledSearchIcon,
  TableWrapper,
  Title,
  TitleContainer,
} from './TitleComponent.styles';

type OrganizationItem = {
  text: string;
  id: string;
  disabled: boolean;
  key: string;
  icon?: ReactNode;
};

interface TitleComponentProps {
  hideSearch?: boolean;
  onSearchChange: (value: string) => void;
  searchText: string;
  items?: OrganizationItem[];
  onItemSelectionChange?: (selection: number | number[]) => void;
  title: string;
  titleComponent?: ReactNode;
  dropdownPlaceholder?: string;
  searchPlaceholder: string;
  selectedIndex?: number;
  titleSelectionModal?: string;
}

const TitleComponent = ({
  hideSearch,
  onSearchChange,
  searchText,
  items,
  onItemSelectionChange,
  dropdownPlaceholder,
  searchPlaceholder,
  title,
  titleComponent,
  selectedIndex,
  titleSelectionModal,
}: TitleComponentProps) => {
  const { t } = useTranslation();
  const [searchTextLocal, setSearchTextLocal] = useState(searchText || '');
  const [searchTextInitial, setSearchTextInitial] = useState(searchText);
  const searchModalRef = useRef(null);
  const orgsModalRef = useRef(null);

  const searchTextDebounce = useDebounce(searchTextLocal, 500) as string;
  useEffect(() => {
    onSearchChange?.(searchTextDebounce);
  }, [searchTextDebounce]);

  if (searchText !== null && searchTextInitial !== searchText) {
    setSearchTextLocal(searchText);
    setSearchTextInitial(searchText);
  }

  const onSearchClick = () => {
    searchModalRef.current.open();
  };

  const onFilterOrgsClick = () => {
    orgsModalRef.current.open();
  };

  const SearchModalInput = forwardRef(({ onFinish }: ActionModalComponentProps, ref): any => {
    const [text, setText] = useState(searchTextLocal);

    useImperativeHandle(ref, () => ({
      submit: () => {
        setSearchTextLocal(text);
        onSearchChange?.(text);
        onFinish?.();
      },
    }));

    return (
      <ModalInputContainer>
        <Input
          placeholder={t('common.search')}
          aria-label={t('common.search')}
          iconLeft={<StyledSearchIcon />}
          clearable
          onChange={(e: any) => setText(e.target.value as string)}
          value={text}
        />
      </ModalInputContainer>
    );
  });

  const OrganizationsTable = forwardRef(({ onFinish }: ActionModalComponentProps, ref) => {
    const [selectedItem, setSelectedItem] = useState(selectedIndex);
    const itemsTable: TableItem[] = useMemo(() => {
      return items.map((item, index) => ({
        key: item.key,
        label: item.text,
        selected: index === selectedItem,
        icon: item.icon,
      }));
    }, items);

    const selectedKeys = selectedItem >= 0 ? new Set([itemsTable[selectedItem].key]) : undefined;

    useImperativeHandle(ref, () => ({
      submit: () => {
        onItemSelectionChange(selectedItem);
        onFinish?.();
      },
    }));

    const onSelectionChange = (selection) => {
      const selectionSet = selection as Set<string>;
      if (!selectionSet.size) {
        setSelectedItem(-1);
        return;
      }

      const key = Array.from(selectionSet)[0];
      const index = itemsTable.findIndex((item) => item.key === key);
      setSelectedItem(index);
    };

    return (
      <TableWrapper>
        <TableComponent
          items={itemsTable}
          multiselect={false}
          defaultSelectedKeys={selectedKeys}
          onSelectionChange={onSelectionChange}
        />
      </TableWrapper>
    );
  });

  return (
    <>
      <TitleContainer>
        {titleComponent || <Title>{title}</Title>}
        <FiltersContainer>
          {items && (
            <StyledDropdown
              selectedIndex={selectedIndex}
              disallowEmptySelection={false}
              placeholder={dropdownPlaceholder}
              items={items}
              showBadge={selectedIndex >= 0}
              placeholderIcon={<OrganizationIcon />}
              onSelectionChange={onItemSelectionChange}
            />
          )}
          {!hideSearch && (
            <InputContainer>
              <StyledInput
                placeholder={searchPlaceholder}
                aria-label={t('common.search')}
                iconLeft={<StyledSearchIcon />}
                clearable
                onChange={(e: any) => setSearchTextLocal(e.target.value as string)}
                value={searchText}
                autoFocus={!!searchText}
              />
            </InputContainer>
          )}
          {items && (
            <MobileFilterButton
              icon={<OrganizationIcon />}
              showBadge={selectedIndex >= 0}
              onClick={onFilterOrgsClick}
            />
          )}
          {!hideSearch && (
            <MobileFilterButton
              icon={<StyledSearchIcon />}
              showBadge={searchTextLocal.length > 1}
              onClick={onSearchClick}
            />
          )}
        </FiltersContainer>
      </TitleContainer>
      <ActionModal
        ref={searchModalRef}
        title={searchPlaceholder}
        submitButtonTitle={t('common.search')}
        Component={SearchModalInput}
      />
      <ActionModal
        ref={orgsModalRef}
        title={titleSelectionModal}
        submitButtonTitle={t('common.apply')}
        Component={OrganizationsTable}
      />
    </>
  );
};

export default TitleComponent;
