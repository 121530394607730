import React from 'react';

const CalendarAddIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12.4997 2.5V4.16667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.83366 2.5V4.16667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.5 6.66667H15.8333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M15.8333 10.3474V4.99992C15.8333 4.07909 15.0875 3.33325 14.1667 3.33325H4.16667C3.24583 3.33325 2.5 4.07909 2.5 4.99992V14.1666C2.5 15.0874 3.24583 15.8333 4.16667 15.8333H10.3475"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83283 8.95833C5.71783 8.95833 5.62449 9.05167 5.62533 9.16667C5.62533 9.28167 5.71866 9.375 5.83366 9.375C5.94866 9.375 6.04199 9.28167 6.04199 9.16667C6.04199 9.05167 5.94866 8.95833 5.83283 8.95833"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83283 12.2916C5.71783 12.2916 5.62449 12.3849 5.62533 12.4999C5.62533 12.6149 5.71866 12.7083 5.83366 12.7083C5.94866 12.7083 6.04199 12.6149 6.04199 12.4999C6.04199 12.3849 5.94866 12.2916 5.83283 12.2916"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.95833 9.1675C8.95833 9.2825 9.05167 9.37583 9.16667 9.375C9.28167 9.375 9.375 9.28166 9.375 9.16666C9.375 9.05166 9.28167 8.95833 9.16667 8.95833C9.05167 8.95833 8.95833 9.05166 8.95833 9.1675"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1667 18.3333C11.8658 18.3333 10 16.4683 10 14.1667C10 11.9133 11.9167 9.9975 14.17 10C16.47 10.0017 18.3333 11.8667 18.3333 14.1667C18.3333 16.4675 16.4683 18.3333 14.1667 18.3333"
      strokeWidth="1.5"
    />
    <path d="M14.1667 12.5V15.8333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.8333 14.1667H12.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CalendarAddIcon;
