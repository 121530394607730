import React from 'react';

const SegmentsIcon = (props: any) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.59966 3.77119L8.01388 2.35697C8.32341 2.04744 8.69087 1.80191 9.09529 1.6344C9.49971 1.46688 9.93316 1.38066 10.3709 1.38066C10.8086 1.38066 11.2421 1.46688 11.6465 1.6344C12.0509 1.80191 12.4184 2.04744 12.7279 2.35697C13.0375 2.6665 13.283 3.03396 13.4505 3.43838C13.618 3.8428 13.7042 4.27626 13.7042 4.714M5.98966 13.4504C5.58524 13.618 5.15178 13.7042 4.71405 13.7042C3.82999 13.7042 2.98214 13.353 2.35702 12.7279C1.7319 12.1028 1.38071 11.2549 1.38071 10.3708C1.38071 9.48679 1.7319 8.63895 2.35702 8.01383L3.77124 6.59961"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6 9.37085L9.77124 5.59961" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.5 15.3708C14.433 15.3708 16 13.8038 16 11.8708C16 9.93785 14.433 8.37085 12.5 8.37085C10.567 8.37085 9 9.93785 9 11.8708C9 13.8038 10.567 15.3708 12.5 15.3708Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.9502 11.3208H12.9502V12.3208H11.9502V11.3208Z" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SegmentsIcon;
