import React from 'react';

const AvatarPlaceholder = (props: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="24" height="24" rx="12" fill="#F4F4F4" />
    <path
      d="M17.3033 6.6967C20.2322 9.62563 20.2322 14.3744 17.3033 17.3033C14.3743 20.2322 9.62562 20.2322 6.6967 17.3033C3.76777 14.3743 3.76777 9.62562 6.6967 6.6967C9.62563 3.76777 14.3744 3.76777 17.3033 6.6967"
      stroke="#ABABAB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.658 8.93659C14.5734 9.85204 14.5734 11.3363 13.658 12.2517C12.7425 13.1672 11.2583 13.1672 10.3428 12.2517C9.42739 11.3363 9.42739 9.85204 10.3428 8.93659C11.2583 8.02114 12.7425 8.02114 13.658 8.93659"
      stroke="#ABABAB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.7539 17.7983C15.558 16.5392 13.8714 15.75 11.998 15.75C10.1247 15.75 8.43802 16.5392 7.24219 17.7992"
      stroke="#ABABAB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AvatarPlaceholder;
