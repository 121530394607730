import React from 'react';

import { useExtendedTranslation } from '../../hooks/useExtendedTranslation';
import { ShimmerImageWrapper, ShimmerTextWrapper } from '../Shimmer';
import { CardSection, CardSectionText, CardSectionTitle, IconText, Value } from './OverviewSectionBuilder.styles';

export interface SectionProps {
  titleKey: string;
  icon?: React.ReactNode;
  value?: string | number;
  expanded?: boolean;
  onValueClick?: (e: any) => void;
  component?: JSX.Element;
  tooltip?: () => JSX.Element;
}

interface OverviewSectionBuilderProps {
  items: SectionProps[];
  loading?: boolean;
  shimmerUnits?: 'pecents' | 'fixed';
}

const OverviewSectionBuilder = ({ items, loading, shimmerUnits = 'pecents' }: OverviewSectionBuilderProps) => {
  const { tOverrides } = useExtendedTranslation();

  const shimmerWidth = (value: number) => {
    return shimmerUnits === 'pecents' ? `${value}%` : `${value / 10}rem`;
  };

  const buildSection = ({ titleKey, icon, value, expanded, onValueClick, component, tooltip }: SectionProps) => {
    return (
      <CardSection $expanded={expanded} key={titleKey}>
        <ShimmerTextWrapper
          loading={loading}
          width={shimmerWidth(50)}
          height="0.75rem"
          marginTop="0.35rem"
          marginBottom="0.75rem"
        >
          <CardSectionTitle>{tOverrides(`${titleKey}`)}</CardSectionTitle>
        </ShimmerTextWrapper>
        <CardSectionText>
          <ShimmerImageWrapper loading={loading} height="1.25rem" width="1.25rem">
            {icon}
          </ShimmerImageWrapper>
          <ShimmerTextWrapper loading={loading} width={shimmerWidth(60)}>
            {component || (
              <IconText $clickable={!!onValueClick} onClick={(e) => onValueClick?.(e)}>
                <Value>{value}</Value>
                {tooltip?.()}
              </IconText>
            )}
          </ShimmerTextWrapper>
        </CardSectionText>
      </CardSection>
    );
  };

  return <>{items.map((item: SectionProps) => buildSection(item))}</>;
};

export default OverviewSectionBuilder;
