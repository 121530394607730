import styled from 'styled-components';

import { InfoIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';

export const HelpContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props: ThemeProp) => props.theme.colors.neutrals.c100};
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: 0 0.375rem;
`;

export const StyledInfoIcon = styled(InfoIcon).attrs((props: ThemeProp) => {
  return { stroke: props.theme.colors.neutrals.c400, fill: props.theme.colors.neutrals.c200 };
})``;

export const TooltipContent = styled.div`
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c100};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
`;
