import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ErrorText = styled.span`
  font-size: ${(props: ThemeProp) => `calc(${props.theme.fontSize.s} - 1px)`};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.s};
  color: ${(props: ThemeProp) => props.theme.colors.errorRed};
  margin: 0.125rem 0 0 0.625rem;
  letter-spacing: -0.05em;
`;

export const Label = styled.span`
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.s};
  padding-left: 0.5rem;
  margin-bottom: 0.375rem;
`;
