import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const AdminIcon = () => {
  const theme = useContext(ThemeContext);
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.19174 10.7505L1.78923 10.2971L1.19174 10.7505L2.32048 12.2381C2.3692 12.3023 2.38397 12.3861 2.36015 12.4631L1.80829 14.2471C1.5071 15.2207 2.09832 16.2447 3.09209 16.4707L4.91299 16.8848C4.99159 16.9026 5.05675 16.9573 5.088 17.0316L5.81197 18.7529C6.20708 19.6924 7.31819 20.0968 8.22473 19.6311L9.88577 18.7778C9.95747 18.741 10.0425 18.741 10.1142 18.7778L11.7753 19.6311C12.6818 20.0968 13.7929 19.6924 14.188 18.7529L14.912 17.0316C14.9432 16.9573 15.0084 16.9026 15.087 16.8848L16.9079 16.4707C17.9017 16.2447 18.4929 15.2207 18.1917 14.2471L17.6398 12.4631C17.616 12.3861 17.6308 12.3023 17.6795 12.2381L18.8083 10.7505C19.4243 9.93858 19.219 8.77412 18.3624 8.22188L16.7929 7.21C16.7252 7.16633 16.6827 7.09266 16.6787 7.01215L16.5871 5.14701C16.5371 4.1291 15.6314 3.36905 14.6202 3.49659L12.7675 3.73028C12.6875 3.74037 12.6076 3.71128 12.5528 3.65214L11.2838 2.28223L10.7414 2.78467L11.2838 2.28223C10.5912 1.53459 9.40879 1.53458 8.7162 2.28223L7.44716 3.65214C7.39238 3.71128 7.31245 3.74037 7.23248 3.73028L5.37978 3.49659C4.36864 3.36905 3.46285 4.12909 3.41288 5.14701L3.3213 7.01215C3.31735 7.09266 3.27482 7.16633 3.20707 7.21L1.6376 8.22188C0.781049 8.77412 0.575723 9.93858 1.19174 10.7505Z"
        fill={theme.colors.primary.c500}
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M12.6693 9L9.0026 12.6667L7.33594 11"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AdminIcon;
