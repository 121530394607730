import React from 'react';

import { Container, ShimmerButtonContainer, ShimmerCaption } from './ShimmerButton.styles';

const ShimmerButton = ({ ...props }) => {
  return (
    <Container>
      <ShimmerButtonContainer {...props} />
      <ShimmerCaption />
    </Container>
  );
};

export default ShimmerButton;
