import styled from 'styled-components';

import InfiniteScrollDropdown from '~/components/InfiniteScrollDropdown';

export const StyledInfiniteScrollDropdown = styled(InfiniteScrollDropdown)`
  & > button {
    width: 100%;
    height: 100%;
  }
`;
