import React, { forwardRef, Ref } from 'react';
import { BottomSheetProps } from 'react-spring-bottom-sheet';
import { RefHandles } from 'react-spring-bottom-sheet/dist/types';

import { StyledBottomSheet } from './BottomSheet.styles';

interface StyledBottomSheetProps extends BottomSheetProps {
  zIndex?: number;
}
const BottomSheet = forwardRef(({ zIndex, ...rest }: StyledBottomSheetProps, ref: Ref<RefHandles>) => {
  return <StyledBottomSheet ref={ref} zIndex={zIndex} {...rest} />;
});
export default BottomSheet;
