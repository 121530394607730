import React from 'react';

const CustomerListIcon = (props: any) => {
  return (
    <svg height="20" width="20" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.23471 4.87256C4.98371 5.10592 4.74988 5.3575 4.5353 5.62517C3.57463 6.82357 3 8.34476 3 10.0002C3 13.8662 6.13401 17.0002 10 17.0002C11.5231 17.0002 12.9326 16.5137 14.0816 15.6877C14.3714 15.4793 14.6447 15.2494 14.899 15.0002"
        strokeWidth="1.5"
      />
      <path d="M17 10C17 6.13401 13.866 3 10 3" strokeDasharray="2 4" strokeLinecap="round" strokeWidth="1.5" />
      <path d="M2 2L18.5 18.5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </svg>
  );
};

export default CustomerListIcon;
