import React from 'react';

const FileCheckIcon = (props: any) => {
  return (
    <svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" fill="#3CA540" opacity="0.1" r="12" />
      <path d="M16 9L10.5 14.5L8 12" stroke="#3CA540" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
    </svg>
  );
};

export default FileCheckIcon;
