import i18next from 'i18next';

export const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

export const hashCode = (str: string) => {
  let hash = 0,
    i: number,
    chr: number;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
};

export function lowercaseFirstLetter(str: string) {
  if (!str?.length) return str;
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function uppercaseFirstLetter(str: string) {
  if (!str?.length) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const lastFolder = (path: string) => {
  return path.match(/([^\\/]*)\/*$/)[1];
};

export const formatPeopleCount = (value: number): string => {
  return value.toLocaleString('en-US');
};

export const formatCurrency = (valueInCents: number, currency = 'USD', showCents = false): string => {
  return i18next.t('price', {
    val: { value: valueInCents / 100.0, currency, fractionDigits: showCents && 2 },
    lng: 'en-US',
  });
};

export const formatDate = (date: Date): string => {
  return i18next.t('date', { val: date ? new Date(date) : new Date(), interpolation: { escapeValue: false } });
};

export const formatDateShort = (date: Date): string => {
  const day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
  return `${day} ${month}`;
};

export const formatTime = (date: Date): string => {
  return date.toLocaleTimeString([], { timeStyle: 'short' });
};

export const formatUrl = (url?: string) => {
  if (!url) return '';

  // Split the URL into an array to distinguish double slashes from single slashes
  const doubleSlash = url.split('//');

  // Format the strings on either side of double slashes separately
  const formatted = doubleSlash
    .map(
      (str) =>
        // Insert a word break opportunity after a colon
        str
          .replace(/(?<after>:)/giu, '$1<wbr>')
          // Before a single slash, tilde, period, comma, hyphen, underline, question mark, number sign, or percent symbol
          .replace(/(?<before>[/~.,\-_?#%])/giu, '<wbr>$1')
          // Before and after an equals sign or ampersand
          .replace(/(?<beforeAndAfter>[=&])/giu, '<wbr>$1<wbr>'),
      // Reconnect the strings with word break opportunities after double slashes
    )
    .join('//<wbr>');

  return formatted;
};

export const omitTypename = (key: string, value: any) => {
  if (!value) return undefined;
  return key === '__typename' ? undefined : value;
};

export const getScrollParent = (node: HTMLElement) => {
  if (!node) return null;

  if (node.scrollHeight > node.clientHeight) {
    return node;
  }

  return getScrollParent(node.parentElement);
};

export const isSubset = (superset: any, subset: any) => {
  if (typeof superset !== 'object' || superset === null || typeof subset !== 'object' || subset === null) return false;

  if (superset instanceof Date || subset instanceof Date) return superset.valueOf() === subset.valueOf();

  return Object.keys(subset as object).every((key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!superset.propertyIsEnumerable(key)) return false;

    const subsetItem = subset[key];
    const supersetItem = superset[key];
    if (
      typeof subsetItem === 'object' && subsetItem !== null
        ? !isSubset(supersetItem, subsetItem)
        : supersetItem !== subsetItem
    )
      return false;

    return true;
  });
};

export const hex2rgba = (hex: string, alpha: number = undefined) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
};

export const generateRandomId = () => Math.floor(Math.random() * 1000000000);

export const truncateValue = (v: string, count: number) => {
  return v.length > count ? `${v.substring(0, count)}...` : v;
};

export const generateRandomId4Digits = () => Math.floor(Math.random() * 10000);
