import React from 'react';

const IntegrationsIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.91571 8.96094H12.0841C12.5445 8.96094 12.9178 9.33419 12.9178 9.79462V10.8367C12.9178 11.8727 12.078 12.7125 11.042 12.7125H8.95781C7.92185 12.7125 7.08203 11.8727 7.08203 10.8367V9.79462C7.08203 9.33419 7.45528 8.96094 7.91571 8.96094Z"
      stroke="stroke"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8.36313 8.95781V7.08203" stroke="stroke" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.6375 8.95781V7.08203" stroke="stroke" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M13.3349 16.7121C16.6454 15.0651 18.2612 11.2508 17.1409 7.72705C16.0206 4.2033 12.4989 2.02224 8.84509 2.58939C5.19131 3.15654 2.49643 6.30256 2.49707 10.0001C2.49532 12.0718 3.31848 14.0589 4.7847 15.5224V15.5224C5.87573 16.6093 7.35301 17.2198 8.89307 17.2198V17.2198C9.50433 17.2193 9.99974 16.7239 10.0002 16.1126V12.7096"
      stroke="stroke"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IntegrationsIcon;
