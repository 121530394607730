import React from 'react';

const WhiteLabelOrgIcon = (props: any) => (
  <svg height="20" width="20" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect height="5.5" width="5.5" rx="1.75" strokeWidth="1.5" x="7.25" y="2.25" />
    <path d="M5 15V12C5 11.4477 5.44772 11 6 11H14C14.5523 11 15 11.4477 15 12V15" strokeWidth="1.5" />
    <path d="M8 18V18C8 16.3431 6.65685 15 5 15V15C3.34315 15 2 16.3431 2 18V18" strokeWidth="1.5" />
    <path d="M18 18V18C18 16.3431 16.6569 15 15 15V15C13.3431 15 12 16.3431 12 18V18" strokeWidth="1.5" />
    <path d="M10 11V8" strokeWidth="1.5" />
  </svg>
);

export default WhiteLabelOrgIcon;
