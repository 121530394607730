import { Switch as NextSwitch } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
`;

interface TitleProps {
  $alignTitle?: 'left' | 'right';
}

export const StyledSwitch = styled(NextSwitch)<TitleProps>`
  padding: 0;
  order: ${(props: TitleProps) => (props.$alignTitle === 'left' ? 2 : 1)};
`;

export const Title = styled.div<TitleProps>`
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
  margin: ${(props: TitleProps) => (props.$alignTitle === 'left' ? 'auto 0.5rem auto auto' : 'auto auto auto 0.5rem')};
  order: ${(props: TitleProps) => (props.$alignTitle === 'left' ? 1 : 2)};
`;
