import React, { ComponentType, PropsWithChildren } from 'react';

import { ShimmerImage, ShimmerProps, ShimmerText } from './Shimmer.styles';
import ShimmerButton from './ShimmerButton';
import ShimmerInput from './ShimmerInput';

interface ShimmerWrapperProps extends ShimmerProps {
  loading?: boolean;
}

const ShimmerWrapper = <T extends { Component: ComponentType<ShimmerProps> } & ShimmerWrapperProps>(
  props: PropsWithChildren<T>,
) => {
  const { loading, children, ...rest } = props;
  return loading ? <props.Component {...rest} /> : <>{children}</>;
};

export const ShimmerTextWrapper = (props: PropsWithChildren<ShimmerWrapperProps>) => {
  return <ShimmerWrapper Component={ShimmerText} {...props} />;
};

export const ShimmerInputWrapper = (props: PropsWithChildren<ShimmerWrapperProps>) => {
  return <ShimmerWrapper Component={ShimmerInput} {...props} />;
};

export const ShimmerButtonWrapper = (props: PropsWithChildren<ShimmerWrapperProps>) => {
  return <ShimmerWrapper Component={ShimmerButton} {...props} />;
};

export const ShimmerImageWrapper = (props: PropsWithChildren<ShimmerWrapperProps>) => {
  return <ShimmerWrapper Component={ShimmerImage} {...props} />;
};
