import React from 'react';

import { ShimmerProps } from '../Shimmer.styles';
import { Container, ShimmerCaption } from './ShimmerInput.styles';

export interface ShimmerInputProps extends ShimmerProps {
  paddingText?: string;
}

const ShimmerInput = ({ paddingText, ...rest }: ShimmerInputProps) => {
  return (
    <Container {...rest}>
      <ShimmerCaption padding={paddingText} />
    </Container>
  );
};

export default ShimmerInput;
