import { Pagination } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    margin-top: 1rem;
  }
`;

export const StyledPagination = styled(Pagination)`
  .nextui-pagination-item {
    background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
    font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
    border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
  }

  .nextui-pagination-highlight {
    background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
    border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
  }

  .nextui-pagination-highlight--active {
    border: 1px solid ${(props: ThemeProp) => props.theme.colors.primary.c500};
    background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  }
  .nextui-pagination-item-active {
    box-shadow: none;
    .nextui-pagination-item-content {
      font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
      color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
    }
  }

  .nextui-pagination-item-disabled {
    .nextui-pagination-item-content {
      opacity: 0.2;
    }
  }
`;
