import { Modal as NextModal } from '@nextui-org/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { inMobile, useWindowDimensions } from '../../hooks/useDimensions';
import SubscriptionModalBanner from '../../overwrites/assets/SubscriptionModalBanner';
import {
  BannerContainer,
  ButtonsContainer,
  ContentContainer,
  FreePlanTag,
  StyledBottomSheet,
  StyledButton,
  StyledCloseIcon,
  StyledModal,
  StyledSecondaryButton,
  StyledSubTitle,
  StyledTitle,
} from './SubscriptionModal.styles';

interface SubscriptionModalProps {
  open: boolean;
  organizationId?: string;
  onClose: () => void;
}

const SubscriptionModal = ({ open, onClose }: SubscriptionModalProps) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  // const firstFreeOrgId = organizationId || me?.organizations?.find((org) => !org?.paidSubscription && org?.owned)?.id;
  // const isManagePlanEnabled = process.env.REACT_APP_MANAGE_PLAN_ENABLED === 'true';

  const modalContent = (
    <>
      <BannerContainer>
        <StyledCloseIcon onClick={onClose} />
        <SubscriptionModalBanner />
      </BannerContainer>
      <ContentContainer>
        <FreePlanTag>{t('subscriptions.freePlan')}</FreePlanTag>
        <StyledTitle>{t('subscriptions.freePlanDescription')}</StyledTitle>
        <StyledSubTitle>{t('subscriptions.freePlanExplanation')}</StyledSubTitle>
        <ButtonsContainer>
          <StyledSecondaryButton onClick={onClose}>{t('subscriptions.dismiss')}</StyledSecondaryButton>
          <StyledButton>
            <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noreferrer">
              {t('subscriptions.contactUs')}
            </a>
          </StyledButton>
        </ButtonsContainer>
      </ContentContainer>
    </>
  );

  return (
    <>
      {inMobile(width) ? (
        <StyledBottomSheet open={open} onDismiss={onClose}>
          {modalContent}
        </StyledBottomSheet>
      ) : (
        <StyledModal noPadding open={open} width={'29rem'} onClose={onClose}>
          <NextModal.Body noPadding>{modalContent}</NextModal.Body>
        </StyledModal>
      )}
    </>
  );
};

export default SubscriptionModal;
