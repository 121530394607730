import React, { ReactNode } from 'react';

import { Button } from '../../../../../overwrites/components/layout/ListHeader/components/FilterButton/Button/Button';
import { Badge, Container } from './FilterButton.styles';

interface FilterButtonProps {
  icon?: ReactNode;
  showBadge?: boolean;
  onClick?: () => void;
  width?: string;
  height?: string;
}

const FilterButton = ({ icon, showBadge, onClick, height, width, ...rest }: FilterButtonProps) => {
  return (
    <Container {...rest}>
      <Button width={width} height={height} icon={icon} onClick={onClick} />
      {showBadge && <Badge>1</Badge>}
    </Container>
  );
};

export default FilterButton;
