import React from 'react';

import { useBrandContext } from '~/brands/BrandContext';

import { useSessionContext } from '../../../contexts/SessionContext';
import LogoIcon from '../../../overwrites/assets/LogoIcon';
import LogoIconCollapsed from '../../../overwrites/assets/LogoIconCollapsed';
import { AppImage, LogoContainer } from './Logo.styles';

export const SideBarLogo = ({ gradientId, collapsed }: { gradientId?: string; collapsed: boolean }) => {
  const { me, isOrgAdmin } = useSessionContext();
  const { brand } = useBrandContext();

  return (
    <LogoContainer>
      {me &&
        (me?.whiteLabelImageUrl && !isOrgAdmin ? (
          <AppImage src={me?.whiteLabelImageUrl} />
        ) : collapsed ? (
          brand?.logoCollapsed || <LogoIconCollapsed gradientId={gradientId} />
        ) : (
          brand?.logo || <LogoIcon gradientId={gradientId} />
        ))}
    </LogoContainer>
  );
};
