import 'react-circular-progressbar/dist/styles.css';

import React, { useContext } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { ThemeContext } from 'styled-components';

import { ThemeType } from '../../theme/Themes';
import { UploadingContainer } from './CircularProgress.styles';

interface CircularProgressProps {
  size: number;
  value: number;
  strokeWidth?: number;
  fontSize?: number;
}

const CircularProgress = ({ size, value, strokeWidth = 2, fontSize = 12, ...rest }: CircularProgressProps) => {
  const theme: ThemeType = useContext(ThemeContext);
  const ratio = 100 / size; // we have svg box size 100x100 and we should update ratio related to the component size
  return (
    <UploadingContainer $size={size} {...rest}>
      <CircularProgressbar
        value={value}
        text={value.toString()}
        strokeWidth={strokeWidth * ratio}
        styles={{
          path: {
            stroke: theme.colors.primary.c500,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          },
          trail: {
            stroke: theme.colors.primary.c500,
            strokeOpacity: 0.1,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          },
          background: {
            fill: theme.colors.neutrals.white,
          },
          text: {
            fill: theme.colors.primary.c500,
            fontSize: fontSize * ratio,
            fontWeight: theme.fontWeight.medium,
          },
        }}
      />
    </UploadingContainer>
  );
};

export default CircularProgress;
