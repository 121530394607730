import { Divider } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

interface ItemsProps {
  $top?: number;
}
export const Items = styled.div.attrs((props: ItemsProps) => ({
  style: {
    top: `${props.$top}px`,
  },
}))<ItemsProps>`
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
  border: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c050};
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: fixed;
  z-index: 400;
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    position: static;
    border: unset;
    padding: 0;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  white-space: nowrap;
`;

export const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 2;
  gap: 1rem;
  height: 2rem;
  justify-content: space-between;
  overflow: hidden;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    max-width: 23rem;
  }
`;

export const ItemText = styled.span`
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
`;

export const ItemDescription = styled(ItemText)`
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
  font-style: italic;
`;

export const Separator = styled(Divider)`
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c050};
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;
