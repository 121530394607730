import React from 'react';

const CustomCategoryIcon = (props: any) => {
  return (
    <svg height="12" width="12" fill="none" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.293 6.705L6.70805 10.29C6.61517 10.383 6.50489 10.4567 6.38349 10.5071C6.26209 10.5574 6.13196 10.5833 6.00055 10.5833C5.86913 10.5833 5.73901 10.5574 5.61761 10.5071C5.49621 10.4567 5.38592 10.383 5.29305 10.29L0.998047 6V1H5.99805L10.293 5.295C10.4793 5.48236 10.5838 5.73581 10.5838 6C10.5838 6.26419 10.4793 6.51764 10.293 6.705V6.705Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3.49805 3.5H3.50255" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </svg>
  );
};

export default CustomCategoryIcon;
