import React from 'react';

const GlobalSearchIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2.5 10H17.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M10 2.5C11.5633 4.55376 12.4517 7.21903 12.5 10C12.4517 12.781 11.5633 15.4462 10 17.5C8.4367 15.4462 7.54828 12.781 7.5 10C7.54828 7.21903 8.4367 4.55376 10 2.5V2.5Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GlobalSearchIcon;
