import { createTheme } from '@nextui-org/react';

import { lightTheme } from '../overwrites/Theme/theme';

export interface ColorVariants {
  c900?: string;
  c800?: string;
  c700?: string;
  c600?: string;
  c500: string;
  c400?: string;
  c300?: string;
  c200?: string;
  c100?: string;
  c050?: string;
}

interface SystemColorVariants {
  error: ColorVariants;
  warning: {
    c500: string;
    c300: string;
    c100: string;
  };
  success: ColorVariants & { c900: string };
  information: {
    c500: string;
    c300: string;
    c100: string;
  };
  overlay: string;
}

interface Colors {
  primary: ColorVariants;
  neutrals: ColorVariants & { black: string; white: string };
  system: SystemColorVariants;
  primaryLight: string;
  p400: string;
  secondary: string;
  neon: string;
  background: string;
  selection: string;
  lightBlackBackground: string;
  lightGreenBackground: string;
  tableHeaderBackground: string;
  secondaryGraph: string;
  disabledPrimaryBackground: string;
  backgroundShadowGradientValue?: string;
  red: string;
  darkRed: string;
  warningYellow: string;
  graph: string[];
  errorRed: string;
  success: string;
  modal?: {
    background: string;
    startGradient: string;
    endGradient: string;
  };
}

interface Gradients {
  primary: string;
  overflowGradient: string;
  shimmer: string;
  shimmerPrimary: string;
  shimmerDark: string;
  welcome: string;
  selection: string;
}

interface FontSize {
  xxs: string;
  xs: string;
  s: string;
  m: string;
  ml: string;
  mlg: string;
  l: string;
  lxl: string;
  xl: string;
  '2xl': string;
  '3xl': string;
}

interface FontWeight {
  regular: number;
  medium: number;
  semibold: number;
  bold: number;
}

export interface Breakpoints {
  smallMobile: string;
  mobile: string;
  xlMobile: string;
  tablet: string;
  desktop: string;
  large: string;
  'extra-large': string;
}

export interface Shadow {
  xs: string;
  s: string;
  m: string;
  l: string;
  xl: string;
  '2xl': string;
}

export interface Fonts {
  primary: string;
  secondary: string;
}

export interface ThemeType {
  name: string;
  colors: Colors;
  gradients: Gradients;
  fontSize: FontSize;
  lineHeight: FontSize;
  fontWeight: FontWeight;
  breakpoints: Breakpoints;
  fonts: Fonts;
  borderRadius: string;
}

export type ThemeProp = { theme: ThemeType };

const fonts = {
  sans: `${lightTheme.fonts.primary}, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;`,
};

export const nextUIThemeMap = (theme: ThemeType) => {
  return createTheme({
    type: theme.name.toLowerCase(), // it could be "light" or "dark"
    theme: {
      colors: {
        // brand colors
        primaryLight: '$green200',
        primaryLightHover: '$green300',
        primaryLightActive: '$green400',
        primaryLightContrast: '$green600',
        primary: theme.colors.primary.c500,
        primaryBorder: theme.colors.primary.c500,
        primaryBorderHover: theme.colors.primary.c500,
        primarySolidHover: theme.colors.primary.c500,
        primarySolidContrast: theme.colors.neutrals.white,
        primaryShadow: theme.colors.neutrals.c500,
        gradient: 'linear-gradient(112deg, $blue100 -25%, $pink500 -10%, $purple500 80%)',
        inputBorder: theme.colors.neutrals.c200,
        inputBackground: theme.colors.neutrals.white,
        link: theme.colors.primary.c500,
        text: theme.colors.neutrals.black,
        textSecondary: theme.colors.neutrals.c400,
        grayScaleText: theme.colors.neutrals.c300,
        disabledText: theme.colors.neutrals.c200,
        disabledBackground: theme.colors.neutrals.c050,
      },
      borderWeights: {
        normal: '1px',
      },
      lineHeights: {
        xs2: '1rem',
        xs3: '1.125rem',
        sm2: '1.25rem',
        base2: '1.5rem',
      },
      radii: {
        xxs: theme.borderRadius,
      },
      space: {
        '6a': '0.8125rem',
        '10a': '1.625rem',
        25: '6.125rem',
        31: '7.875rem',
      },
      fonts,
      fontSizes: {
        checkbox: '$base',
        input: '0.9375rem',
      },
    },
  });
};
