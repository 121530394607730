import React from 'react';

import {
  CloseButton,
  HeaderContainer,
  HeaderTitle,
  StyledCloseIcon,
  StyledInput,
  StyledSearchIcon,
  Title,
} from './Header.styles';

const Header = ({ onClose, inputPlaceholder, setSearch, search, title, hideHeaderTitle }) => (
  <HeaderContainer>
    {!hideHeaderTitle && (
      <HeaderTitle>
        <Title>{title}</Title>
        <CloseButton icon={<StyledCloseIcon />} onClick={onClose} />
      </HeaderTitle>
    )}
    <StyledInput
      fullWidth
      id="searchItem"
      aria-label="searchItem"
      placeholder={inputPlaceholder}
      name="searchItem"
      iconLeft={<StyledSearchIcon />}
      onChange={(e: any): void => setSearch(e.target.value as string)}
      value={search}
    />
  </HeaderContainer>
);

export default Header;
