import React from 'react';

const ChartColumnIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.63656 17.0849H3.70073C3.26573 17.0849 2.91406 16.7324 2.91406 16.2983V13.1499C2.91406 12.7149 3.26656 12.3633 3.70073 12.3633H7.63573"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3594 3.70464V17.0846H16.2944C16.7294 17.0846 17.081 16.7321 17.081 16.298V3.70464C17.081 3.26964 16.7285 2.91797 16.2944 2.91797H13.146C12.7119 2.91797 12.3594 3.27047 12.3594 3.70464H12.3594Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3553 17.0848H7.63281V8.42729C7.63281 7.99229 7.98531 7.64062 8.41948 7.64062H12.3545"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChartColumnIcon;
