import React from 'react';

const GoogleAnalyticsIcon = (props: any) => {
  return (
    <svg height="32" width="32" fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.25 6.10227V25.6023C20.25 27.7859 21.755 29 23.3523 29C24.8295 29 26.4545 27.9659 26.4545 25.6023V6.24999C26.4545 4.25106 24.9773 3 23.3523 3C21.7273 3 20.25 4.38032 20.25 6.10227Z"
        fill="#F8AB00"
      />
      <path
        d="M12.125 15.9997V25.602C12.125 27.7856 13.63 28.9997 15.2273 28.9997C16.7045 28.9997 18.3295 27.9656 18.3295 25.602V16.1475C18.3295 14.1485 16.8523 12.8975 15.2273 12.8975C13.6023 12.8975 12.125 14.2778 12.125 15.9997Z"
        fill="#E37300"
      />
      <path
        d="M10.2045 25.8982C10.2045 27.6109 8.81499 29.0004 7.10227 29.0004C5.38956 29.0004 4 27.6109 4 25.8982C4 24.1855 5.38956 22.7959 7.10227 22.7959C8.81499 22.7959 10.2045 24.1855 10.2045 25.8982Z"
        fill="#E37300"
      />
    </svg>
  );
};

export default GoogleAnalyticsIcon;
