import React from 'react';

const FileTypeFailedIcon = (props: any) => {
  return (
    <svg height="20" width="20" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2 2C2 0.895431 2.89543 0 4 0H13.298L18 4.9199V18C18 19.1046 17.1046 20 16 20H4C2.89543 20 2 19.1046 2 18V2Z"
        fill="#FBBEC9"
        fillRule="evenodd"
      />
      <path
        d="M7.6001 8.59998L12.4001 13.4"
        stroke="#ED0A34"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M12.4001 8.59998L7.6001 13.4"
        stroke="#ED0A34"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default FileTypeFailedIcon;
