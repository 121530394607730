import React, { Key, ReactNode, useState } from 'react';

import { CollapseChevron } from '~/assets';

import { ChevronContainer, StyledCollapse, StyledCollapseTitle, StyledDivider } from './Collapse.styles';

interface CollapseProps {
  titleComponent: ReactNode;
  content?: ReactNode;
  defaultExpanded?: boolean;
  key?: Key;
  onHeaderClick?: () => void;
}

// The title component will be rendered as the title of the collapse and be present when collapsed.
// The content component will be rendered as the content of the collapse and be present when expanded.
const Collapse = ({ titleComponent, content, defaultExpanded, onHeaderClick, ...props }: CollapseProps): any => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded || false);

  const onClick = () => {
    if (!content) return;
    if (onHeaderClick) {
      onHeaderClick?.();
    } else {
      // If no custom behaviour is defined then toggle the expanded value
      setIsExpanded((prevState) => !prevState);
    }
  };

  return (
    <StyledCollapse
      {...props}
      contentLeft={<StyledCollapseTitle onClick={onClick}>{titleComponent}</StyledCollapseTitle>}
      divider={false}
      preventDefault={true}
      // Disabled so we can control the collapse only with the arrow
      disabled
      expanded={isExpanded}
      arrowIcon={
        content ? (
          <ChevronContainer onClick={() => setIsExpanded((prevState) => !prevState)}>
            <CollapseChevron />
          </ChevronContainer>
        ) : (
          <></>
        )
      }
      title=""
    >
      {content && (
        <>
          <StyledDivider />
          {content}
        </>
      )}
    </StyledCollapse>
  );
};

export default Collapse;
