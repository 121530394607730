import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

const Tag = styled.div`
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  border-radius: 4px;
  border: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c200};
  padding: 0.1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xxs};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xs};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
  width: fit-content;
  height: fit-content;
  font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
`;

export default Tag;
