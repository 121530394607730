import React from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { HideButton, ToastContainer } from './BlackToast.styles';

interface BlackToastProps {
  showHide?: boolean;
  text: string;
  toastId: string;
}

const BlackToast = ({ showHide = true, text, toastId }: BlackToastProps) => {
  const { t } = useTranslation();
  return (
    <ToastContainer>
      {text} {showHide && <HideButton onClick={() => toast.remove(toastId)}>{t('invite.hide')}</HideButton>}
    </ToastContainer>
  );
};

export default BlackToast;
