import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

interface CardSectionProps {
  $expanded?: boolean;
}

export const CardSection = styled.div<CardSectionProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  grid-column: ${(props: CardSectionProps) => props.$expanded && 'span 2'};
`;

export const CardSectionTitle = styled.span`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xs};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.semibold};
  font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xs};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const CardSectionText = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
  font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.s};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  gap: 0.5rem;

  svg {
    flex-shrink: 0;
  }

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    text-align: left;
  }
`;

interface IconTextProps {
  $clickable?: boolean;
}

export const IconText = styled.div<IconTextProps>`
  overflow: hidden;
  align-items: center;
  display: flex;
  color: ${(props: ThemeProp & IconTextProps) =>
    props.$clickable ? props.theme.colors.primary.c500 : props.theme.colors.neutrals.black};
  cursor: ${(props: IconTextProps) => (props.$clickable ? 'pointer' : 'default')};

  :hover {
    ${(props: IconTextProps) => props.$clickable && 'text-decoration: underline'};
  }
`;

export const Value = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Black = styled.div`
  display: inline-block;
  margin-right: 0.15rem;
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
`;
