import moment from 'moment';
import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { SquareImageComponent } from '../components';
import {
  AudienceCondition,
  AudienceFilterDateField,
  AudienceFilterNumberField,
  AudiencePixelStatus,
  useOrganizationsPageQuery,
} from '../types/api.graphql';
import { getOrganizationInitials } from '../utils/organization';

interface ItemsType {
  text: string;
  id: string;
  key: string;
  disabled: boolean;
  icon: ReactNode;
  imgSrc?: string;
  description?: string;
}

export type FilterDropdownItem<T> = {
  text: string;
  id: T;
  icon?: ReactNode;
};

interface ListFilterContextProps {
  items: ItemsType[];
  setItems: (value: ItemsType[]) => void;
  hasMore: boolean;
  loadMore: () => void;
  loadingMore: boolean;
  page: number;
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  loadingFirst: boolean;
  mobileScroll: number;
  setMobileScroll: (value: number) => void;
  filter: ItemsType;
  setFilter: (value: ItemsType) => void;
  isCreationModalOpen: boolean;
  setIsCreationModalOpen: (value: boolean) => void;
  searchOrgInfiniteScroll: string;
  setSearchOrgInfiniteScroll: (value: string) => void;
  handleReset: () => void;
  audienceConditionFilter: FilterDropdownItem<AudienceCondition>;
  setAudienceConditionFilter: (value: FilterDropdownItem<AudienceCondition>) => void;
  pixelStatusFilter: FilterDropdownItem<AudienceFilterConditionType>;
  setPixelStatusFilter: (value: FilterDropdownItem<AudienceFilterConditionType>) => void;
  quotaUsageFilter: number;
  setQuotaUsageFilter: (value: number) => void;
  recentMatchDateFilter: string;
  setRecentMatchDateFilter: (value: string) => void;
}

interface ListFilterProviderProps {
  children: ReactNode;
}

const defaultValues: ListFilterContextProps = {
  items: null,
  setItems: null,
  hasMore: false,
  loadMore: null,
  loadingMore: false,
  page: null,
  searchTerm: null,
  setSearchTerm: null,
  loadingFirst: false,
  mobileScroll: 0,
  setMobileScroll: null,
  filter: null,
  setFilter: null,
  isCreationModalOpen: false,
  setIsCreationModalOpen: null,
  searchOrgInfiniteScroll: null,
  setSearchOrgInfiniteScroll: null,
  handleReset: null,
  audienceConditionFilter: null,
  setAudienceConditionFilter: null,
  pixelStatusFilter: null,
  setPixelStatusFilter: null,
  quotaUsageFilter: null,
  setQuotaUsageFilter: null,
  recentMatchDateFilter: null,
  setRecentMatchDateFilter: null,
};

const ListFilterContext = createContext<ListFilterContextProps>(defaultValues);

const PAGE_ITEMS_COUNT = 10;
export const QUOTA_USAGE_PERCENTAGE = 80;
export const WITHOUT_RECENT_MATCHES_DATE = moment().subtract(2, 'days').format('YYYY-MM-DD');
export type AudienceFilterConditionType = AudiencePixelStatus | AudienceFilterNumberField | AudienceFilterDateField;

export const ListFilterProvider: FC<ListFilterProviderProps> = ({ children }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchOrgInfiniteScroll, setSearchOrgInfiniteScroll] = useState('');
  const [audienceConditionFilter, setAudienceConditionFilter] = useState<FilterDropdownItem<AudienceCondition>>(null);
  const [pixelStatusFilter, setPixelStatusFilter] = useState<FilterDropdownItem<AudienceFilterConditionType>>(null);
  const [quotaUsageFilter, setQuotaUsageFilter] = useState<number>(null);
  const [recentMatchDateFilter, setRecentMatchDateFilter] = useState<string>(null);

  const [filter, setFilter] = useState(null);
  const [mobileScroll, setMobileScroll] = useState(0);
  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);

  const location = useLocation();

  const isOrganizationPage = useMemo(() => location.pathname === '/app/organizations', [location]);

  useEffect(() => {
    if (filter) {
      setItems([filter, ...items.filter((item) => item.id !== filter.id)]);
    }
  }, [filter]);

  const { refetch, loading: loadingFirst } = useOrganizationsPageQuery({
    variables: {
      pageNumber: page,
      pageItemsCount: PAGE_ITEMS_COUNT,
      searchQuery: searchOrgInfiniteScroll,
    },
    skip: page > 1 || isOrganizationPage,
    onCompleted: (dataOrgs) => {
      const newItems = [
        // Always put the filtered org as the first item if exists
        ...(filter && !searchOrgInfiniteScroll ? [filter] : []),
        ...items.filter((item) => item.id !== filter?.id),
        ...(
          dataOrgs?.organizationsPage?.nodes?.map((organization) => ({
            text: organization.name,
            imgSrc: organization.imageUrl,
            id: organization.id,
            key: organization.id,
            description: t(`organization.settings.orgTypes.${organization?.organizationType?.toLowerCase()}`),
            disabled: false,
            icon: (
              <SquareImageComponent
                src={organization.imageUrl}
                squared
                text={getOrganizationInitials(organization.name || '')}
              />
            ),
          })) || []
        ).filter((item) => item.id !== filter?.id),
      ];
      setItems(newItems);
      setHasMore(dataOrgs?.organizationsPage?.pageInfo?.hasNextPage);
      setPage(page + 1);
    },
  });

  useEffect(() => {
    setPage(1);
    setItems([]);
  }, [searchOrgInfiniteScroll]);

  const handleLoadMore = () => {
    void (async () => {
      setLoadingMore(true);
      await refetch({ pageNumber: page, pageItemsCount: PAGE_ITEMS_COUNT, searchQuery: searchOrgInfiniteScroll });
      setPage(page + 1);
      setLoadingMore(false);
    })();
  };

  const handleReset = () => {
    setItems([]);
    setPage(1);
    setFilter(undefined);
    setAudienceConditionFilter(undefined);
    setPixelStatusFilter(undefined);
  };

  return (
    <ListFilterContext.Provider
      value={{
        items,
        setItems,
        hasMore,
        loadMore: handleLoadMore,
        loadingMore,
        page,
        searchTerm,
        setSearchTerm,
        loadingFirst,
        mobileScroll,
        setMobileScroll,
        filter,
        setFilter,
        isCreationModalOpen,
        setIsCreationModalOpen,
        searchOrgInfiniteScroll,
        setSearchOrgInfiniteScroll,
        handleReset,
        audienceConditionFilter,
        setAudienceConditionFilter,
        pixelStatusFilter,
        setPixelStatusFilter,
        quotaUsageFilter,
        setQuotaUsageFilter,
        recentMatchDateFilter,
        setRecentMatchDateFilter,
      }}
    >
      {children}
    </ListFilterContext.Provider>
  );
};

export const useListFilterContext: () => ListFilterContextProps = () => {
  return React.useContext(ListFilterContext);
};
