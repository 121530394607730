import { Checkbox as NextCheckbox } from '@nextui-org/react';
import styled from 'styled-components';

const Checkbox = styled(NextCheckbox).attrs((props) => ({
  ...props,
  size: 'sm',
  css: {
    '& .nextui-checkbox-container': {
      height: '$8',
      width: '$8',
    },
    '& .nextui-checkbox-text': {
      'font-size': '$sm',
      'line-height': '$sm2',
    },
  },
}))`
  opacity: ${(props) => (props?.disabled ? 0.4 : 1)};
`;

export default Checkbox;
