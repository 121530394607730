import styled from 'styled-components';

import { ThemeProp } from '../../../theme/Themes';
import { ShimmerText } from '../Shimmer.styles';

export const Container = styled.div`
  position: relative;
  flex: 1;
`;

export const ShimmerButtonContainer = styled(ShimmerText)``;

export const ShimmerCaption = styled(ShimmerText).attrs((props: ThemeProp) => {
  return { height: '0.625rem', gradient: props.theme.gradients.shimmerDark };
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
`;
