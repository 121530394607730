import React from 'react';

const InactivePixelIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.1467 13.4996C13.1467 13.4996 12.3996 14.397 11.3476 14.7353C10.2957 15.0736 9.09605 14.8251 8.26096 13.99L5.86948 11.5986C5.06436 10.7934 4.80455 9.64951 5.09006 8.62573C5.37557 7.60195 6.23986 7.13266 6.3999 6.75244"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.4052 4.8553L8.62817 4.63232C9.15649 4.10401 10.013 4.10401 10.5414 4.63232L15.3243 9.41529C15.8526 9.9436 15.8526 10.8002 15.3243 11.3285L15.0254 11.6274"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11.275 5.25243L13.4274 3.10009" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.7481 8.52978L16.9004 6.37744" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.02539 16.8774L6.77539 13.1274" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.77539 4.12744L15.4004 15.7524" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default InactivePixelIcon;
