import React, { ChangeEvent, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CountryData, PhoneInputProps as OriginalPhoneInputProps } from 'react-phone-input-2';

import { Container, Label, PhoneInputContainer, StyledInput, StyledPhoneInput } from './PhoneInput.styles';

const MAX_DIGITS = 15;

const updateDidDeleteRef = (
  currentValue: string,
  valueRef: MutableRefObject<string>,
  didDeleteRef: MutableRefObject<boolean>,
) => {
  if (valueRef.current.length > currentValue.length) {
    didDeleteRef.current = true;
  } else if (valueRef.current.length < currentValue.length) {
    didDeleteRef.current = false;
  }
  valueRef.current = currentValue;
};

const triggerInputEvent = (inputRef: MutableRefObject<HTMLInputElement | undefined>, value: string) => {
  if (!inputRef.current) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
  if (!nativeInputValueSetter) {
    return;
  }

  nativeInputValueSetter.call(inputRef.current, value);

  const event = new Event('input', { bubbles: true });
  inputRef.current.dispatchEvent(event);
};

const extractDigits = (text: string) =>
  text.split('').reduce((accumulator, letter) => {
    if (letter >= '0' && letter <= '9') {
      return accumulator + letter;
    }
    return accumulator;
  }, '');

const extractDots = (text: string) => (text || '').replace(/[^.]/g, '');

const removePlusPrefix = (text: string) => {
  text = text || '';
  return text
    .split('+')
    .slice(text.trim().startsWith('+') ? 1 : 0)
    .join('+');
};

export type PhoneInputProps = Omit<OriginalPhoneInputProps, 'onChange'> & {
  onChange?: (phone: string, isComplete: boolean) => void;
  error?: string;
  noMargin?: boolean;
  required?: boolean;
  defaultValue?: string;
};

enum InputField {
  CountryCode = 'CountryCode',
  PhoneNumber = 'PhoneNumber',
}

const PhoneInput: React.FC<PhoneInputProps> = (props) => {
  const { t } = useTranslation();
  const initialValueRef = useRef(props.value);
  const [fullPhoneNumber, setFullPhoneNumber] = useState(props.value || '');
  const [countryCode, setCountryCode] = useState('+1');
  const countryCodeRef = useRef(countryCode);
  const [countryISOCode, setCountryISOCode] = useState('us');
  const [phoneNumber, setPhoneNumber] = useState(props.defaultValue || '');
  const phoneNumberRef = useRef(phoneNumber);
  const didDeletePhoneNumberRef = useRef(false);
  const didDeleteCountryCodeRef = useRef(false);
  const hiddenPhoneInputRef = useRef<HTMLInputElement>();
  const phoneNumberInputRef = useRef<HTMLInputElement>();
  const countryCodeInputRef = useRef<HTMLInputElement>();
  const [lastInputField, setLastInputField] = useState<InputField>();

  updateDidDeleteRef(phoneNumber, phoneNumberRef, didDeletePhoneNumberRef);
  updateDidDeleteRef(countryCode, countryCodeRef, didDeleteCountryCodeRef);

  const isTab = (event: KeyboardEvent) => {
    return event.code === 'Tab';
  };

  useEffect(() => {
    const newFullPhoneNumber = `${countryCode} ${phoneNumber}`;
    const onlyDigits = extractDigits(newFullPhoneNumber);

    // react-phone-input-2 has a setting called "enableLongNumbers" which we want set as false (the default value) so it
    // doesn't allow longer numbers than what the format specifies.. the only downside of that is that when disabled,
    // and when there are more than 15 input phone's digits, when simulating an input event for that the onChange
    // callback doesn't get called, and thus we can't use the library's functionality to extract the valid number from
    // the formattedValue parameter.. when the number of digits is lower than that, that functionality can be used
    // correctly: e.g. entering +1 (314) 555-12345 will get a formattedValue of +1 (314) 555-1234, which we then use
    // to set again the phone number to (314) 555-1234
    if (onlyDigits.length <= MAX_DIGITS) {
      setFullPhoneNumber(newFullPhoneNumber);
    } else {
      // for digits.length > 15 however, we need to trim the number manually and don't even update the full phone number
      // (an example of this is a DO number: +1 809 123 123 123 12):
      setPhoneNumber((fullPhoneNumber.split(countryCode).pop() || '').trim());
    }
  }, [countryCode, phoneNumber]);
  useEffect(() => triggerInputEvent(hiddenPhoneInputRef, fullPhoneNumber), [fullPhoneNumber]);

  const onHiddenPhoneInputChange = (
    value: string,
    data: CountryData,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string,
  ) => {
    // take the the entered country code instead of the formatted value so we can process the actual country code
    // entered by the user (since the formatted value joins both country code and phone number, and the phone number
    // can be detected as part of the country code when editing)
    const countryCodeWithoutPlus = removePlusPrefix(countryCode);
    let processPhoneInfo = !!data.dialCode && countryCodeWithoutPlus.startsWith(data.dialCode);
    let finalCountryCode = countryCode;
    let finalPhoneNumber = phoneNumber;

    // for cases when the component is being rendered for the first time, and a value must be parsed, we need to rely
    // on the formatted value, we "whitelist" a valid one by comparing against the country code's format
    if (
      data.dialCode &&
      removePlusPrefix(formattedValue).startsWith(data.dialCode) &&
      initialValueRef.current === formattedValue &&
      finalCountryCode === '+1' &&
      !finalPhoneNumber
    ) {
      processPhoneInfo = true;
      const phoneParts = formattedValue.split(' ');
      finalCountryCode = phoneParts[0];
      finalPhoneNumber = phoneParts.slice(1).join(' ');
    }

    // allow updating country code/phone number in the first render, so we can accept and parse an input value
    if (processPhoneInfo) {
      if (!didDeleteCountryCodeRef.current) {
        setCountryISOCode(data.countryCode);
        finalCountryCode = `+${data.dialCode}`;

        // update the country code with the country data's dialCode
        setCountryCode(finalCountryCode);

        // get the updated phone number b stripping away the country code
        finalPhoneNumber = formattedValue.split(finalCountryCode)[1].trim();

        if (
          (finalPhoneNumber !== phoneNumber &&
            (!didDeletePhoneNumberRef.current || lastInputField === InputField.CountryCode)) ||
          !finalPhoneNumber
        ) {
          // and set it if it changed
          setPhoneNumber(finalPhoneNumber);
        }
      }
    }

    // Safari looses focus, so execute this to make sure it works correctly there too (this doesn't break functionality
    // in other browsers)
    if (lastInputField) {
      if (
        phoneNumber === finalPhoneNumber &&
        (lastInputField === InputField.CountryCode || (!phoneNumber.length && didDeletePhoneNumberRef.current)) &&
        (!data.dialCode || countryCodeWithoutPlus !== data.dialCode)
      ) {
        countryCodeInputRef.current?.focus();
      } else {
        phoneNumberInputRef.current?.focus();
      }
    }
    finalPhoneNumber = finalPhoneNumber.trim();

    // always report empty on no phone number
    if (!finalPhoneNumber) {
      props.onChange?.('', false);
      return;
    }

    finalCountryCode = finalCountryCode.trim();

    let fullNumber = '';
    if (finalCountryCode) {
      fullNumber += `${finalCountryCode.startsWith('+') ? finalCountryCode : `+${finalCountryCode}`} `;
    }
    fullNumber += finalPhoneNumber;
    fullNumber = fullNumber.trim();

    // some countries don't have a known format, and the default format ends up making the number very long, we handle
    // those cases by informing any input is a valid phone number
    const isUnknownFormat = extractDots(data.format).length > MAX_DIGITS;
    const isComplete = isUnknownFormat || (processPhoneInfo && data.format.length === fullNumber.length);
    props.onChange?.(fullNumber, isComplete);
  };

  const onCountryChange = (value: string, data: CountryData) => {
    setCountryCode(value);
    setCountryISOCode(data.countryCode);
  };

  return (
    <Container $noMargin={props.noMargin || false}>
      <Label $required={props.required}>{t('auth.signUp.phoneNumber')}</Label>
      <PhoneInputContainer error={props.error}>
        <StyledPhoneInput
          style={{ display: 'none' }}
          inputProps={{ ref: hiddenPhoneInputRef }}
          onChange={onHiddenPhoneInputChange}
        />
        <StyledPhoneInput
          {...props}
          inputProps={{ ref: countryCodeInputRef }}
          key={countryISOCode}
          country={countryISOCode}
          value={countryCode}
          onChange={onCountryChange}
          placeholder="+1"
          onKeyDown={(e: KeyboardEvent) => {
            setLastInputField(InputField.CountryCode);
            if (isTab(e) && !e.shiftKey) {
              // If the user press Tab (and not shift + tab) then move the focus to the phone (ignore arrow)
              phoneNumberInputRef.current?.focus();
              e.preventDefault();
            }
          }}
        />
        <StyledInput
          ref={phoneNumberInputRef}
          value={phoneNumber}
          aria-label={t('auth.signUp.phoneNumber')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value.trim())}
          fullWidth={false}
          onKeyDown={(e: KeyboardEvent) => {
            setLastInputField(InputField.PhoneNumber);
            if (e.code === 'Backspace' && !phoneNumber) {
              // switch to the country input when hitting backspace on an empty phone number input
              countryCodeInputRef.current?.focus();
              e.preventDefault();
            } else if (isTab(e) && e.shiftKey) {
              // If the user press Shift + Tab then move the focus to the country field (ignore arrow)
              countryCodeInputRef.current?.focus();
              e.preventDefault();
            }
          }}
          error={props.error}
          placeholder={t('auth.signUp.phoneNumber')}
        />
      </PhoneInputContainer>
    </Container>
  );
};

export default PhoneInput;
