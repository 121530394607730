import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const IntegrationTrashIcon = (props: any) => {
  const themeContext = useContext(ThemeContext);
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.5" y="0.5" width="47" height="47" rx="5.5" fill="white" stroke="#EAEAEA" />
      <path
        d="M15.8333 18.1667H32.1666"
        stroke={themeContext.colors.primary.c500}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31 18.1666V31C31 32.2891 29.9558 33.3333 28.6667 33.3333H19.3333C18.0442 33.3333 17 32.2891 17 31V18.1666"
        stroke={themeContext.colors.primary.c500}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5 14.375H20.5"
        stroke={themeContext.colors.primary.c500}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6666 22.8334V28.6667"
        stroke={themeContext.colors.primary.c500}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.3333 22.8334V28.6667"
        stroke={themeContext.colors.primary.c500}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IntegrationTrashIcon;
