import styled from 'styled-components';

import { SearchIcon } from '../../assets';
import { Dropdown, Input } from '../../components';
import { ThemeProp } from '../../theme/Themes';
import FilterButton from '../layout/ListHeader/components/FilterButton';

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-right: 1rem;
  gap: 1rem;
`;

export const Title = styled.p`
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.l};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.m};
  font-family: ${(props: ThemeProp) => props.theme.fonts.secondary};
  margin: 0;
  align-self: center;
`;

export const InputContainer = styled.div`
  .nextui-input-main-container {
    margin-top: 0;
    margin-bottom: 0;
    min-width: 12rem;
  }
  .nextui-input-container--input {
    display: flex;
    height: 2.25rem;
  }
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    display: none;
  }
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.desktop}) {
    .nextui-input-main-container {
      min-width: unset;
    }
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
`;

export const StyledDropdown = styled(Dropdown)`
  width: 100%;
  height: 2.25rem;
  width: 12.5rem;
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const StyledInput = styled(Input)`
  height: 2.5rem;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const MobileFilterButton = styled(FilterButton)`
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
  display: none;
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    display: flex;
  }
`;

export const ModalInputContainer = styled.div`
  margin-top: 0.5rem;
  .nextui-input-main-container {
    margin-bottom: 0;
  }
`;

export const TableWrapper = styled.div`
  .nextui-table-container {
    box-shadow: none;
    border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
  }
`;
