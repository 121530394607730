import React, { Key, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useListFilterContext } from '../../contexts/ListFiltersContext';
import { useDebounce } from '../../hooks/useDebounce';
import { ShimmerImage, ShimmerText } from '../Shimmer';
import {
  NoResults,
  StyledInput,
  StyledSearchIcon,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
  TableCellDescription,
  TableLeftContainer,
} from './OrganizationsInfiniteScrollFilter.styles';

interface ItemType {
  key: string;
  label: string;
  selected?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  description?: string;
}

interface OrganizationsInfiniteScrollFilterProps {
  items: ItemType[];
  minCountSelected?: number;
  onSelectionChange: (selection: Set<Key>) => void;
}

const OrganizationsInfiniteScrollFilter = ({
  items,
  onSelectionChange,
  minCountSelected = 0,
}: OrganizationsInfiniteScrollFilterProps) => {
  const { t } = useTranslation();
  const listRef = React.useRef(null);
  const {
    hasMore,
    loadMore,
    loadingMore,
    searchOrgInfiniteScroll,
    setMobileScroll,
    mobileScroll,
    setSearchOrgInfiniteScroll,
  } = useListFilterContext();

  const [searchTextLocal, setSearchTextLocal] = useState(searchOrgInfiniteScroll || '');
  const searchTextDebounce = useDebounce(searchTextLocal, 500) as string;

  useEffect(() => {
    if (!items || items?.length < 10) {
      setMobileScroll?.(0);
    }
  }, [items]);

  useEffect(() => {
    if (mobileScroll > 0) {
      listRef.current.scrollTop = mobileScroll;
    }
  }, [mobileScroll, listRef?.current]);

  useEffect(() => {
    setSearchOrgInfiniteScroll?.(searchTextDebounce);
  }, [searchTextDebounce]);

  const getDefaultSelectedIndexes = (items: ItemType[]) => {
    return new Set(items.filter((item) => item.selected).map((item) => item.key));
  };
  const [selectedItems, setSelectedItems] = React.useState<Set<Key>>(getDefaultSelectedIndexes(items));

  const onItemSelected = (id: string, selected: boolean) => {
    setMobileScroll(listRef.current.scrollTop as number);
    if (!selected && minCountSelected > 0 && selectedItems.size <= minCountSelected) {
      return;
    }
    const selectedItemsTemp = new Set([]);
    if (!selectedItems.has(id)) {
      selectedItemsTemp.add(id);
    }
    onLocalSelectionChange(selectedItemsTemp as Set<string>);
    setSelectedItems(selectedItemsTemp);
  };

  const onLocalSelectionChange = (selection: Set<string>) => {
    const selectionSet = selection;
    const singleSelection = new Set([...selectionSet].filter((key) => !selectedItems?.has(key)));
    setSelectedItems(singleSelection);
    onSelectionChange?.(singleSelection);
  };

  const renderItems = () => {
    return items?.length > 0 ? (
      items
        .map((item) => (
          <StyledTableRow
            onClick={() => !item.disabled && onItemSelected(item.key, !selectedItems.has(item.key))}
            key={item.key}
            $isSelected={selectedItems.has(item.key)}
          >
            <TableLeftContainer>
              {item.icon}
              <StyledTableCell>{item.label}</StyledTableCell>
            </TableLeftContainer>
            {item.description && <TableCellDescription>{item.description}</TableCellDescription>}
          </StyledTableRow>
        ))
        .concat(loadingMore ? listShimmer : null)
    ) : (
      <NoResults>{t('common.noResults')}</NoResults>
    );
  };

  const listShimmer = [...Array(10)].map((_, index) => (
    <StyledTableRow key={`${index}-shimmer`}>
      <ShimmerImage width="1.5rem" height="1.5rem" />
      <ShimmerText width="7rem" />
      <StyledTableCell />
    </StyledTableRow>
  ));

  return (
    <StyledTable>
      <InfiniteScroll
        dataLength={items.length}
        next={() => {
          setMobileScroll(listRef?.current?.scrollTop as number);
          loadMore();
        }}
        hasMore={hasMore}
        hasChildren={items.length > 0}
        scrollableTarget="scrollableDiv"
        scrollThreshold={'20px'}
        loader={null}
      >
        <StyledInput
          placeholder={searchTextLocal}
          aria-label={t('common.search')}
          iconLeft={<StyledSearchIcon />}
          clearable
          onChange={(e: any) => setSearchTextLocal(e.target.value as string)}
          value={searchTextLocal}
          removeMargin
          containerMarginTop="0.75rem"
          marginBottom="0.25rem"
        />
        <StyledTableBody ref={listRef} id="scrollableDiv">
          {loadingMore ? listShimmer : renderItems()}
        </StyledTableBody>
      </InfiniteScroll>
    </StyledTable>
  );
};

export default OrganizationsInfiniteScrollFilter;
