import { Dropdown } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xs};
  margin: 0 0 0.25rem 0.5rem;
  display: flex;
  align-items: center;
`;

export const StyledDropdown = styled(Dropdown).attrs((props) => ({
  ...props,
  isBordered: props.isBordered === undefined ? true : props.isBordered,
  offset: 8,
}))``;

export const DropDownButton = styled(Dropdown.Button).attrs((props) => {
  const isDisabled = props.disabled;

  return {
    css: {
      color: props.theme.colors.neutrals.black,
      height: 'calc($15 - 2px)',
      'border-radius': props.theme.borderRadius,
      'border-color': props.isBordered
        ? props.error
          ? props.theme.colors.red
          : props.theme.colors.neutrals.c200
        : 'transparent',
      'padding-left': '$6',
      'padding-right': '$6',
      fontSize: '$input',
      fontWeight: '$normal',
      '&.nextui-dropdown-button': {
        justifyContent: 'space-between',
        background: isDisabled ? props.theme.colors.neutrals.c050 : 'transparent',
        color: isDisabled ? props.theme.colors.neutrals.c400 : props.theme.colors.neutrals.black,
      },
      '&.nextui-dropdown-button[aria-haspopup="true"].nextui-dropdown-button[aria-expanded="true"]': {
        transform: 'unset',
      },
      '.nextui-button-text': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'block',
      },
      '.nextui-button-icon-right': {
        stroke: props.theme.colors.neutrals.black,
      },
      minWidth: 'unset',
      ...props.css,
    },
    bordered: true,
    ...props,
  };
})``;

export const DropDownItemText = styled.span`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DropDownValueText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DropDownItemDescription = styled(DropDownItemText)`
  font-style: italic;
  padding-right: 0.25rem;
  flex-shrink: 0;
`;

interface DropDownMenuProps {
  width: number;
  $maxHeight?: string;
}

export const DropDownMenu = styled(Dropdown.Menu)<DropDownMenuProps>`
  max-width: unset;

  width: ${(props: DropDownMenuProps) => (props.width ? `${props.width}px` : 'auto')};
  & .nextui-dropdown-item {
    height: unset;
    padding: 0 0.5rem;

    :hover {
      background: ${(props: ThemeProp) => props.theme.colors.primaryLight}EA;
      color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
      svg {
        stroke: ${(props: ThemeProp) => props.theme.colors.primary.c500};
      }
      ${DropDownItemDescription} {
        color: ${(props: ThemeProp) => props.theme.colors.graph[6]};
      }
    }
  }

  ${DropDownItemDescription} {
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
  }

  .is-selected {
    background: ${(props: ThemeProp) => props.theme.colors.primaryLight};
    color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
    svg {
      stroke: ${(props: ThemeProp) => props.theme.colors.primary.c500};
    }
    ${DropDownItemDescription} {
      color: ${(props: ThemeProp) => props.theme.colors.graph[6]};
    }
  }

  .nextui-dropdown-item-content {
    overflow: hidden;
  }
`;

export const DropDownItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  height: 2rem;
`;

export const DropDownItemLoader = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 2rem;
`;

export const DropDownItemLeftContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 0.5rem;
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c050};
`;

export const DropdownError = styled.span`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xs};
  color: ${(props: ThemeProp) => props.theme.colors.red};
  margin: 0.125rem 0.5rem 0 0.5rem;
`;
