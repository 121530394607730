import React from 'react';

const FailedIcon = (props: any) => {
  return (
    <svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" fill="#ED0A34" opacity="0.1" r="12" />
      <path
        d="M9.6001 9.59998L14.4001 14.4"
        stroke="#ED0A34"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M14.4001 9.59998L9.6001 14.4"
        stroke="#ED0A34"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default FailedIcon;
