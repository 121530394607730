import { Popover } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';
import { Button, SecondaryButton } from '../';
import BottomSheet from '../BottomSheet';

export const StyledBottomSheet = styled(BottomSheet)`
  display: none;
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    display: flex;

    .nextui-table-container {
      box-shadow: none;
      border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
    }
  }
`;

export const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    margin-bottom: 1.5rem;
  }
`;
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0.75rem 0rem 0.75rem;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    padding: 0 0 1rem 0;
  }
`;

export const StyledButton = styled(Button)`
  padding: 0.5rem 1rem;
  min-width: fit-content;
  height: 1.5rem;
  width: 50%;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xs};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.s};

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    flex: 1;
    height: 3rem;
    font-size: ${(props: ThemeProp) => props.theme.fontSize.ml};
    line-height: ${(props: ThemeProp) => props.theme.lineHeight.s};
  }
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  padding: 0.5rem 1rem;
  min-width: fit-content;
  height: 1.5rem;
  width: 50%;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xs};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.s};

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    flex: 1;
    height: 3rem;
    font-size: ${(props: ThemeProp) => props.theme.fontSize.ml};
    line-height: ${(props: ThemeProp) => props.theme.lineHeight.s};
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: center;
  padding: 0.75rem;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    padding: 2rem 0;
  }
`;

interface StyledPopoverContentProps {
  $width?: string;
}

export const StyledPopoverContent = styled(Popover.Content)<StyledPopoverContentProps>`
  width: ${(props: StyledPopoverContentProps) => props.$width || ''}px;
  overflow: hidden;
`;
