import React from 'react';

const BusinessFilterIcon = (props: any) => {
  return (
    <svg height="20" width="20" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8 17H3V3H15V7.5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M7 17V12H8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M6.5 6.5H11M6.5 9H10" strokeLinecap="round" strokeWidth="1.5" />
      <path
        d="M18.25 11.125H10.75L13.5 14.6725V17.125L15.5 17.875V14.6725L18.25 11.125Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default BusinessFilterIcon;
