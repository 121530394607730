import { Avatar } from '@nextui-org/react';
import styled from 'styled-components';

export const SquareImageComponent = styled(Avatar)`
  width: 1.5rem;
  height: 1.5rem;
  min-width: unset;
  min-height: unset;
  border-radius: 6px;
  flex-shrink: 0;
`;
