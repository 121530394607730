import 'react-spring-bottom-sheet/dist/style.css';

import { BottomSheet } from 'react-spring-bottom-sheet';
import styled from 'styled-components';

interface StyledBottomSheetProps {
  zIndex?: number;
}

export const StyledBottomSheet = styled(BottomSheet)<StyledBottomSheetProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  [data-rsbs-backdrop] {
    z-index: ${(props) => props.zIndex || 9998};
  }
  [data-rsbs-overlay] {
    right: unset;
    left: unset;
    z-index: ${(props) => (props.zIndex ? Number(props.zIndex) + 1 : 9999)};
    max-height: calc(100% - 3rem);
    border-radius: 12px 12px 0px 0px;
  }
  [data-rsbs-content] {
    padding: 0 1.25rem 1.25rem 1.25rem;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    [data-rsbs-overlay] {
      right: 0;
      left: 0;
      max-width: unset;
      width: 100%;
    }
  }
`;
