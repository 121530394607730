import React, { createContext, FC, ReactNode, useEffect, useState } from 'react';

import { darkTheme as darkThemeDefault, lightTheme as lightThemeDefault } from '~/overwrites/Theme/theme';
import { ThemeType } from '~/theme/Themes';

type ThemeName = 'light' | 'dark';

interface Brand {
  appName?: string;
  logo?: ReactNode;
  logoCollapsed?: ReactNode;
  imageWelcome?: ReactNode;
  mapStyleUrl?: string;
  mapMarker?: string;
}

const mainColor = '#171717';

export const lightTheme: ThemeType = {
  ...lightThemeDefault,
  name: 'cobrandLight',
  colors: {
    ...lightThemeDefault.colors,
    p400: '#8F8F8F',
    background: '#FAFAFA',
    secondaryGraph: '#8F8F8F',
    primaryLight: '#EDEDED',
    selection: '#EBEBEB',
    backgroundShadowGradientValue: '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0',
    primary: {
      ...lightThemeDefault.colors.primary,
      c900: '#032745',
      c800: '#043D6C',
      c700: '#054880',
      c600: '#065EA7',
      c500: mainColor,
      c400: '#8F8F8F',
      c300: '#B3B3B3',
      c200: '#D9D9D9',
      c100: '#EBEBEB',
      c050: '#F2F2F2',
    },
    graph: [
      '#2d2d2d',
      '#393939',
      '#4B4B4B',
      '#5E5E5E',
      '#727272',
      '#868686',
      '#ABABAB',
      '#C2C2C2',
      '#DDDDDD',
      '#F3F3F3',
    ],
    modal: {
      background: '#B3B3B3',
      startGradient: '#171717',
      endGradient: '#414042',
    },
  },
  gradients: {
    ...lightThemeDefault.gradients,
    primary: 'linear-gradient(322deg, #2d2d2d 52.87%, #414042 100%)',
    welcome: 'linear-gradient(322deg, #F3F3F3 49.92%, #FFF 100%)',
    selection:
      'linear-gradient(90deg, rgba(91, 87, 100, 0.12) 0%, rgba(91, 87, 100, 0.06) 100%, rgba(91, 87, 100, 0.06) 100%)',
  },
};

export const darkTheme: ThemeType = {
  name: 'cobrandDark',
  ...darkThemeDefault,
};

export interface BrandContextProps {
  setThemeName: (name: ThemeName) => void;
  theme: ThemeType;
  brandName?: string;
  brand?: Brand;
  isBrandChecked: boolean;
  mapStyleUrl?: string;
}

interface BrandProviderProps {
  children: ReactNode;
}

const BrandContext = createContext<BrandContextProps>({
  setThemeName: undefined,
  theme: undefined,
  brandName: undefined,
  brand: undefined,
  isBrandChecked: false,
  mapStyleUrl: undefined,
});

export const BrandProvider: FC<BrandProviderProps> = ({ children }) => {
  const [themeName, setThemeName] = useState<ThemeName>('light');
  const [theme, setTheme] = useState(themeName === 'dark' ? darkThemeDefault : lightThemeDefault);
  const [isBrandChecked, setBrandChecked] = useState(process.env.REACT_APP_COBRANDS_ENABLED === 'false');
  const [brandName, setBrandName] = useState<string>(undefined);
  const [brand, setBrand] = useState<Brand>(undefined);
  const [mapStyleUrl, setMapStyleUrl] = useState<string>(process.env.REACT_APP_MAPBOX_STYLE_URL);

  const loadBrandIfNeeded = async () => {
    let hostname = window.location.hostname;
    if (hostname.startsWith('www.')) {
      hostname = hostname.slice(4);
    }
    const hostPrefix = hostname.split('.')[0];
    if (hostPrefix && hostPrefix !== 'app') {
      try {
        const brandConstants: Brand = await import(`./${hostPrefix}/constants.tsx`);
        if (brandConstants) {
          setTheme(themeName === 'dark' ? darkTheme : lightTheme);
          setBrandName(hostPrefix);
          setBrand(brandConstants);
          setMapStyleUrl(brandConstants.mapStyleUrl || process.env.REACT_APP_MAPBOX_STYLE_URL);
        }
      } finally {
        setBrandChecked(true);
      }
    } else {
      setBrandChecked(true);
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_COBRANDS_ENABLED === 'true') {
      void loadBrandIfNeeded();
    }
  }, []);

  return (
    <BrandContext.Provider
      value={{
        setThemeName,
        theme,
        brandName,
        brand,
        isBrandChecked,
        mapStyleUrl,
      }}
    >
      {children}
    </BrandContext.Provider>
  );
};

export const useBrandContext: () => BrandContextProps = () => {
  return React.useContext(BrandContext);
};
