import { NavigateOptions, useNavigate } from 'react-router-dom';

export function querystring(name: string, url = window.location.href) {
  name = name.replace(/[[]]/g, '\\$&');

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const EmailRegex =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const shouldOpenLinkInNewTab = (e: React.MouseEvent) => {
  return e.ctrlKey || e.metaKey || e.ctrlKey;
};

export const useLinkHandler = () => {
  const navigate = useNavigate();

  const handleLinkClick = (e: React.MouseEvent, navigateUrl: string, options?: NavigateOptions) => {
    if (shouldOpenLinkInNewTab(e)) {
      window.open(navigateUrl);
    } else {
      navigate(navigateUrl, options);
    }
  };

  return { handleLinkClick };
};
