import React from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipRenderProps } from 'react-joyride';

import {
  ButtonsContainer,
  LastRow,
  NextButton,
  NumberCount,
  SkipButton,
  TourStepDescription,
  TourStepTitle,
  TourTooltipContainer,
} from './TourTooltip.styles';

interface TourTooltipProps extends TooltipRenderProps {
  stepsAmount: number;
}

const TourTooltip = ({
  continuous,
  index,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  tooltipProps,
  stepsAmount,
}: TourTooltipProps) => {
  const { t } = useTranslation();
  return (
    <TourTooltipContainer {...tooltipProps}>
      <TourStepTitle>{step.title}</TourStepTitle>
      <TourStepDescription>{step.content}</TourStepDescription>
      <LastRow>
        <NumberCount>
          {index + 1}/{stepsAmount}
        </NumberCount>
        <ButtonsContainer>
          {!isLastStep && <SkipButton {...skipProps}>{t('common.skipAll')}</SkipButton>}
          <NextButton {...primaryProps}>
            {continuous ? (index + 1 === stepsAmount ? t('common.close') : t('common.next')) : t('common.close')}
          </NextButton>
        </ButtonsContainer>
      </LastRow>
    </TourTooltipContainer>
  );
};

export default TourTooltip;
